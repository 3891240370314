import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';
import { subMenuItems } from '../../constant/enum';
import { Close } from '../../constant/Icon';
import { assetImages } from '../../constant/images';

const MobileMenu = ({ getActiveStatus, activeStatus }) => {
  const [services, setservices] = useState(false);
  const [selectedSubsubmenu, setSelectedSubsubmenu] = useState(null);
  const location = useLocation();
  return (
    <div className={`offcanvas-mobile-menu ${activeStatus ? 'active' : ''}`}>
      <div className="offcanvas-mobile-menu__overlay-close" onClick={() => getActiveStatus(false)} />
      <div className="offcanvas-mobile-menu__wrapper">
        <div className="offcanvas-mobile-menu__content-wrapper">
          <div className="offcanvas-mobile-menu_header">
            <img src={assetImages.logo} alt="Logo" className="lozad logo" />
            <button className="offcanvas-mobile-menu__close btn" onClick={() => getActiveStatus(false)}>
              <Close />
            </button>
          </div>
          <div className="offcanvas-mobile-menu__content">
            <nav className="offcanvas-mobile-menu__navigation" id="offcanvas-mobile-menu__navigation">
              <ul>
                <li onClick={() => getActiveStatus(false)}>
                  <Link to="/" onClick={scrollToTop} className={`${location.pathname === '/' ? 'active' : ''}`}>
                    Home
                  </Link>
                </li>
                <li className={`menu-item-has-children ${services ? 'active' : ''}`}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => setservices(!services)}
                  >
                    <span className={`${services === true ? 'active' : ''} service-main-menu`}>Services</span>
                    <span className="menu-expand">
                      <i></i>
                    </span>
                  </div>
                  {services && (
                    <div className=" mt-3">
                      {subMenuItems?.map((itm) => (
                        <div className="submenu-wrapper" key={itm.id}>
                          <div
                            onClick={() => setSelectedSubsubmenu(itm.id)}
                            className={`sub-menu-item-has-children ${
                              itm.id === selectedSubsubmenu ? 'active' : ''
                            } d-flex align-items-center justify-content-space-between`}
                          >
                            <div className="service-title-wrapper">
                              <p>{itm.title}</p>
                              <div className="line"></div>
                            </div>
                            <span className="sub-menu-expand">
                              <i></i>
                            </span>
                          </div>
                          {selectedSubsubmenu === itm.id && (
                            <ul className="mobile-sub-menu">
                              {itm.stacks?.map((ser) => (
                                <li key={ser.id}>
                                  <Link
                                    to={ser.link}
                                    onClick={() => {
                                      scrollToTop();
                                      getActiveStatus(false);
                                    }}
                                  >
                                    {ser.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                      <Link
                        to="/ui-ux-design"
                        onClick={() => {
                          scrollToTop();
                          getActiveStatus(false);
                        }}
                      >
                        <div className="service-title-wrapper">
                          <p>UI/UX Design & Development</p>
                          <div className="line"></div>
                        </div>
                      </Link>
                      <Link
                        to="/testing-and-quality-assurance"
                        onClick={() => {
                          scrollToTop();
                          getActiveStatus(false);
                        }}
                      >
                        <div className="service-title-wrapper">
                          <p>Testing & quality Assurance</p>
                          <div className="line"></div>
                        </div>
                      </Link>
                    </div>
                  )}
                </li>
                <li onClick={() => getActiveStatus(false)}>
                  <Link
                    to="/industry"
                    onClick={() => {
                      scrollToTop();
                      getActiveStatus(false);
                    }}
                    className={`${location.pathname === '/industry' ? 'active' : ''}`}
                  >
                    Industry
                  </Link>
                </li>
                <li onClick={() => getActiveStatus(false)}>
                  <Link
                    to="/portfolio"
                    onClick={() => {
                      scrollToTop();
                      getActiveStatus(false);
                    }}
                    className={`${location.pathname === '/portfolio' ? 'active' : ''}`}
                  >
                    Portfolio
                  </Link>
                </li>
                <li onClick={() => getActiveStatus(false)}>
                  <Link
                    to="/contact-us"
                    onClick={() => {
                      scrollToTop();
                      getActiveStatus(false);
                    }}
                    className={`${location.pathname === '/contact-us' ? 'active' : ''}`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
            <Link
              to="/estimate-your-project"
              onClick={() => {
                scrollToTop();
                getActiveStatus(false);
              }}
            >
              <button className="header-button" style={{ padding: '7px 10px' }}>
                Estimate Your Project
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
