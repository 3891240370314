import Home from './pages/Home';
import './assets/style/style.scss';
import { useRoutes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import MEANStack from './pages/webDevServices/MEANStack';
import MERNStack from './pages/webDevServices/MERNStack';
import AngularStack from './pages/webDevServices/AngularStack';
import ReactStack from './pages/webDevServices/ReactStack';
import NodeStack from './pages/webDevServices/NodeStack';
import PythonWeb from './pages/webDevServices/PythonWeb';
import DrupalWeb from './pages/webDevServices/DrupalWeb';
import MagentoWeb from './pages/webDevServices/MagentoWeb';
import CodeigniterDev from './pages/webDevServices/CodeigniterDev';
import WordpressDev from './pages/webDevServices/WordpressDev';
import MobileAppDev from './pages/mobileAppDevService/MobileAppDev';
import AndroidAppDev from './pages/mobileAppDevService/AndroidAppDev';
import IphoneAppDev from './pages/mobileAppDevService/IphoneAppDev';
import MobileAppMain from './pages/mobileAppDevService/MobileAppMain';
import MobileUi from './pages/mobileAppDevService/MobileUi';
import ReactNativeAppDev from './pages/mobileAppDevService/ReactNativeAppDev';
import StartUpAppDev from './pages/mobileAppDevService/StartUpAppDev';
import SEOServices from './pages/digitalMarketingServices/SEOServices';
import Adwords from './pages/digitalMarketingServices/Adwords';
import FaceBookMarketing from './pages/digitalMarketingServices/FaceBookMarketing';
import GoogleShoppingAds from './pages/digitalMarketingServices/GoogleShoppingAds';
import YoutubeAd from './pages/digitalMarketingServices/YoutubeAd';
import EmailMarketing from './pages/digitalMarketingServices/EmailMarketing';
import SpotifyAds from './pages/digitalMarketingServices/SpotifyAds';
import BannerAd from './pages/digitalMarketingServices/BannerAd';
import WebDesign from './pages/webandCreativeServices/WebDesign';
import Ecom from './pages/webandCreativeServices/Ecom';
import Branding from './pages/webandCreativeServices/Branding';
import LandingPages from './pages/webandCreativeServices/LandingPages';
import Copywriting from './pages/webandCreativeServices/Copywriting';
import Domains from './pages/webandCreativeServices/Domains';
import Gsuites from './pages/webandCreativeServices/Gsuites';
import AwsCloud from './pages/awsServices/AwsCloud';
import AwsMig from './pages/awsServices/AwsMig';
import AwsDeployment from './pages/awsServices/AwsDeployment';
import CloudApp from './pages/awsServices/CloudApp';
import CloudMig from './pages/awsServices/CloudMig';
import UIUXDesign from './pages/UIUXDesign';
import TestingAndQuality from './pages/TestingAndQuality';
import Industry from './pages/Industry';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Portfolio from './pages/Portfolio';
import EstimateProject from './pages/EstimateProject';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

function App() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/mean-stack',
      element: <MEANStack />,
    },
    {
      path: '/mern-stack',
      element: <MERNStack />,
    },
    {
      path: '/angular-js',
      element: <AngularStack />,
    },
    {
      path: '/react-js',
      element: <ReactStack />,
    },
    {
      path: '/node-js',
      element: <NodeStack />,
    },
    {
      path: '/python-web-development',
      element: <PythonWeb />,
    },
    {
      path: '/drupal-development',
      element: <DrupalWeb />,
    },
    {
      path: '/magento-evelopment',
      element: <MagentoWeb />,
    },
    {
      path: '/codeigniter-development',
      element: <CodeigniterDev />,
    },
    {
      path: '/wordpress-development',
      element: <WordpressDev />,
    },
    {
      path: '/mobile-app-development',
      element: <MobileAppDev />,
    },
    {
      path: '/android-app-development',
      element: <AndroidAppDev />,
    },
    {
      path: '/iphone-app-development',
      element: <IphoneAppDev />,
    },
    {
      path: '/mobile-app-maintainance',
      element: <MobileAppMain />,
    },
    {
      path: '/mobile-ui-screen',
      element: <MobileUi />,
    },
    {
      path: '/react-native-app-development',
      element: <ReactNativeAppDev />,
    },
    {
      path: '/startups-app-development',
      element: <StartUpAppDev />,
    },
    {
      path: '/seo-services',
      element: <SEOServices />,
    },
    {
      path: '/adwords',
      element: <Adwords />,
    },
    {
      path: '/facebook-marketing',
      element: <FaceBookMarketing />,
    },
    {
      path: '/google-shopping-ads',
      element: <GoogleShoppingAds />,
    },
    {
      path: '/youtube-advertising',
      element: <YoutubeAd />,
    },
    {
      path: '/email-marketing',
      element: <EmailMarketing />,
    },
    {
      path: '/spotify-ads',
      element: <SpotifyAds />,
    },
    {
      path: '/banner-advertising',
      element: <BannerAd />,
    },
    {
      path: '/web-design',
      element: <WebDesign />,
    },
    {
      path: '/e-com-web-design',
      element: <Ecom />,
    },
    {
      path: '/branding',
      element: <Branding />,
    },
    {
      path: '/landing-pages-design',
      element: <LandingPages />,
    },
    {
      path: '/copywriting-and-content-writing',
      element: <Copywriting />,
    },
    {
      path: '/domain-name-registration',
      element: <Domains />,
    },
    {
      path: '/gsuites-services',
      element: <Gsuites />,
    },
    {
      path: '/aws-cloud-consulting-services',
      element: <AwsCloud />,
    },
    {
      path: '/aws-data-migration',
      element: <AwsMig />,
    },
    {
      path: '/aws-deployment',
      element: <AwsDeployment />,
    },
    {
      path: '/cloud-application-services',
      element: <CloudApp />,
    },
    {
      path: '/cloud-migratuion-services',
      element: <CloudMig />,
    },
    {
      path: '/ui-ux-design',
      element: <UIUXDesign />,
    },
    {
      path: '/testing-and-quality-assurance',
      element: <TestingAndQuality />,
    },
    {
      path: '/industry',
      element: <Industry />,
    },
    {
      path: '/about-us',
      element: <AboutUs />,
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
    },
    {
      path: '/portfolio',
      element: <Portfolio />,
    },
    {
      path: '/estimate-your-project',
      element: <EstimateProject />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
  ]);
  return <ToastProvider placement="top-right">{routes}</ToastProvider>;
}

export default App;
