import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const MobileAppMain = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Delaying Is Risky',
      des: "A mobile app is the first successful stage for your business and having mobile application maintenance and support is the second smart stage to step on. Eventual threats are lurking in the digital space to crash the gates of security check. New modifications may also arrive in the OS, business services or app features. In any condition, the app owner and the app have to be ready to implement the change as well as be preventive and curative of malware mutating the normal functions of an app. Choose Cybertron Softech's Maintenance services to keep your app fine all the time.",
      listItems: [
        'Team with steadfast technological knowledge',
        'Value-added service quality',
        'Application security management',
        'Cost-Effective app maintenance services',
        'Enhanced app performance with ideal solutions',
        'Resolving problems in lesser time',
        'App analyzing for Startups',
      ],
      image: assetImages.mobileappmain1,
    },
    {
      id: 2,
      title: 'Benefits Of Mobile App Maintenance',
      des: 'Solving the bugs at right time is crucial in mobile app maintenance. Apps come in contact with corruptive bugs and errors at times. Bugs might exploit the vulnerability, affect operations or even might steal personal credentials and bank details. All the workings of business happening digitally can become haphazard but mobile app maintenance for bugs can help unfailingly.',
      image: assetImages.mobileappmain2,
    },
    {
      id: 3,
      title: 'Opportune Reviewing & Testing',
      des: 'It makes sense to test and review apps. All four aspects – quantity, quality, speed and attention are needed to pass the test. Our agile testers perform various usability tests, functional and responsive tests with various devices to assure the performance of the app and earn favourable reviews.',
      image: assetImages.mobileappmain3,
    },
    {
      id: 4,
      title: 'Smooth Business Functionality',
      des: 'This benefit helps the app owners to relax because their app is in harmony with users, workforce and business transactions. Smooth business functionality turns the users into customers and business into a blessing.',
      image: assetImages.mobileappmain4,
    },
    {
      id: 5,
      title: 'Optimized Interface And Upgrades',
      des: 'The mobile application has to stay updated with the constant requirements of latest features on smartphones. Smart ways of maintaining the user interface and upgrading it to new OS advances user functionality outperforms the application efficiency.',
      image: assetImages.androidapp4,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Who will take care of my project during development process?',
      des: 'A project leader will be assigned to you, who will keep you updated about the development, save all the communication and passes your requirements and approvals to the team.',
    },
    {
      id: 2,
      title: '2. What are the fees  to create developer account on Google?',
      des: 'For Android developer account, you will need to pay $25 USD/ one time',
    },
    {
      id: 3,
      title: '3. can I get changes done in the app after it is launched?',
      des: 'Yes, minor fixes, bug fixing, and maintenance can be done after it is launched.',
    },
    {
      id: 4,
      title: '4. Does Company provide free support?',
      des: 'For first few months after the app is launched, the company provides free support.',
    },
    {
      id: 5,
      title: '5. Will I own all of the source code produced by my team?',
      des: 'Yes - All project related work created for you by the team, is sole and exclusive property of the client.',
    },
    {
      id: 6,
      title: '6. Will you help us to upload the app after development ?',
      des: 'Yes, we will upload the app in both Andorid and Apple stores after the development.',
    },
    {
      id: 7,
      title: '7. can I hire developer for hourly or project based task ?',
      des: 'Yes, If you know the task to get done from the developer, then you can hire a developer on hourly or project/task base. Even we give flexibility to change the hire model on later stage.',
    },
    {
      id: 8,
      title: '8. Are there possibilities of my app getting rejected by Apple?',
      des: 'Yes, there are such possibilities but the company takes responsibility to get it approved.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Bankable And On The Level Mobile Application Maintenance"
        description={
          <>
            Having a mobile app brings you on the platform of an ever-running market and if you don’t keep up with its
            speed, you may fall. So, Mobile Application Maintenance is a fundamental need once a mobile app is there.
            Our mobile application maintenance and support services help you to be with the current market trends and
            meet the consumer needs and expectations that change constantly. If you don’t want your users to go
            somewhere else, then the availability of your mobile app with uninterrupted applicability is essential. We
            deliver the best mobile app maintenance services with a volume of experience to help your apps stay
            proactive and perform non-stop.
          </>
        }
        serviceImg={assetImages.mobileappmain}
        maxHeight="25rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default MobileAppMain;
