import React from 'react';
import CenteredTitle from '../UIComponents/CenteredTitle';

const SerKeyBenefits = ({ title, benefits }) => {
  return (
    <section className="service-key-benefits">
      <CenteredTitle title={title} />
      <div className="container-lg">
        <div className="row g-3 justify-content-center">
          {benefits.map((itm) => (
            <div key={itm.id} className="col-lg-4">
              <div className="feature-itm-wrapper">
                <h4 className={`fet-title `}>{itm.title}</h4>
                <p className={`des-text`}>{itm.des}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SerKeyBenefits;
