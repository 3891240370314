import logo from '../assets/images/cybertron-images/logo.png';
import homebanner from '../assets/images/cybertron-images/home-banner.png';
import adobeLogo from '../assets/images/cybertron-images/Adobe-logo.png';
import yahoologo from '../assets/images/cybertron-images/yahoo-logo.png';
import ibmlogo from '../assets/images/cybertron-images/IBM_logo.png';
import applePay from '../assets/images/cybertron-images/Apple_Pay.png';
import googlelogo from '../assets/images/cybertron-images/google-logo.png';
import microsoftlogo from '../assets/images/cybertron-images/microsoft-logo.png';
import whatwedo from '../assets/images/cybertron-images/whatwedo.png';
import appdevelopment from '../assets/images/cybertron-images/app-development.png';
import digitalmarketing from '../assets/images/cybertron-images/digital-marketing.png';
import ui from '../assets/images/cybertron-images/ui.png';
import ourmilestone from '../assets/images/cybertron-images/ourmilestone.png';
import ind1 from '../assets/images/cybertron-images/ind1.png';
import ind2 from '../assets/images/cybertron-images/ind2.png';
import ind3 from '../assets/images/cybertron-images/ind3.png';
import ind4 from '../assets/images/cybertron-images/ind4.png';
import ind5 from '../assets/images/cybertron-images/ind5.png';
import ind6 from '../assets/images/cybertron-images/ind6.png';
import ind7 from '../assets/images/cybertron-images/ind7.png';
import ind8 from '../assets/images/cybertron-images/ind8.png';
import ind9 from '../assets/images/cybertron-images/ind9.png';
import ind10 from '../assets/images/cybertron-images/ind10.png';
import ind11 from '../assets/images/cybertron-images/ind11.png';
import ind12 from '../assets/images/cybertron-images/ind12.png';
import ultimateexp from '../assets/images/cybertron-images/ultimateexp.png';
import tecexp from '../assets/images/cybertron-images/tecexp.png';
import estmitaecost from '../assets/images/cybertron-images/estmitaecost.png';
import sportsbatting from '../assets/images/cybertron-images/sportsbatting.png';
import videoconsulting from '../assets/images/cybertron-images/videoconsulting.png';
import elearning from '../assets/images/cybertron-images/elearning.png';
import arrowround from '../assets/images/cybertron-images/arrowround.png';
import clientfour from '../assets/images/cybertron-images/Clientfour.jpeg';
import clientone from '../assets/images/cybertron-images/Clientone.jpeg';
import clientTwo from '../assets/images/cybertron-images/ClientTwo.jpeg';
import clientThree from '../assets/images/cybertron-images/ClientThree.jpeg';
import certi1 from '../assets/images/cybertron-images/certi1.png';
import certi2 from '../assets/images/cybertron-images/certi2.png';
import certi3 from '../assets/images/cybertron-images/certi3.png';
import certi4 from '../assets/images/cybertron-images/certi4.png';
import certi5 from '../assets/images/cybertron-images/certi5.png';
import ach1 from '../assets/images/cybertron-images/ach1.png';
import ach2 from '../assets/images/cybertron-images/ach2.png';
import ach3 from '../assets/images/cybertron-images/ach3.png';
import ach4 from '../assets/images/cybertron-images/ach4.jpeg';
import newsHand from '../assets/images/cybertron-images/NewsHand.png';
import serviceBanner from '../assets/images/cybertron-images/serviceBanner.png';
import meanstackbanner from '../assets/images/cybertron-images/meanstackbanner.png';
import meanstackanatomy from '../assets/images/cybertron-images/meanstackanatomy.png';
import nodejs from '../assets/images/cybertron-images/nodejs.png';
import mongobd from '../assets/images/cybertron-images/mongobd.png';
import expressjs from '../assets/images/cybertron-images/expressjs.png';
import angularjs from '../assets/images/cybertron-images/angularjs.png';
import heretohelp from '../assets/images/cybertron-images/heretohelp.png';
import reactjslogo from '../assets/images/cybertron-images/reactjslogo.png';
import mernbanner from '../assets/images/cybertron-images/mernbanner.png';
import mernstackdevelopment from '../assets/images/cybertron-images/mern-stack-development.png';
import angularjssec from '../assets/images/cybertron-images/angularjssec.png';
import angularjsbanner from '../assets/images/cybertron-images/angular-js-banner.png';
import consultant from '../assets/images/cybertron-images/consultant.png';
import angwebdev from '../assets/images/cybertron-images/ang-web-dev.png';
import angmobdev from '../assets/images/cybertron-images/ang-mob-dev.png';
import migration from '../assets/images/cybertron-images/migration.png';
import angmanagsup from '../assets/images/cybertron-images/ang-manag-sup.png';
import angelaup from '../assets/images/cybertron-images/angelaup.png';
import reactjsbanner from '../assets/images/cybertron-images/reactjs-banner.png';
import reactsec from '../assets/images/cybertron-images/reactsec.png';
import reactuiux from '../assets/images/cybertron-images/reactui-ux.png';
import reactplugin from '../assets/images/cybertron-images/react-plugin.png';
import reactwebdev from '../assets/images/cybertron-images/react-web-dev.png';
import reactcust from '../assets/images/cybertron-images/react-cust.png';
import reactupd from '../assets/images/cybertron-images/react-upd.png';
import nodebanner from '../assets/images/cybertron-images/node-banner.png';
import nodesec from '../assets/images/cybertron-images/node-sec.png';
import nodeapi from '../assets/images/cybertron-images/node-api.png';
import nodechat from '../assets/images/cybertron-images/node-chat.png';
import nodeweb from '../assets/images/cybertron-images/node-web.png';
import nodeportal from '../assets/images/cybertron-images/node-portal.png';
import nodeajax from '../assets/images/cybertron-images/node-ajax.png';
import pythonbanner from '../assets/images/cybertron-images/python-banner.png';
import pythonsec from '../assets/images/cybertron-images/python-sec.png';
import python1 from '../assets/images/cybertron-images/python-1.png';
import python2 from '../assets/images/cybertron-images/python-2.png';
import highlyScalable from '../assets/images/cybertron-images/highlyScalable.png';
import rapiddev from '../assets/images/cybertron-images/rapiddev.png';
import python5 from '../assets/images/cybertron-images/python-5.png';
import python6 from '../assets/images/cybertron-images/python-6.png';
import drupalbanner from '../assets/images/cybertron-images/drupal-banner.png';
import drupalsec from '../assets/images/cybertron-images/drupal-sec.png';
import drupal1 from '../assets/images/cybertron-images/drupal-1.png';
import drupal2 from '../assets/images/cybertron-images/drupal-2.png';
import drupal3 from '../assets/images/cybertron-images/drupal-3.png';
import drupal4 from '../assets/images/cybertron-images/drupal-4.png';
import drupal5 from '../assets/images/cybertron-images/drupal-5.png';
import drupal7 from '../assets/images/cybertron-images/drupal-7.png';
import drupal8 from '../assets/images/cybertron-images/drupal-8.png';
import drupal9 from '../assets/images/cybertron-images/drupal-9.png';
import magentobanner from '../assets/images/cybertron-images/magento-banner.png';
import magentosec from '../assets/images/cybertron-images/magento-sec.png';
import magento1 from '../assets/images/cybertron-images/magento-1.png';
import magento2 from '../assets/images/cybertron-images/magento-2.png';
import magento3 from '../assets/images/cybertron-images/magento-3.png';
import magento4 from '../assets/images/cybertron-images/magento-4.png';
import magento5 from '../assets/images/cybertron-images/magento-5.png';
import magento6 from '../assets/images/cybertron-images/magento-6.png';
import magento7 from '../assets/images/cybertron-images/magento-7.png';
import magento8 from '../assets/images/cybertron-images/magento-8.png';
import magento9 from '../assets/images/cybertron-images/magento-9.png';
import magento10 from '../assets/images/cybertron-images/magento-10.png';
import magento11 from '../assets/images/cybertron-images/magento-11.png';
import codeigniterbanner from '../assets/images/cybertron-images/codeigniter-banner.png';
import codeignitersec from '../assets/images/cybertron-images/codeigniter-sec.png';
import codeigniter1 from '../assets/images/cybertron-images/codeigniter-1.png';
import codeigniter3 from '../assets/images/cybertron-images/codeigniter-3.png';
import codeigniter4 from '../assets/images/cybertron-images/codeigniter-4.png';
import codeigniter5 from '../assets/images/cybertron-images/codeigniter-5.png';
import codeigniter6 from '../assets/images/cybertron-images/codeigniter-6.png';
import codeigniter7 from '../assets/images/cybertron-images/codeigniter-7.png';
import codeigniter2 from '../assets/images/cybertron-images/codeigniter-2.png';
import wordpressbanner from '../assets/images/cybertron-images/wordpress-banner.png';
import wordpresssec from '../assets/images/cybertron-images/wordpress-sec.png';
import wordpress1 from '../assets/images/cybertron-images/wordpress-1.png';
import wordpress2 from '../assets/images/cybertron-images/wordpress-2.png';
import wordpress3 from '../assets/images/cybertron-images/wordpress-3.png';
import wordpress4 from '../assets/images/cybertron-images/wordpress-4.png';
import wordpress5 from '../assets/images/cybertron-images/wordpress-5.png';
import wordpress6 from '../assets/images/cybertron-images/wordpress-6.png';
import wordpress7 from '../assets/images/cybertron-images/wordpress-7.png';
import wordpress8 from '../assets/images/cybertron-images/wordpress-8.png';
import wordpress9 from '../assets/images/cybertron-images/wordpress-9.png';
import mobileappdev from '../assets/images/cybertron-images/mobileappdev.png';
import mobileapp1 from '../assets/images/cybertron-images/mobileapp1.png';
import mobileapp2 from '../assets/images/cybertron-images/mobileapp2.png';
import mobileapp3 from '../assets/images/cybertron-images/mobileapp3.png';
import mobileapp4 from '../assets/images/cybertron-images/mobileapp4.png';
import mobileapp5 from '../assets/images/cybertron-images/mobileapp5.png';
import androidappdev from '../assets/images/cybertron-images/androidappdev.png';
import androidapp1 from '../assets/images/cybertron-images/androidapp1.png';
import androidapp2 from '../assets/images/cybertron-images/androidapp2.png';
import androidapp3 from '../assets/images/cybertron-images/androidapp3.png';
import androidapp4 from '../assets/images/cybertron-images/androidapp4.png';
import androidapp5 from '../assets/images/cybertron-images/androidapp5.png';
import androidapp6 from '../assets/images/cybertron-images/androidapp6.png';
import androidapp7 from '../assets/images/cybertron-images/androidapp7.png';
import androidapp8 from '../assets/images/cybertron-images/androidapp8.png';
import androidapp9 from '../assets/images/cybertron-images/androidapp9.png';
import androidapp10 from '../assets/images/cybertron-images/androidapp10.png';
import iphoneappdev from '../assets/images/cybertron-images/iphoneappdev.png';
import iphoneapp1 from '../assets/images/cybertron-images/iphoneapp1.png';
import iphoneapp2 from '../assets/images/cybertron-images/iphoneapp2.png';
import iphoneapp3 from '../assets/images/cybertron-images/iphoneapp3.png';
import iphoneapp4 from '../assets/images/cybertron-images/iphoneapp4.png';
import iphoneapp5 from '../assets/images/cybertron-images/iphoneapp5.png';
import iphoneapp7 from '../assets/images/cybertron-images/iphoneapp7.png';
import iphoneapp8 from '../assets/images/cybertron-images/iphoneapp8.png';
import iphoneapp11 from '../assets/images/cybertron-images/iphoneapp11.png';
import mobileappmain from '../assets/images/cybertron-images/mobileappmain.png';
import mobileappmain1 from '../assets/images/cybertron-images/mobileappmain1.png';
import mobileappmain2 from '../assets/images/cybertron-images/mobileappmain2.png';
import mobileappmain3 from '../assets/images/cybertron-images/mobileappmain3.png';
import mobileappmain4 from '../assets/images/cybertron-images/mobileappmain4.png';
import mobileui from '../assets/images/cybertron-images/mobileui.png';
import mobileui1 from '../assets/images/cybertron-images/mobileui1.png';
import mobileui2 from '../assets/images/cybertron-images/mobileui2.png';
import mobileui3 from '../assets/images/cybertron-images/mobileui3.png';
import mobileui4 from '../assets/images/cybertron-images/mobileui4.png';
import mobileui5 from '../assets/images/cybertron-images/mobileui5.png';
import reactnative from '../assets/images/cybertron-images/reactnative.png';
import reactnative1 from '../assets/images/cybertron-images/reactnative1.png';
import reactnative3 from '../assets/images/cybertron-images/reactnative3.png';
import reactnative4 from '../assets/images/cybertron-images/reactnative4.png';
import reactnative5 from '../assets/images/cybertron-images/reactnative5.png';
import startupapp from '../assets/images/cybertron-images/startupapp.png';
import startupapp1 from '../assets/images/cybertron-images/startupapp1.png';
import seoBanner from '../assets/images/cybertron-images/seoBanner.png';
import seochoose1 from '../assets/images/cybertron-images/seochoose1.png';
import seochoose2 from '../assets/images/cybertron-images/seochoose2.png';
import seochoose3 from '../assets/images/cybertron-images/seochoose3.png';
import seochoose4 from '../assets/images/cybertron-images/seochoose4.png';
import seochoose5 from '../assets/images/cybertron-images/seochoose5.png';
import seochoose6 from '../assets/images/cybertron-images/seochoose6.png';
import seoFet1 from '../assets/images/cybertron-images/seoFet1.png';
import seoFet2 from '../assets/images/cybertron-images/seoFet2.png';
import seoFet3 from '../assets/images/cybertron-images/seoFet3.png';
import seoFet4 from '../assets/images/cybertron-images/seoFet4.png';
import seoFet5 from '../assets/images/cybertron-images/seoFet5.png';
import adWordsBanner from '../assets/images/cybertron-images/adWordsBanner.png';
import adWordsFet1 from '../assets/images/cybertron-images/adWordsFet1.png';
import adWordsFet3 from '../assets/images/cybertron-images/adWordsFet3.png';
import adWordsFet4 from '../assets/images/cybertron-images/adWordsFet4.png';
import adWordsFet5 from '../assets/images/cybertron-images/adWordsFet5.png';
import fbMarkBanner from '../assets/images/cybertron-images/fbMarkBanner.png';
import fbMarkFet1 from '../assets/images/cybertron-images/fbMarkFet1.png';
import fbMarkFet2 from '../assets/images/cybertron-images/fbMarkFet2.png';
import fbMarkFet3 from '../assets/images/cybertron-images/fbMarkFet3.png';
import fbMarkFet4 from '../assets/images/cybertron-images/fbMarkFet4.png';
import retargetBanner from '../assets/images/cybertron-images/retargetBanner.png';
import retargetFet1 from '../assets/images/cybertron-images/retargetFet1.png';
import retargetFet2 from '../assets/images/cybertron-images/retargetFet2.png';
import retargetFet3 from '../assets/images/cybertron-images/retargetFet3.png';
import googleShopAdBanner from '../assets/images/cybertron-images/googleShopAdBanner.png';
import googleShopAdFet1 from '../assets/images/cybertron-images/googleShopAdFet1.png';
import googleShopAdFet2 from '../assets/images/cybertron-images/googleShopAdFet2.png';
import googleShopAdFet3 from '../assets/images/cybertron-images/googleShopAdFet3.png';
import videoAdBanner from '../assets/images/cybertron-images/videoAdBanner.png';
import videoAdFet1 from '../assets/images/cybertron-images/videoAdFet1.png';
import videoAdFet2 from '../assets/images/cybertron-images/videoAdFet2.png';
import videoAdFet3 from '../assets/images/cybertron-images/videoAdFet3.png';
import videoAdFet4 from '../assets/images/cybertron-images/videoAdFet4.png';
import emailMarkBanner from '../assets/images/cybertron-images/emailMarkBanner.png';
import emailMarkFet1 from '../assets/images/cybertron-images/emailMarkFet1.png';
import emailMarkFet2 from '../assets/images/cybertron-images/emailMarkFet2.png';
import emailMarkFet3 from '../assets/images/cybertron-images/emailMarkFet3.png';
import emailMarkFet4 from '../assets/images/cybertron-images/emailMarkFet4.png';
import emailMarkFet5 from '../assets/images/cybertron-images/emailMarkFet5.png';
import spotifyAdBanner from '../assets/images/cybertron-images/spotifyAdBanner.png';
import spotifyAdFet1 from '../assets/images/cybertron-images/spotifyAdFet1.png';
import spotifyAdFet2 from '../assets/images/cybertron-images/spotifyAdFet2.png';
import spotifyAdFet3 from '../assets/images/cybertron-images/spotifyAdFet3.png';
import bannerAd from '../assets/images/cybertron-images/bannerAd.png';
import bannerAd1 from '../assets/images/cybertron-images/bannerAd1.png';
import bannerAd2 from '../assets/images/cybertron-images/bannerAd2.png';
import bannerAd3 from '../assets/images/cybertron-images/bannerAd3.png';
import webdevfe1 from '../assets/images/cybertron-images/webdevfe1.png';
import webdevfe2 from '../assets/images/cybertron-images/webdevfe2.png';
import webdevfe3 from '../assets/images/cybertron-images/webdevfe3.png';
import webdevfe4 from '../assets/images/cybertron-images/webdevfe4.png';
import webdevfe5 from '../assets/images/cybertron-images/webdevfe5.png';
import webdev1 from '../assets/images/cybertron-images/webdev1.png';
import webdev2 from '../assets/images/cybertron-images/webdev2.png';
import webdev3 from '../assets/images/cybertron-images/webdev3.png';
import webdev4 from '../assets/images/cybertron-images/webdev4.png';
import webdev5 from '../assets/images/cybertron-images/webdev5.png';
import webdev6 from '../assets/images/cybertron-images/webdev6.png';
import webdev7 from '../assets/images/cybertron-images/webdev7.png';
import webdev8 from '../assets/images/cybertron-images/webdev8.png';
import webdev9 from '../assets/images/cybertron-images/webdev9.png';
import webdev10 from '../assets/images/cybertron-images/webdev10.png';
import webdev11 from '../assets/images/cybertron-images/webdev11.png';
import ecome1 from '../assets/images/cybertron-images/ecome1.png';
import ecome2 from '../assets/images/cybertron-images/ecome2.png';
import ecome3 from '../assets/images/cybertron-images/ecome3.png';
import ecombanner from '../assets/images/cybertron-images/ecombanner.png';
import brandbanner from '../assets/images/cybertron-images/brandbanner.png';
import brand1 from '../assets/images/cybertron-images/brand1.png';
import brand2 from '../assets/images/cybertron-images/brand2.png';
import brand3 from '../assets/images/cybertron-images/brand3.png';
import brand4 from '../assets/images/cybertron-images/brand4.png';
import brand5 from '../assets/images/cybertron-images/brand5.png';
import landingPageBanner from '../assets/images/cybertron-images/landingPageBanner.png';
import landingPage1 from '../assets/images/cybertron-images/landingPage1.png';
import landingPage2 from '../assets/images/cybertron-images/landingPage2.png';
import copywbanner from '../assets/images/cybertron-images/copywbanner.png';
import copyw1 from '../assets/images/cybertron-images/copyw1.png';
import copyw2 from '../assets/images/cybertron-images/copyw2.png';
import copyw3 from '../assets/images/cybertron-images/copyw3.png';
import domainbanner from '../assets/images/cybertron-images/domainbanner.png';
import domain1 from '../assets/images/cybertron-images/domain1.png';
import domain2 from '../assets/images/cybertron-images/domain2.png';
import gsuitebanner from '../assets/images/cybertron-images/gsuitebanner.png';
import gsuite1 from '../assets/images/cybertron-images/gsuite1.png';
import gsuite2 from '../assets/images/cybertron-images/gsuite2.png';
import industry7 from '../assets/images/cybertron-images/industry7.png';
import awsBanner from '../assets/images/cybertron-images/awsBanner.png';
import awsmigbanner from '../assets/images/cybertron-images/awsmigbanner.png';
import awsmigsec from '../assets/images/cybertron-images/awsmigsec.png';
import awssec from '../assets/images/cybertron-images/awssec.png';
import cloudapp from '../assets/images/cybertron-images/cloudapp.png';
import cloudapp1 from '../assets/images/cybertron-images/cloudapp1.png';
import cloudmig from '../assets/images/cybertron-images/cloudmig.png';
import industry1 from '../assets/images/cybertron-images/industry1.png';
import industry2 from '../assets/images/cybertron-images/industry2.png';
import industry3 from '../assets/images/cybertron-images/industry3.png';
import industry4 from '../assets/images/cybertron-images/industry4.png';
import industry5 from '../assets/images/cybertron-images/industry5.png';
import industry6 from '../assets/images/cybertron-images/industry6.png';
import industry8 from '../assets/images/cybertron-images/industry8.png';
import industry9 from '../assets/images/cybertron-images/industry9.png';
import industry10 from '../assets/images/cybertron-images/industry10.png';
import industry11 from '../assets/images/cybertron-images/industry11.png';
import industry12 from '../assets/images/cybertron-images/industry12.png';
import testing1 from '../assets/images/cybertron-images/testing1.png';
import testing5 from '../assets/images/cybertron-images/testing5.png';
import testing6 from '../assets/images/cybertron-images/testing6.png';
import testingbanner from '../assets/images/cybertron-images/testingbanner.png';
import testingsec1 from '../assets/images/cybertron-images/testingsec1.png';
import testingsec2 from '../assets/images/cybertron-images/testingsec2.png';
import uiux1 from '../assets/images/cybertron-images/uiux1.png';
import uiux2 from '../assets/images/cybertron-images/uiux2.png';
import uiux3 from '../assets/images/cybertron-images/uiux3.png';
import uiux4 from '../assets/images/cybertron-images/uiux4.png';
import uiux5 from '../assets/images/cybertron-images/uiux5.png';
import uiux6 from '../assets/images/cybertron-images/uiux6.png';
import uiux7 from '../assets/images/cybertron-images/uiux7.png';
import uiux8 from '../assets/images/cybertron-images/uiux8.png';
import uiuxbanner from '../assets/images/cybertron-images/uiuxbanner.png';
import uiuxsec from '../assets/images/cybertron-images/uiuxsec.png';
import uiuxsec2 from '../assets/images/cybertron-images/uiuxsec2.png';
import latestpro1 from '../assets/images/cybertron-images/latestpro1.png';
import latestpro2 from '../assets/images/cybertron-images/latestpro2.png';
import latestpro3 from '../assets/images/cybertron-images/latestpro3.png';
import aboutBanner from '../assets/images/cybertron-images/aboutBanner.png';
import contactbanner from '../assets/images/cybertron-images/contactbanner.png';
import portfoliobanner from '../assets/images/cybertron-images/portfoliobanner.png';
import specialize from '../assets/images/cybertron-images/specialize.png';
import aboutsecbg from '../assets/images/cybertron-images/aboutsecbg.png';
import flistaa from '../assets/images/cybertron-images/flistaa.png';
import wholisticcure from '../assets/images/cybertron-images/wholisticcure.png';
import dhemo from '../assets/images/cybertron-images/dhemo.png';
import prayanelectricals from '../assets/images/cybertron-images/prayanelectricals.png';
import prayoshafoodingredients from '../assets/images/cybertron-images/prayoshafoodingredients.png';
import tileswale from '../assets/images/cybertron-images/tileswale.png';
import withlovenregards from '../assets/images/cybertron-images/withlovenregards.png';
import stratboard from '../assets/images/cybertron-images/stratboard.png';
import b2bshopmart from '../assets/images/cybertron-images/b2bshopmart.png';
import careermakerseducation from '../assets/images/cybertron-images/careermakerseducation.png';
import gatewayschoolmumbai from '../assets/images/cybertron-images/gatewayschoolmumbai.png';
import treeium from '../assets/images/cybertron-images/treeium.png';
import industrykart from '../assets/images/cybertron-images/industrykart.png';
import acehardware from '../assets/images/cybertron-images/acehardware.png';
import rebath from '../assets/images/cybertron-images/rebath.png';
import newzealand from '../assets/images/cybertron-images/newzealand.png';
import easyeda from '../assets/images/cybertron-images/easyeda.png';
import simplychocolatecph from '../assets/images/cybertron-images/simplychocolatecph.png';
import vihita from '../assets/images/cybertron-images/vihita.png';
import handyman from '../assets/images/cybertron-images/handyman.png';
import ikarein from '../assets/images/cybertron-images/ikarein.png';
import happywishcare from '../assets/images/cybertron-images/happywishcare.png';
import aircompressorindia from '../assets/images/cybertron-images/aircompressorindia.png';
import solarpowersupply from '../assets/images/cybertron-images/solarpowersupply.png';
import webDesBanner from '../assets/images/cybertron-images/webDesBanner.png';

export const assetImages = {
  logo: logo,
  homebanner: homebanner,
  adobeLogo: adobeLogo,
  yahoologo: yahoologo,
  ibmlogo: ibmlogo,
  applePay: applePay,
  googlelogo: googlelogo,
  microsoftlogo: microsoftlogo,
  whatwedo: whatwedo,
  appdevelopment: appdevelopment,
  digitalmarketing: digitalmarketing,
  ui: ui,
  ourmilestone: ourmilestone,
  ind1: ind1,
  ind2: ind2,
  ind3: ind3,
  ind4: ind4,
  ind5: ind5,
  ind6: ind6,
  ind7: ind7,
  ind8: ind8,
  ind9: ind9,
  ind10: ind10,
  ind11: ind11,
  ind12: ind12,
  ultimateexp: ultimateexp,
  tecexp: tecexp,
  estmitaecost: estmitaecost,
  sportsbatting: sportsbatting,
  videoconsulting: videoconsulting,
  elearning: elearning,
  arrowround: arrowround,
  clientfour: clientfour,
  clientone: clientone,
  clientTwo: clientTwo,
  clientThree: clientThree,
  certi1: certi1,
  certi2: certi2,
  certi3: certi3,
  certi4: certi4,
  certi5: certi5,
  ach1: ach1,
  ach2: ach2,
  ach3: ach3,
  ach4: ach4,
  newsHand: newsHand,
  serviceBanner: serviceBanner,
  meanstackbanner: meanstackbanner,
  meanstackanatomy: meanstackanatomy,
  nodejs: nodejs,
  mongobd: mongobd,
  expressjs: expressjs,
  angularjs: angularjs,
  heretohelp: heretohelp,
  reactjslogo: reactjslogo,
  mernbanner: mernbanner,
  mernstackdevelopment: mernstackdevelopment,
  angularjssec: angularjssec,
  angularjsbanner: angularjsbanner,
  consultant: consultant,
  angwebdev: angwebdev,
  angmobdev: angmobdev,
  migration: migration,
  angmanagsup: angmanagsup,
  angelaup: angelaup,
  reactjsbanner: reactjsbanner,
  reactsec: reactsec,
  reactuiux: reactuiux,
  reactplugin: reactplugin,
  reactwebdev: reactwebdev,
  reactcust: reactcust,
  reactupd: reactupd,
  nodebanner: nodebanner,
  nodesec: nodesec,
  nodeapi: nodeapi,
  nodechat: nodechat,
  nodeweb: nodeweb,
  nodeportal: nodeportal,
  nodeajax: nodeajax,
  pythonbanner: pythonbanner,
  pythonsec: pythonsec,
  python1: python1,
  python2: python2,
  highlyScalable: highlyScalable,
  rapiddev: rapiddev,
  python5: python5,
  python6: python6,
  drupalbanner: drupalbanner,
  drupalsec: drupalsec,
  drupal1: drupal1,
  drupal2: drupal2,
  drupal3: drupal3,
  drupal4: drupal4,
  drupal5: drupal5,
  drupal7: drupal7,
  drupal8: drupal8,
  drupal9: drupal9,
  magentobanner: magentobanner,
  magentosec: magentosec,
  magento1: magento1,
  magento2: magento2,
  magento3: magento3,
  magento4: magento4,
  magento5: magento5,
  magento6: magento6,
  magento7: magento7,
  magento8: magento8,
  magento9: magento9,
  magento10: magento10,
  magento11: magento11,
  codeigniterbanner: codeigniterbanner,
  codeignitersec: codeignitersec,
  codeigniter1: codeigniter1,
  codeigniter3: codeigniter3,
  codeigniter4: codeigniter4,
  codeigniter5: codeigniter5,
  codeigniter6: codeigniter6,
  codeigniter7: codeigniter7,
  codeigniter2: codeigniter2,
  wordpressbanner: wordpressbanner,
  wordpresssec: wordpresssec,
  wordpress1: wordpress1,
  wordpress2: wordpress2,
  wordpress3: wordpress3,
  wordpress4: wordpress4,
  wordpress5: wordpress5,
  wordpress6: wordpress6,
  wordpress7: wordpress7,
  wordpress8: wordpress8,
  wordpress9: wordpress9,
  mobileappdev: mobileappdev,
  mobileapp1: mobileapp1,
  mobileapp2: mobileapp2,
  mobileapp3: mobileapp3,
  mobileapp4: mobileapp4,
  mobileapp5: mobileapp5,
  androidappdev: androidappdev,
  androidapp1: androidapp1,
  androidapp2: androidapp2,
  androidapp3: androidapp3,
  androidapp4: androidapp4,
  androidapp5: androidapp5,
  androidapp6: androidapp6,
  androidapp7: androidapp7,
  androidapp8: androidapp8,
  androidapp9: androidapp9,
  androidapp10: androidapp10,
  iphoneappdev: iphoneappdev,
  iphoneapp1: iphoneapp1,
  iphoneapp2: iphoneapp2,
  iphoneapp3: iphoneapp3,
  iphoneapp4: iphoneapp4,
  iphoneapp5: iphoneapp5,
  iphoneapp7: iphoneapp7,
  iphoneapp8: iphoneapp8,
  iphoneapp11: iphoneapp11,
  mobileappmain: mobileappmain,
  mobileappmain1: mobileappmain1,
  mobileappmain2: mobileappmain2,
  mobileappmain3: mobileappmain3,
  mobileappmain4: mobileappmain4,
  mobileui: mobileui,
  mobileui1: mobileui1,
  mobileui2: mobileui2,
  mobileui3: mobileui3,
  mobileui4: mobileui4,
  mobileui5: mobileui5,
  reactnative: reactnative,
  reactnative1: reactnative1,
  reactnative3: reactnative3,
  reactnative4: reactnative4,
  reactnative5: reactnative5,
  startupapp: startupapp,
  startupapp1: startupapp1,
  seoBanner: seoBanner,
  seochoose1: seochoose1,
  seochoose2: seochoose2,
  seochoose3: seochoose3,
  seochoose4: seochoose4,
  seochoose5: seochoose5,
  seochoose6: seochoose6,
  seoFet1: seoFet1,
  seoFet2: seoFet2,
  seoFet3: seoFet3,
  seoFet4: seoFet4,
  seoFet5: seoFet5,
  adWordsBanner: adWordsBanner,
  adWordsFet1: adWordsFet1,
  adWordsFet3: adWordsFet3,
  adWordsFet4: adWordsFet4,
  adWordsFet5: adWordsFet5,
  fbMarkBanner: fbMarkBanner,
  fbMarkFet1: fbMarkFet1,
  fbMarkFet2: fbMarkFet2,
  fbMarkFet3: fbMarkFet3,
  fbMarkFet4: fbMarkFet4,
  retargetBanner: retargetBanner,
  retargetFet1: retargetFet1,
  retargetFet2: retargetFet2,
  retargetFet3: retargetFet3,
  googleShopAdBanner: googleShopAdBanner,
  googleShopAdFet1: googleShopAdFet1,
  googleShopAdFet2: googleShopAdFet2,
  googleShopAdFet3: googleShopAdFet3,
  videoAdBanner: videoAdBanner,
  videoAdFet1: videoAdFet1,
  videoAdFet2: videoAdFet2,
  videoAdFet3: videoAdFet3,
  videoAdFet4: videoAdFet4,
  emailMarkBanner: emailMarkBanner,
  emailMarkFet1: emailMarkFet1,
  emailMarkFet2: emailMarkFet2,
  emailMarkFet3: emailMarkFet3,
  emailMarkFet4: emailMarkFet4,
  emailMarkFet5: emailMarkFet5,
  spotifyAdBanner: spotifyAdBanner,
  spotifyAdFet1: spotifyAdFet1,
  spotifyAdFet2: spotifyAdFet2,
  spotifyAdFet3: spotifyAdFet3,
  bannerAd: bannerAd,
  bannerAd1: bannerAd1,
  bannerAd2: bannerAd2,
  bannerAd3: bannerAd3,
  webdevfe1: webdevfe1,
  webdevfe2: webdevfe2,
  webdevfe3: webdevfe3,
  webdevfe4: webdevfe4,
  webdevfe5: webdevfe5,
  webdev1: webdev1,
  webdev2: webdev2,
  webdev3: webdev3,
  webdev4: webdev4,
  webdev5: webdev5,
  webdev6: webdev6,
  webdev7: webdev7,
  webdev8: webdev8,
  webdev9: webdev9,
  webdev10: webdev10,
  webdev11: webdev11,
  ecome1: ecome1,
  ecome2: ecome2,
  ecome3: ecome3,
  ecombanner: ecombanner,
  brandbanner: brandbanner,
  brand1: brand1,
  brand2: brand2,
  brand3: brand3,
  brand4: brand4,
  brand5: brand5,
  landingPageBanner: landingPageBanner,
  landingPage1: landingPage1,
  landingPage2: landingPage2,
  copywbanner: copywbanner,
  copyw1: copyw1,
  copyw2: copyw2,
  copyw3: copyw3,
  domainbanner: domainbanner,
  domain1: domain1,
  domain2: domain2,
  gsuitebanner: gsuitebanner,
  gsuite1: gsuite1,
  gsuite2: gsuite2,
  awsBanner: awsBanner,
  awsmigbanner: awsmigbanner,
  awsmigsec: awsmigsec,
  awssec: awssec,
  cloudapp: cloudapp,
  cloudapp1: cloudapp1,
  cloudmig: cloudmig,
  industry1: industry1,
  industry2: industry2,
  industry3: industry3,
  industry4: industry4,
  industry5: industry5,
  industry6: industry6,
  industry7: industry7,
  industry8: industry8,
  industry9: industry9,
  industry10: industry10,
  industry11: industry11,
  industry12: industry12,
  testing1: testing1,
  testing5: testing5,
  testing6: testing6,
  testingbanner: testingbanner,
  testingsec1: testingsec1,
  testingsec2: testingsec2,
  uiux1: uiux1,
  uiux2: uiux2,
  uiux3: uiux3,
  uiux4: uiux4,
  uiux5: uiux5,
  uiux6: uiux6,
  uiux7: uiux7,
  uiux8: uiux8,
  uiuxbanner: uiuxbanner,
  uiuxsec: uiuxsec,
  uiuxsec2: uiuxsec2,
  latestpro1: latestpro1,
  latestpro2: latestpro2,
  latestpro3: latestpro3,
  aboutBanner: aboutBanner,
  contactbanner: contactbanner,
  portfoliobanner: portfoliobanner,
  specialize: specialize,
  aboutsecbg: aboutsecbg,
  flistaa: flistaa,
  wholisticcure: wholisticcure,
  dhemo: dhemo,
  prayanelectricals: prayanelectricals,
  prayoshafoodingredients: prayoshafoodingredients,
  tileswale: tileswale,
  withlovenregards: withlovenregards,
  stratboard: stratboard,
  b2bshopmart: b2bshopmart,
  careermakerseducation: careermakerseducation,
  gatewayschoolmumbai: gatewayschoolmumbai,
  treeium: treeium,
  industrykart: industrykart,
  acehardware: acehardware,
  rebath: rebath,
  newzealand: newzealand,
  easyeda: easyeda,
  simplychocolatecph: simplychocolatecph,
  vihita: vihita,
  handyman: handyman,
  ikarein: ikarein,
  happywishcare: happywishcare,
  aircompressorindia: aircompressorindia,
  solarpowersupply: solarpowersupply,
  webDesBanner: webDesBanner,
};
