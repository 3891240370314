import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';
const GoogleShoppingAds = () => {
  const servicesWeProvide = [
    {
      id: 3,
      title: 'Smart Display Campaigns',
      des: (
        <>
          Online shopping is big business, seriously big business, like ‘trillion dollar business’ big. With new
          generations being raised by technology, accustom to convenience and instant gratification you can bet your
          bottom dollar that figure ain’t peaking any time soon. But with so many people turning to online retail stores
          rather than brick and mortar it’s absolutely ESSENTIAL that your business is built to support quick
          transactions, engaging customer journeys and most importantly to stand apart from your competitors. So when
          new marketing opportunities like Google Shops Smart Display Campaigns appear on the market – trust us you want
          to be an early adopter. But it’s not so easy, often we have DIY’ers enter our front doors, deflated at their
          blown budget with little returns. We can help you get your products optimised and showing on the first page
          without blowing your budget.
          <br />
          <br />
          With Google Shopping services, you’re able to display the products you have for sale with Google Shopping Ads
          – you’ve probably seen these ads pop up when you search for specific products. If you’re looking for a Google
          Shopping agency, we specialise in Google Ads Shopping management and can help you develop a campaign which
          utilises this. If you’re curious, or simply want to understand how it works, we’re here to help, so get in
          touch today and lets start a conversation.
        </>
      ),
      image: assetImages.googleShopAdFet1,
    },
    {
      id: 4,
      title: "What's Included In Our Google Shopping Service?",
      des: (
        <>
          We go straight to the things that matter – and that’s MAKING YOU MONEY. After understanding your business, we
          start implementing the work right away.
        </>
      ),
      image: assetImages.googleShopAdFet2,
      listItems: [
        'Google Merchant Centre Setup',
        'Google Ads Setup',
        'Smart Targeting of Focused Products',
        'Campaign reporting based on KPI',
        'Dedicated Account Manager',
      ],
    },
    {
      id: 5,
      title: 'Getting Started',
      des: (
        <>
          To get started, you’ll need both a Google AdWords account and a Google Merchant Centre account. Don’t worry,
          the setup process is absolutely free for both of these platforms and we can most definitely help you along the
          way.
          <br />
          <br />
          To have your items listed on Google Shopping, you need to submit your data information through the Google
          Merchant Centre. It’s super important to fill in all the necessary attributes so your products turn up in the
          right search results – if your product is scarves, you sure as well don’t want to show up for home appliances.
          <br />
          <br />
          Link your AdWords and Google Merchant Centre accounts together and get started with your campaign!
        </>
      ),
      image: assetImages.googleShopAdFet3,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Google Shopping Ads Agency"
        description={
          <>
            <span className="text-orage">Product listing ads that convert!</span>
          </>
        }
        serviceImg={assetImages.googleShopAdBanner}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default GoogleShoppingAds;
