import React from 'react';
import CenteredTitle from '../UIComponents/CenteredTitle';
import OneSideTitle from '../UIComponents/OneSideTitle';

const ServicesBlueBg = ({ servicesWeProvide, whyChoose, des }) => {
  return (
    <section className="services-blue-bg">
      <div className={whyChoose ? 'mb-5' : ''}>
        <div className="container-lg">
          {servicesWeProvide?.map((itm, i) => (
            <div key={itm.id} className={`row mb-4 ${i % 2 === 0 ? 'is-reverse' : ''} align-items-center`}>
              <div className="col-lg-6">
                <OneSideTitle title={itm.title} des={itm.des} />
                {itm.listItems?.map((fet, ind) => (
                  <div className="feature-wrapper" key={ind}>
                    <div className="fet-dot"></div>
                    <p>{fet}</p>
                  </div>
                ))}
                <br />
                <p className="des-text">{itm.des2}</p>
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <img
                  src={itm.image}
                  alt="whatwedo"
                  style={{
                    maxHeight: itm.maxHeight ? itm.maxHeight : '20rem',
                  }}
                  className="img-fluid sec-image"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {whyChoose && <CenteredTitle disablemb={true} title="Why Choose Us" des={des} />}
    </section>
  );
};

export default ServicesBlueBg;
