import React from 'react';
import Layout from '../components/layout/Layout';

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="container-lg policy-sec">
        <div className="policy-banner">
          <p className="des-text text-center m-0">Current as of 1 June 2023</p>
          <div className="title-wrapper justify-content-center">
            <h2>Terms & Conditions</h2>
          </div>
        </div>
        <p className="des-text">
          These Terms and Conditions ("Agreement") govern your use of the services provided by Cybertron Softech Inc. By
          engaging our web development services, you agree to be bound by this Agreement. It is important to read and
          understand these terms before proceeding with our services.
        </p>
        <h4>1. Scope of Services</h4>
        <div className="policy-des">
          <p className="des-text">
            We will provide web development services as agreed upon between you and the Company. This may include
            website design, development, customizations, content creation, integration of third-party tools, and other
            related services. The specific details of the services will be outlined in the project proposal or
            agreement.
          </p>
        </div>
        <h4>2. Project Requirements and Communication</h4>
        <div className="policy-des">
          <p className="des-text">
            It is your responsibility to provide us with accurate and complete project requirements, content, and
            necessary materials within the agreed-upon timeframe. We rely on your timely input, feedback, and approvals
            to ensure the smooth progress of the project. We will communicate primarily via email, phone, or project
            management tools mutually agreed upon.
          </p>
        </div>
        <h4>3. Intellectual Property Rights</h4>
        <div className="policy-des">
          <p className="des-text">
            Upon full payment and completion of the project, the intellectual property rights of the developed website
            or software will be transferred to you, subject to the following conditions:
          </p>
          <p className="des-text m-0">
            - You guarantee that all materials provided to us for the project are owned by you or that you have the
            necessary permissions to use them.
          </p>
          <p className="des-text">
            - We retain the right to use and display your project in our portfolio and marketing materials, unless
            otherwise agreed upon in writing.
          </p>
        </div>
        <h4>4. Payment Terms</h4>
        <div className="policy-des">
          <p className="des-text">
            The pricing and payment terms for our services will be outlined in the project proposal or agreement. Unless
            otherwise stated, payments will be made according to the following terms:
          </p>
          <p className="des-text m-0">- A deposit or upfront payment may be required before starting the project.</p>
          <p className="des-text m-0">
            - Progress payments may be requested at specific milestones or intervals as determined in the project
            proposal.
          </p>
          <p className="des-text m-0">- The final payment is due upon completion and delivery of the project.</p>
          <p className="des-text m-0">- Late payments may incur additional fees or result in project delays.</p>
        </div>
        <h4>5. Confidentiality</h4>
        <div className="policy-des">
          <p className="des-text">
            Both parties agree to keep any confidential information received during the project confidential. This
            includes but is not limited to project details, trade secrets, proprietary information, and any other
            information designated as confidential by either party.
          </p>
        </div>
        <h4>6. Limitation of Liability</h4>
        <div className="policy-des">
          <p className="des-text">
            To the fullest extent permitted by law, the Company will not be liable for any indirect, incidental,
            consequential, or punitive damages arising from the use of our services. We will not be responsible for any
            loss or damage resulting from the use or inability to use the delivered website or software.
          </p>
        </div>
        <h4>7. Termination</h4>
        <div className="policy-des">
          <p className="des-text">
            Either party may terminate the agreement by providing written notice in case of a material breach by the
            other party. In the event of termination, you will be responsible for any outstanding payments for services
            rendered up to the termination date.
          </p>
        </div>
        <h4>8. Governing Law and Jurisdiction</h4>
        <div className="policy-des">
          <p className="des-text">
            This Agreement shall be governed by and interpreted in accordance with the laws of the jurisdiction in which
            the Company is located. Any disputes arising from or relating to this Agreement shall be subject to the
            exclusive jurisdiction of the courts in that jurisdiction.
          </p>
        </div>
        <h4>9. Amendments</h4>
        <div className="policy-des">
          <p className="des-text">
            We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Any
            changes will be effective upon posting the revised version on our website. Your continued use of our
            services after any such changes constitutes your acceptance of the revised Terms and Conditions.
          </p>
        </div>
        <h4>10. Entire Agreement</h4>
        <div className="policy-des">
          <p className="des-text">
            This Agreement constitutes the entire understanding between you and the Company regarding the subject matter
            discussed herein and supersedes any prior agreements, discussions, or understandings, whether written or
            oral.
          </p>
          <p className="des-text">
            If you have any questions or concerns regarding these Terms and Conditions, please contact us before
            proceeding with our services.
          </p>
          <p className="des-text">
            By engaging our services, you acknowledge that you have read, understood, and agreed to these Terms and
            Conditions.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
