import React from 'react';
import Layout from '../components/layout/Layout';
import CenteredTitle from '../components/UIComponents/CenteredTitle';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import { assetImages } from '../constant/images';

const Portfolio = () => {
  const portfolioItems = [
    {
      id: 1,
      image: assetImages.flistaa,
      link: 'https://www.flistaa.com/',
    },
    {
      id: 2,
      image: assetImages.wholisticcure,
      link: 'https://wholisticcure.com/',
    },
    {
      id: 3,
      image: assetImages.dhemo,
      link: 'https://play.google.com/store/apps/details?id=com.bakeryshop&pli=1',
    },
    {
      id: 4,
      image: assetImages.prayanelectricals,
      link: 'https://www.prayanelectricals.com/',
    },
    {
      id: 5,
      image: assetImages.prayoshafoodingredients,
      link: 'https://prayoshafoodingredients.com/',
    },
    {
      id: 6,
      image: assetImages.tileswale,
      link: 'https://tileswale.com/',
    },
    {
      id: 7,
      image: assetImages.withlovenregards,
      link: 'https://www.withlovenregards.com/',
    },
    {
      id: 8,
      image: assetImages.stratboard,
      link: 'https://www.strat-board.com/',
    },
    {
      id: 9,
      image: assetImages.b2bshopmart,
      link: 'http://b2bshopmart.com/',
    },
    {
      id: 10,
      image: assetImages.careermakerseducation,
      link: 'https://www.careermakerseducation.in/',
    },
    {
      id: 11,
      image: assetImages.gatewayschoolmumbai,
      link: 'https://www.gatewayschoolmumbai.org/',
    },
    {
      id: 12,
      image: assetImages.treeium,
      link: 'https://treeium.com/',
    },
    {
      id: 13,
      image: assetImages.industrykart,
      link: 'https://www.industrykart.com/',
    },
    {
      id: 14,
      image: assetImages.acehardware,
      link: 'https://call-collect.acehardware.ph/',
    },
    {
      id: 15,
      image: assetImages.rebath,
      link: 'https://www.rebath.com/',
    },
    {
      id: 16,
      image: assetImages.newzealand,
      link: 'https://www.newzealand.com/',
    },
    {
      id: 17,
      image: assetImages.easyeda,
      link: 'https://easyeda.com/',
    },
    {
      id: 18,
      image: assetImages.simplychocolatecph,
      link: 'https://simplychocolatecph.com/',
    },
    {
      id: 19,
      image: assetImages.vihita,
      link: 'http://www.vihita.com/',
    },
    {
      id: 110,
      image: assetImages.handyman,
      link: 'https://handyman.com.ph/',
    },
    {
      id: 21,
      image: assetImages.ikarein,
      link: 'https://www.ikarein.com/',
    },
    {
      id: 22,
      image: assetImages.happywishcare,
      link: 'https://www.happywishcare.com.au/',
    },
    {
      id: 23,
      image: assetImages.aircompressorindia,
      link: 'http://www.aircompressorindia.com/',
    },
    {
      id: 24,
      image: assetImages.solarpowersupply,
      link: 'https://solarpowersupply.net/',
    },
  ];
  return (
    <Layout>
      <section className="about-us-banner">
        <div className="container-lg">
          <div className={`row align-items-center gx-3 gy-3`}>
            <div className="col-lg-6">
              <OneSideTitle
                title="Creative Work Portfolio"
                des={
                  <>
                    We work dedicatedly to craft top-quality products backed by futuristic technologies to make your
                    business successful in the digital world.
                  </>
                }
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={assetImages.serviceBanner} alt="serviceBanner" className="bg-vector" />
              <img
                src={assetImages.portfoliobanner}
                alt="whatwedo"
                className="img-fluid sec-image"
                style={{ maxHeight: '18rem' }}
              />
            </div>
          </div>
        </div>
      </section>
      <CenteredTitle title="Successfully Completed Work" />
      <section className="portfolio-cards">
        <div className="container-lg">
          <div className="row justify-content-center">
            {portfolioItems?.map((itms, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={itms.id}>
                <a rel="noreferrer" href={itms.link} target="_blank">
                  <div className="port-card">
                    <img src={itms.image} alt={`portfolio-${i}`} className="img-fluid" />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Portfolio;
