import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';

const WhatWeDo = () => {
  const woDo = [
    {
      id: 1,
      logo: assetImages.appdevelopment,
      title: 'Web & Mobile App Development',
      des: 'We build the highest quality, feature-rich and top -rated websites and mobile applications that let you manage your business more effectively and provide amazing experiences.',
    },
    {
      id: 2,
      logo: assetImages.ui,
      title: 'UI/UX Design & Development',
      des: 'We craft the most intuitive and mobile-friendly UI/ UX design of your website or application that drives more customer attention to boost user engagement and customer retention rates.',
    },
    {
      id: 3,
      logo: assetImages.digitalmarketing,
      title: 'Digital Marketing',
      des: 'We offer next-gen digital marketing solutions that help your business to thrive in the digital world. We strive hard to make every business successful by providing result-driven digital solutions.',
    },
  ];
  return (
    <section className="what-we-do-sec">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img src={assetImages.whatwedo} alt="whatwedo" className="img-fluid sec-image" />
          </div>
          <div className="col-lg-6">
            <OneSideTitle
              title={'What We Do'}
              des="We provide top-quality web & mobile apps and custom software development services for startups, SMEs and
              brands to help them scale-up in the next-gen world. Client satisfaction is our top priority and we aim to
              build business-focused and scalable solutions to help achieve their business goal."
            />
            {woDo.map((itm) => (
              <div key={itm.id} className="we-do-itm-wrapper">
                <div className="we-do-image-wrapper">
                  <img alt={itm.title} src={itm.logo} className="img-fluid" />
                </div>
                <div className="we-do-content">
                  <h4 className="item-title">{itm.title}</h4>
                  <p className="des-text">{itm.des}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
