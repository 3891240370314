import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import SerKeyBenefits from '../../components/services/SerKeyBenefits';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const CloudMig = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Why Automated Cloud Migration Services',
      des: (
        <>
          The reason for migrating to clouds is its agile working and efficiency it provides. With Cloud computing, we
          can turn up numerous servers in minutes instead of 10 to 18 weeks it ordinarily takes to turn up servers being
          utilized on-premises. Cloud Migration services identify the areas of risks and open doors for enhancing
          performance and productivity of a business critical function. Cloud provides more safe and secure functions
          that server cannot all on your fingertips. Powerful cloud benefits can be realized with minimal cost and
          delay.
        </>
      ),
      image: assetImages.cloudapp1,
    },
  ];
  const benefits = [
    {
      id: 1,
      title: <>ELASTICITY</>,
      des: <>Easily to scale up cloud capacity, fluctuating bandwidth and many more.</>,
    },
    {
      id: 2,
      title: <>COST REDUCTION</>,
      des: <>You can save substantial capital costs and pay for what is used.</>,
    },
    {
      id: 3,
      title: <>RELIABILITY</>,
      des: (
        <>
          Much more reliable and consistent than in-house infrastructure. Organizations can benefit from a massive pool
          of redundant IT resources.
        </>
      ),
    },
    {
      id: 4,
      title: <>MANAGEABILITY</>,
      des: (
        <>
          Simplified IT management and maintenance capabilities, fulfilling a simple web-based user interface for
          accessing software services.
        </>
      ),
    },
    {
      id: 5,
      title: <>AUTOMATED PROVISION</>,
      des: <>Automated Provision by decreasing bugs and errors.</>,
    },
    {
      id: 6,
      title: <>VALIDATION</>,
      des: <>Agile validation and testing can be done without disrupting replication.</>,
    },
    {
      id: 7,
      title: <>DISASTER RECOVERY</>,
      des: <>Cloud based backups and recovery techniques of data for disaster management.</>,
    },
    {
      id: 8,
      title: <>WORK FROM ANYWHERE</>,
      des: <>You can work from anywhere with cloud computing, you just need internet connection.</>,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Automated Cloud Migration Services"
        description={
          <>
            Since many years we are in cloud computing industry delivering Cloud Migration Services and built a
            reputation of working with cloud migration software. We have wrapped that in expert services and a
            proprietary methodology for success. We provide you with end-to-end cloud migration services for every
            business solution to enterprise migrations from small scale to large scale industries.
            <br />
            <br />
            We are the best cloud migration service provider company offering you with a variety of cloud options that
            help you grow your business. We work parallel considering today's trend and so we won't leave you behind,
            taking to moving to cloud application migration. With careful planning, preparation, methodology and
            strategies our work evolves from all areas to perform the migration.
          </>
        }
        serviceImg={assetImages.cloudmig}
        maxHeight="18rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg
        servicesWeProvide={servicesWeProvide}
        whyChoose={true}
        des="Cybertron Softech has Experts of technologies, who not only help reduce the migration costs, but also maintain the efficiency of the application well. Seamless migration remains the motto of the professional experts that helps out in maintaining the fidelity of the business logic."
      />
      <SerKeyBenefits title="Key Benefits of Cloud Migration Services" benefits={benefits} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default CloudMig;
