import React, { useState } from 'react';
import { ImArrowDown, ImArrowRight } from 'react-icons/im';
import OneSideTitle from '../UIComponents/OneSideTitle';

const ServiceFAQS = ({ faqs }) => {
  const [activeacc, setActiveacc] = useState(1);
  return (
    <section className="services-faqs">
      <div className="container-lg">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <OneSideTitle title={'FAQs'} />
            {faqs.map((itm) => (
              <div
                key={itm.id}
                className="faq-itm-wrapper"
                onClick={() => {
                  if (activeacc === itm.id) {
                    setActiveacc('');
                  } else {
                    setActiveacc(itm.id);
                  }
                }}
              >
                <div className="faq-content-wrapper">
                  <h4>{itm.title}</h4>
                  {activeacc === itm.id && <p className="des-text">{itm.des}</p>}
                  {itm.listItems?.map((fet) => (
                    <div className="feature-wrapper" key={fet}>
                      <div className="fet-dot"></div>
                      <p>{fet}</p>
                    </div>
                  ))}
                </div>
                <div className={`faq-icon-wrapper ${activeacc === itm.id ? 'active' : 'inactive'}`}>
                  {activeacc === itm.id ? <ImArrowDown /> : <ImArrowRight />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceFAQS;
