import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';

const HereToHelp = () => {
  return (
    <section className="here-to-help">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <OneSideTitle
              title="We're Here to Help, are you sure where to start?"
              des="Our team of digital and business experts will guide you to the right direction."
            />
          </div>
          <div className="col-lg-6">
            <img src={assetImages.heretohelp} alt="serviceBanner" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HereToHelp;
