import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const LandingPages = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: <>Our Process</>,
      image: assetImages.landingPage1,
      listItems: [
        <>
          <span className="text-orage">WE DESIGN</span> – Our expert team of visually creative minds sit together and
          work out the most amazing look for your page. If it doesn’t blow you away the first time, we refine it until
          it does.
        </>,
        <>
          <span className="text-orage">WE DEVELOP</span> – With design complete and confirmed by you, our tech head
          developers get right in and start building with all the pin point accuracy of a scalpel and the speed of a
          race car.
        </>,
        <>
          <span className="text-orage">THEY CONVERT</span> – Long form landing pages done well have the power to convert
          (make $$, increase enquiries, grow mailing lists: whatever your KPI is) up to 220% more than the average rate.
          With our keen marketing eye, everything that goes out from our teams is built to bring you more business.
        </>,
      ],
      maxHeight: '27rem',
    },
    {
      id: 2,
      title: <>Pages that tell A Story</>,
      maxHeight: '30rem',
      des: (
        <>
          Our landing pages are designed to look professional, reflect your branding guidelines & inform users about
          businesses relevant to their needs. But Most importantly – our landing pages are built to generate revenue &
          build your business.
          <br />
          <br />
          Landing pages are an easy & affordable alternative to a fully stacked websites, and when paired with one of
          our advertising strategies – they’re unstoppable machines. One of the best parts about landing pages is that
          they’re cleverly engineered to achieve a specific goal all within the space of a single page – whether it’s
          growing your mailing list, presenting a limited time offer, getting client sign ups, or downloading a freebie
          – our landing pages are the quickest and most effective way to get you to your end goal. Sound good to you?
          Why not give us a pressure free call to explore how a landing page design could help you get ahead of your
          competition? Book a casual catch up with us today, and let’s go grab a coffee and chat about scaling your
          business.{' '}
        </>
      ),
      image: assetImages.landingPage2,
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="Landing Page Design"
        description={
          <>
            <span className="text-orage">IF THEY'RE GOOD ENOUGH FOR OBAMA, THEY'RE GOOD ENOUGH FOR YOU.</span>
            <br />
            You read that right, Obama used landing pages and generated an additional 60 million US smacks for his
            presidential election campaign.
            <br />
            <br />
            So when our clients ask us “How can we supercharge our advertising and make sales?”, we know the answer.
            Invest in a tailored, high-converting landing page design to spread your core message. If you’re browsing
            the web and you see an ad which sparks your interest, wouldn’t you want to end up somewhere highly relevant
            to what you’ve clicked on? Your answer should be a resounding yes, and here at Cybertron Softech we have the
            power to make that happen.
            <br />
            <br />
            Our passionate & super talented design and marketing departments work together to plan & develop amazing
            visual spaces online to promote and sell your product. With our landing page design service, you’ll be
            guaranteed a landing page that you can not only be proud of, but also rest easy knowing you’ll be able to
            increase your enquiries and generate more revenue like hotcakes on Sunday.
          </>
        }
        serviceImg={assetImages.landingPageBanner}
        maxHeight="28rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default LandingPages;
