import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Adwords = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Our Approach To Adwords As An Agency',
      des: (
        <>
          AdWords can burn a hole in your wallet or fill it right up depending on your Adwords campaign strategy and
          what your business goals are. That’s why we take the time to understand your business objectives, where you’re
          going and what it’s going to take to get you there. If we’ve determined that both AdWords and Cybertron
          Softech is the right path for you, we’ll start with a thorough PPC keyword analysis, before crafting a
          tactical campaign to achieve your particular targets. We don’t believe in a one size fits all approach, that’s
          why no two accounts are the same. We custom design your AdWords management campaign to increase your CTR,
          lower your cost per click, improve your conversion rate, and decrease your cost per acquisition.
          <br />
          <br />
          Your personal Google AdWords consultant will focus on determining qualified keywords that are strategically
          matched with high converting ad copy that not only engages potential customers but compels them to take
          action. Once they take action, we direct them to the most relevant landing page that offers the best possible
          opportunity to convert your visitors into leads. We pride ourselves on having the best AdWords experts in
          Ontario, experienced business strategists that strive to build profitable PPC marketing campaigns.
          <br />
          <br />
          We might suggest search, Youtube, Gmail, remarketing or display campaigns, it all depends on what your
          objectives are. With every strategy we decide on, you can be sure that you’ll be getting the very best adwords
          Ontario has to offer, every time.
          <br />
          <br />
          Let’s start the conversation today, and we can work together to build your business.
        </>
      ),
      image: assetImages.adWordsFet1,
    },
    {
      id: 2,
      title: 'PPC Reporting & Management',
      des: (
        <>
          Make good use of Cybertron Softech for your AdWords management & marketing – we are experts at recognising the
          value of key phrase taxonomy based on real information in your projects.
        </>
      ),
      image: assetImages.seoFet4,
      listItems: [
        'Full AdWords Management and Focused Campaign Setup',
        'Highly Targeted Campaign and Adgroup Structure',
        'Negative Keyword Management',
        'Daily Bidding and Optimisation',
        'Extension Optimisation',
        'A/B Testing Ad Copies, Landing Pages',
        'New Keyword Research',
        'Data Driven Bidding adjustments and Optimisation',
        'Bidding Adjustments by device, location, time, gender, age & income',
        'Search Campaigns',
        'Remarketing – search & display',
        'Display Campaigns',
        'Gmail Campaigns',
        'Youtube Campaigns',
        'Landing Page Analysis',
        '1 Highly experienced Account Manager',
        '1 Highly experienced PPC Specialist',
        'Full Detail Monthly Reporting',
      ],
      maxHeight: '25rem',
    },
    {
      id: 3,
      title: 'Google Ads Specialisation',
      des: (
        <>
          In addition to Google Ads, we are highly specialised on the skills below. Being a preferred Google partner, we
          maintain the highest level of service and performance for the following Adwords services below:
        </>
      ),
      image: assetImages.adWordsFet3,
    },
    {
      id: 4,
      title: 'Reporting & Management',
      des: (
        <>
          Every Cybertron Softech client receives a dedicated account manager from Cybertron Softech and a PPC marketing
          agency expert to develop and manage their campaign. Depending on the Key Performance Indicator (KPI) and
          business goals described during the consultation stage, the reporting is personalized to suit your needs.
          However, we ensure that we provide transparent reporting and easy to understand insights to help you, as a
          business owner, make better strategic decisions.
          <br />
          <br />
          We use the up-to-date PPC management agency reporting software that will ensure accurate, fast and real-time
          reporting.
        </>
      ),
      image: assetImages.adWordsFet4,
      maxHeight: '25rem',
    },
    {
      id: 5,
      title: 'Other Media Buying Services',
      image: assetImages.adWordsFet5,
      listItems: [
        'Content Discovery Advertising',
        'Programmatic',
        'Mobile App Advertising',
        'Facebook Advertising',
        'Pinterest Ads',
        'Linkedin Ads',
      ],
    },
  ];
  const features = [
    {
      id: 1,
      title: 'Banner Ads',
      des: 'Display advertising is a seriously cool & Powerful online marketing strategy.',
    },
    {
      id: 2,
      title: 'Google Shopping',
      des: 'Display advertising is a seriously cool & Powerful online marketing strategy.',
    },
    {
      id: 3,
      title: 'Youtube Ads',
      des: 'Display advertising is a seriously cool & Powerful online marketing strategy.',
    },
    {
      id: 4,
      title: 'Facebook Marketing',
      des: 'Display advertising is a seriously cool & Powerful online marketing strategy.',
    },
    {
      id: 5,
      title: 'Spotify Ads',
      des: 'Display advertising is a seriously cool & Powerful online marketing strategy.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Google Adwords Management"
        description={
          <>
            <span className="text-orage">CERTIFIED EXPERTS, DATA-DRIVEN, ADVANCED OPTIMISATIONS </span>
            <br />
            Only 3% of Google Adwords /PPC management agencies are Google Premier Partners, and we’re one of them. We’re
            not trying to toot our own horn but, ‘Toot, toot’.
          </>
        }
        serviceImg={assetImages.adWordsBanner}
        maxHeight="25rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFeatures isFromAdword={true} features={features} title="Want to Know More?" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Adwords;
