import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import { assetImages } from '../../constant/images';

const NodeStack = () => {
  const features = [
    {
      id: 1,
      title: 'API INTEGRATION AND DEVELOPMENT',
      des: 'Node Js developers can integrate and develop functional APIs according to the requirement. With deep knowledge of Node Js framework, we speed up the API integration and development process.',
      image: assetImages.nodeapi,
    },
    {
      id: 2,
      title: 'REAL-TIME CHAT APPS',
      des: 'We can build robust and secure chat apps powered by Node Js. As an established Node Js development company, we leveraged Node Js for end-to-end real-time chat app development, effectively.',
      image: assetImages.nodechat,
    },
    {
      id: 3,
      des: 'Understanding end-user needs and preferences is essential for the success of a solution. Our insights into the user psyche enable us to design an intuitive UI that promises an engaging user experience.',
      title: 'WEB APP UI/UX DEVELOPMENT',
      image: assetImages.nodeweb,
    },
    {
      id: 4,
      title: 'NODE JS PLUGIN DEVELOPMENT',
      des: 'Modern web platforms are powered by feature-rich plugins. We can enrich your existing Node Js web application with the right plugin, designed specifically as per your custom requirements.',
      image: assetImages.reactplugin,
    },
    {
      id: 6,
      title: 'AJAX DEVELOPMENT',
      des: 'AJAX is a modular development technique for developing robust solutions. Our experts adopt the AJAX approach for creating innovative solutions powered by Node Js.',
      image: assetImages.nodeajax,
    },
    {
      id: 7,
      title: 'PORTAL DEVELOPMENT',
      des: 'Node Js can be a perfect choice to build a web portal from scratch. Our services are focused on developing a feature-rich, secure, responsive and efficient platform powered by Node Js framework.',
      image: assetImages.nodeportal,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Node JS Development Company"
        description="A Node Js framework is an advanced run-time environment that enables developers to execute JavaScript code outside a browser. Node Js developers use the platform to develop a highly scalable and dynamic solution on both the client and server end from a single command line. Node Js development services are used to unify web application development using a single programming language to promote dynamic, agile and responsive websites. At Cybertron Softech, we provide end-to-end Node Js development services that help you accelerate on the digital growth path."
        serviceImg={assetImages.nodebanner}
        maxHeight="22rem"
      />
      <ServiceSecTwo
        title="Why Choose Node Js Web Applications?"
        description="The Node Js framework can be used to develop versatile and dynamic solutions. With the help of a Node Js web application services provider, an enterprise can build non-buffering web solutions in real-time. There are several benefits of choosing Node Js."
        serviceImg={assetImages.nodesec}
      />
      <ServiceFeatures features={features} title="For Resilient Digital Transformation" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default NodeStack;
