import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';

const ServiceBanner = ({ title, description, serviceImg, maxHeight, isVectorHidden }) => {
  return (
    <section className="service-banner">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <OneSideTitle title={title} des={description} />
          </div>
          {serviceImg && (
            <div className="col-lg-6 image-wrapper-col">
              {!isVectorHidden && (
                <img src={assetImages.serviceBanner} alt="serviceBanner" className="banner-bg-vector" />
              )}
              <img src={serviceImg} alt={title} style={{ maxHeight: maxHeight }} className="serviceImg" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceBanner;
