import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';

const OurMilestone = () => {
  const milestones = [
    {
      id: 1,
      title: '5+ Years of Industry Expertise',
      des: 'With over 5 years of industry expertise, we have worked with varied clients ranging from different industry verticals and helped them achieve their business goals.',
    },
    {
      id: 2,
      title: '400+ Projects Completed',
      des: 'We have completed over 400+ projects with 100% client satisfaction before the deadline while maintaining a long -term relationship with our clients.',
    },
    {
      id: 3,
      title: '100+ Dedicated Professionals',
      des: 'We hold a dynamic team of 100+ IT experts covering skilled developers, creative designers, expert SEOs, certified writers and project managers to meet all your business needs.',
    },
  ];
  return (
    <section className="our-milestone">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-6">
            <OneSideTitle
              title={'Our Milestone'}
              des="We have completed hundreds of projects and helped thousands of clients achieve their business goals by
              providing world-class customized IT digital solutions with on-time project delivery."
            />
            {milestones.map((itm) => (
              <div key={itm.id} className="milestone-itm-wrapper">
                <div className="orange-line"></div>
                <div className="milestone-content">
                  <h4 className="item-title">{itm.title}</h4>
                  <p className="des-text">{itm.des}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6">
            <img src={assetImages.ourmilestone} alt="whatwedo" className="img-fluid sec-image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMilestone;
