import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import CenteredTitle from '../../components/UIComponents/CenteredTitle';
import { assetImages } from '../../constant/images';

const DrupalWeb = () => {
  const features = [
    {
      id: 1,
      title: 'RELIABLE AND SAFE',
      des: 'Provide all quality standards and safety measures.',
      image: assetImages.drupal1,
    },
    {
      id: 2,
      title: 'SEO FRIENDLY',
      des: 'Flexible enough to display content in a way search engines want.',
      image: assetImages.drupal2,
    },
    {
      id: 3,
      title: 'CUSTOMIZABLE ACCESS CONTROL',
      des: 'Highly customizable which gives heaps of choices to developers and designers.',
      image: assetImages.drupal3,
    },
    {
      id: 4,
      title: 'ENTERPRISE FUNCTIONALITIES',
      des: 'Build-in functionalities like version control, comments, forum, flexible access control management.',
      image: assetImages.drupal4,
    },
    {
      id: 6,
      title: 'OPEN SOURCE SOFTWARE',
      des: 'Free themes available online reduce web development cost significantly.',
      image: assetImages.drupal5,
    },
    {
      id: 7,
      title: 'RAPID DEPLOYMENT',
      des: 'Enables companies to deploy functionalities and features of the site.',
      image: assetImages.rapiddev,
    },
    {
      id: 5,
      title: 'INTEGRABLE',
      des: 'Readily integrates with other applications.',
      image: assetImages.drupal7,
    },
    {
      id: 8,
      title: 'API SUPPORT',
      des: 'Drupal provides extensive support for API’s like Facebook, twitter etc.',
      image: assetImages.drupal8,
    },
    {
      id: 9,
      title: 'EASY CONTENT AUTHORING',
      des: 'Essential tools are available for content creation and publishing like WYSIWYG.',
      image: assetImages.drupal9,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Drupal Website Development Company"
        description={
          <>
            Cybertron Softech, Drupal Development Company brings you many years of experience in developing the most
            functional, secure and cost effective Drupal development solutions for clients across the globe. Our Drupal
            expertise across the verticals of eCommerce, travel, healthcare, CRM, Mobile Applications, insurance-
            banking, social media and telecom has enabled us creating websites that belong to diverse domains along with
            customized Drupal features.
            <br />
            <br />
            Drupal is free and Open Source for content management. Our Drupal development experts develop blogs and
            enterprise applications according to the customer’s business needs.
            <br />
            <br />
            The team of knowledgeable and extremely proficient Drupal web developers brings you a complete range of
            Drupal web development services.
          </>
        }
        serviceImg={assetImages.drupalbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="What Is Drupal Development Services"
        description="Drupal open source software, particularly created for a content management system (CMS) framework. Drupal is composed in PHP code and, in this manner, it spares cash with regards to building up a site utilizing it. Publishes a single website or shares content in multiple languages across many devices. Drupal cms enables designers to customize innovative templates. It provides many add-ons and designs that makes it a popular choice among developers, designers and business users."
        serviceImg={assetImages.drupalsec}
        maxHeight="20rem"
      />
      <CenteredTitle
        title="Why Choose Us"
        des="Our Drupal website developers with ample knowledge and rich experience can create customized designs that are optimized for the way your website works. Our Experts produce advanced, interactive and modern solutions for your business. In addition, we also provide you a complete control to publish, organize and edit the content online."
      />
      <ServiceFeatures features={features} title="Key Benefits Of Drupal Development" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default DrupalWeb;
