import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';

const EstimateCost = () => {
  return (
    <section className="estimate-cost">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <OneSideTitle
              title={'Estimate The Cost of Your Project?'}
              des=" Get an estimate of time and cost for your exclusive business needs. Connect with our experts now, without
              any delay."
            />
            <Link to="/estimate-your-project" onClick={scrollToTop}>
              <button className="orage-bg-whiote-text-button">Estimate Your Project</button>
            </Link>
          </div>
          <div className="col-lg-6">
            <img src={assetImages.estmitaecost} alt="whatwedo" className="img-fluid sec-image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EstimateCost;
