import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const FaceBookMarketing = () => {
  const servicesWeProvide = [
    {
      id: 2,
      title: 'Facebook Retargeting',
      des: (
        <>
          Let’s talk about one of our favourite Facebook Advertising features – Retargeting. Retargeting is available
          across most PPC platforms & they all in essence do the same thing. Facebook retargeting works like this. When
          we set you up with Facebook Ads, Facebook Ads in return, offers you a pixel (it’s kind of like an ID). We
          place that pixel within your website, and that pixel then inserts a cookie into the browsers of customers that
          visit your site.
          <br />
          <br />
          Facebook retargeting Ads show your ad only to people that have visited your website within the last 180 days.
          It’s kind of like that scene in the movie when the two people are in love, and one of them is ready to dive in
          deep but even though the other one knows they’re in love too, they’re scared to make the leap – what happens
          if they realise it’s not really for them? What if they want to go back to being friends? As a business, your
          customers are for the most part, the ones that are scared of your love, even if they feel the same.
          Retargeting prompts them to take the leap. Over time, they come to trust you, and when they do want to act,
          you’re right there, ready to hold their hand through the whole process.
          <br />
          <br />
          At Cybertron Softech, we develop strategic Facebook advertising services & retargeting campaigns that narrow
          down on your customers site behaviour. With the data analysed, we’re able to cater our ads specifically to
          each individual visitors behaviour ensuring the information we present about your business aligns with their
          intent for being on your site. It sounds tricky, and it is, those in the know can give you a high ROI on a low
          budget, but those that aren’t can really blow money with very little to show for it.
        </>
      ),
      image: assetImages.fbMarkFet1,
      maxHeight: '25rem',
    },
    {
      id: 3,
      title: 'Smart Prospecting using Facebook',
      des: (
        <>
          Facebook’s Organic reach is dismal right now (you can thank Facebook’s algorithms for that). That’s why Ads
          are absolutely crucial for your facebook marketing campaigns. And honestly, Facebook Ads offers some seriously
          exciting targeting capabilities, so it’s really not a bad thing. The options for your custom audience creation
          on facebook are unlike any other social media platform out there. From age, gender, location, interests,
          hobbies and engagement – we can really create an awesome ad experience for your customers that they will
          actually enjoy and benefit from.
          <br />
          <br />
          As a highly experienced facebook advertising agency, Cybertron Softech take advantage of these crazily good
          features and craft your Facebook campaigns like a beautiful Michelangelo bloody painting. With our advanced
          facebook ad sequencing, we can get you awesome conversion rates at a seriously low cost, and that’s because
          our team are always researching to stay ahead of the game and beat Facebook’s algorithms, which really are
          giving Google a run for their money. We constantly tweak and adjust your facebook ads and audiences depending
          on the audience analytics and ensure we’re showcasing your products to the audience that wants to purchase,
          optimizing's your ad for ultimate leads & sales.
        </>
      ),
      image: assetImages.fbMarkFet2,
      maxHeight: '25rem',
    },
    {
      id: 4,
      title: 'Lead Generating Facebook Ads Agency',
      des: (
        <>
          If you’re using Cybertron Softech as your facebook advertising agency, then you can expect an epic increase in
          leads generated during the time we manage you. We’ve had extensive experience in social media marketing in
          Ontario, and understand how to position your ad in a way that compels people to throw themselves at you,
          figuratively. We have a growing amount of awesome business like yourself that we’ve worked with for years, and
          as you might know by now, we don’t do contracts. We let the results be all the reason you need to stick by us
          every month. That’s why you can trust us to ensure your ad campaigns are tailored to the peak of performance,
          ensuring you gain an impressive ROI for your business. With some of the best facebook marketing Ontario has to
          offer, what are you waiting for?
        </>
      ),
      image: assetImages.fbMarkFet3,
      maxHeight: '25rem',
    },
    {
      id: 5,
      title: 'Brand Awareness',
      des: (
        <>
          If you’re launching a new product & find traditional marketing too expensive, then digital is most definitely
          the way to go for you to build your brand and gain that massive cult like following! Our Facebook, GDN & Video
          Advertising solution will reach millions of people for a surprisingly modest cost! We can help you get likes,
          comments and shares that drive credibility and trust for your business! Not only that, but we design fresh
          creative & relevant ad content every 3 months for your audience as part of our service. Talk about damn good
          value.
        </>
      ),
      image: assetImages.fbMarkFet4,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Facebook Marketing Services"
        description={
          <>
            <span className="text-orage">A FACEBOOK ADVERTISING AGENCY TO DRIVE LEADS AND GROW YOUR BRAND</span>
            <br />
            Facebook has more than 2 billion daily active users, and for every second your business isn’t advertising in
            facebook – your competitors are snapping up your potential customers!
          </>
        }
        serviceImg={assetImages.fbMarkBanner}
        maxHeight="24rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default FaceBookMarketing;
