import React from 'react';
import Newsletter from '../components/commonComponents/Newsletter';
import Layout from '../components/layout/Layout';

import ServiceCardsHorizon from '../components/services/ServiceCardsHorizon';
import ServiceBanner from '../components/services/ServiceBanner';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import { assetImages } from '../constant/images';

const UIUXDesign = () => {
  const features = [
    {
      id: 1,
      des: (
        <>
          We offer UI and usability testing solutions that provide the most convenient and user-friendly experience for
          your customers.
        </>
      ),
      title: 'UI And Usability Testing',
      image: assetImages.uiux1,
    },
    {
      id: 2,
      des: (
        <>
          We do an in-depth analysis to focus on essential factors, like architecture, sitemap, and content inventory.
        </>
      ),
      title: 'Design Usability Audit',
      image: assetImages.uiux2,
    },
    {
      id: 3,
      des: (
        <>
          We create the most appealing and attractive UI/UX designs for your websites and apps to deliver the best user
          experience.
        </>
      ),
      title: 'Interactive Design',
      image: assetImages.uiux3,
    },
    {
      id: 4,
      des: (
        <>
          We offer the best UI/UX services based on interaction design & usability engineering to deliver a better UX
          experience as per your business goals.
        </>
      ),
      title: 'Experience Design',
      image: assetImages.uiux4,
    },
    {
      id: 5,
      des: (
        <>
          Our experienced developers aim to deliver cross-platform supported and responsive designs for your websites
          and apps.
        </>
      ),
      title: 'Platform Compatibility',
      image: assetImages.uiux5,
    },
    {
      id: 6,
      des: (
        <>
          We perform the best practices to define and plan the information hierarchy of design for your websites and
          apps.
        </>
      ),
      title: 'Wireframes',
      image: assetImages.uiux6,
    },
    {
      id: 7,
      des: (
        <>
          Our skilled designers offer user testing and feedback support to enhance the UI/UX design based on user
          experience and interaction.
        </>
      ),
      title: 'Feedback And Testing',
      image: assetImages.uiux7,
    },
    {
      id: 8,
      des: (
        <>
          Our UI/UX experts also provide architecture support UI services to maximize the benefit through the targeted
          audience.
        </>
      ),
      title: 'Information Architecture Support',
      image: assetImages.uiux8,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Leading UI/UX Design 
        Services Company in 
        the UK"
        description={
          <>
            We craft a unique, customer-centric, and eye-catchy UI/UX design that drives more customer attention to
            boost conversions.
          </>
        }
        isVectorHidden={true}
        serviceImg={assetImages.uiuxbanner}
        maxHeight="22rem"
      />
      <section className="service-banner">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-6">
              <img src={assetImages.uiuxsec} alt={'uiuxsec'} style={{ maxHeight: '22rem' }} className="serviceImg" />
            </div>
            <div className="col-lg-6">
              <OneSideTitle
                title={'Fully Responsive UI/UX Design Services in the UK'}
                des="UI/UX design plays a vital role in helping your business provide the best 
experience for customers and encourage them to browse more pages. 
Customers may leave your website if it doesn’t give a great look and feel 
to them. They might probably like to visit another site that catches their 
attention and delivers the best user experience. No matter how unique 
and fast-loading a website or mobile app you have, it makes no sense if it 
doesn’t make a great look and feel to your customers on all devices, 
including mobile, tablets, and desktops. When it comes to UI/UX design, 
Cybertron Softech is a leading UI/UX development company that provides the 
most appealing, fully responsive, and eye-catchy UI/UX design services at 
reasonable prices."
              />
            </div>
          </div>
        </div>
      </section>
      <ServiceCardsHorizon
        title="Intuitive UI/UX Design to 
Create Better User Experiences"
        features={features}
      />
      <ServiceBanner
        title="Why Cybertron Softech for UI/UX 
        Design Services"
        description={
          <>
            Being a pre-eminent UI/UX design services company in the UK, at Cybertron Softech, we aim to provide
            end-to-end UI/UX design services to address all your business needs at affordable prices. We have a team of
            expert UI/UX designers who have in-depth knowledge and experience in creating fully responsive, eye-catchy,
            and SEO-friendly designs of your websites and mobile applications that provide an exceptional user
            experience for your customers. If you are looking for the best UI/UX design company in the UK, then
            Cybertron Softech is the right platform for you to help with it. Hire our expert UI/UX designers to craft
            something exceptional at affordable prices.
          </>
        }
        isVectorHidden={true}
        serviceImg={assetImages.uiuxsec2}
        maxHeight="20rem"
      />
      <Newsletter />
    </Layout>
  );
};

export default UIUXDesign;
