import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';
const YoutubeAd = () => {
  const servicesWeProvide = [
    {
      id: 3,
      title: 'The Reach of Youtube',
      des: (
        <>
          Remember back in 2005 when the first ever YouTube video was uploaded? A lot has changed since then, and in the
          last 14 years YouTube has seen unprecedented growth. The biggest thought leaders in the tech industries are
          all singing the same song and it goes something like this “YouTube is the future of marketing”. It’s already
          dominating the internet with over 30 million worldwide visitors every day and 50% of Australians using YouTube
          on the reg – that’s a helluva lot of traffic. In fact more video content has been uploaded in the last 30 days
          than all the major US Television broad casters have released in the last 30 years, and people are watching
          hours of it every day, 500 million hours of it every day to be exact. Think about that massive potential for
          your business – it’s huge. The wide reach of YouTube offers the perfect opportunity for your business to
          connect with your target audience in an engaging way.
          <br />
          <br />
          Not only this, its widely known that on an intellectual level, and this is important for increasing your brand
          awareness so listen up, customers will remember 95% of your video message in contrast to the measly 10%
          retention rate of reading it in text. If you were watching us say this in video format right now, chances are
          you could still tell us in a weeks time the purpose of our video. On top of that, this form of easy marketing
          is surprisingly cheap and available to businesses of all sizes.
        </>
      ),
      image: assetImages.videoAdFet1,
      maxHeight: '25rem',
    },
    {
      id: 4,
      title: 'Specific Targeting For Your Ads',
      des: (
        <>
          If you’re concerned that your YouTube ad will be popping up before every random cat video, you don’t need to
          worry – although cat videos are funny, we know your target audience probably isn’t hanging out there. There
          are a heap of specific targeting options which can help you narrow down your audience and Cybertron Softech is
          king at choosing the right targeting to get maximum return on your YouTube ads.
          <br />
          <br />
          YouTube allows you to target by demographic which means you can focus on the age, gender, parental status, or
          household income of the audience you want to reach. This means we can quite literally hone in on the audience
          that historically have been your biggest revenue earners – How Bloody Awesome. Your business can also target
          your ads depending on what topics a user might be interested in. If you reach people who have interests which
          are relevant to your business, you’re more likely to grab their attention! YouTube also offers you the
          opportunity to target your ads based on what videos people are actually watching and its relevance to your ad.
        </>
      ),
      image: assetImages.videoAdFet2,
      maxHeight: '30rem',
    },
    {
      id: 5,
      title: 'Different Type of Youtube Ads',
      des: (
        <>
          Just as YouTube offers different options for targeting your ads, there are also a few different ways you can
          choose to reach your audience. Cybertron Softech can offer Youtube Advertising services to guide you to the
          best option for your specific marketing requirements.
        </>
      ),
      maxHeight: '25rem',
      image: assetImages.videoAdFet3,
      listItems: [
        <>
          <span className="text-orage">INSTREAM</span> – Instream ads will pop up before a YouTube video begins and it
          gives you five seconds to grab a user’s attention before they’re given the option of skipping the ad. Remember
          that with a clever ad, sometimes five seconds is all you need!
        </>,
        <>
          <span className="text-orage">IN-VIDEO</span> – In-video ads will appear while YouTube content is playing and
          shows at the top of the recommended video list. It can catch a user’s attention while at the same time not
          interfering with their video.
        </>,
        <>
          <span className="text-orage">BUMPER</span> – Bumper ads can really pack a punch when it comes to making an
          impression and increasing reach. They are non-skippable ads which are only allowed to run for a maximum of six
          seconds.
        </>,
        <>
          <span className="text-orage">MASTHEAD</span> – Masthead ads give you the highest reach and awareness by
          showcasing your brand on the YouTube homepage for a full 24hrs. This means everyone who visits YouTube that
          day will be exposed to your brand.
        </>,
      ],
    },
    {
      id: 6,
      title: "Cybertron Softech's Youtube Advertising Service",
      des: (
        <>
          Video advertising is a form of marketing which is still often overlooked by businesses thinking this strategy
          “need not apply”. But they do, all businesses would benefit no matter their industry for promoting their
          service on YouTube because it can really deliver results when it’s cleverly implemented.
          <br />
          <br />
          When you let Cybertron Softech Digital help out with a touch of marketing awesomeness, you’ll wish you’d
          thought of giving YouTube ads a try a long time ago.
          <br />
          <br />
          So if you’re looking for a Youtube advertising agency, Cybertron Softech Digital provide killer video
          marketing services for your business and can help you develop creative and effective YouTube ads to target
          your audience. We are one of the leading digital marketing agencies for SEO services in Ontario, and with
          these skills sets, we always ensure we build a YouTube Ad that will not only resonate with the right audience,
          but are answering questions that people are looking for. To speak with one of our YouTube ads consultants,
          give Cybertron Softech a call today.
        </>
      ),
      maxHeight: '25rem',
      image: assetImages.videoAdFet4,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Video that connects and Converts"
        description={
          <>
            <span className="text-orage">
              "90% OF CUSTOMERS ARE MORE LIKELY TO PURCHASE AFTER THEY WATCH YOUR VIDEO"
            </span>
            <br />
            Let’s face it people are lazier than ever when it comes to digesting information online. Watching video
            makes it easy, and that’s why online video consumption is up year on year. Almost 5 billion videos are
            watched on YouTube every single day. If you want to get in front of people online, that’s where they are and
            that’s where we can put you.
          </>
        }
        serviceImg={assetImages.videoAdBanner}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default YoutubeAd;
