import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import CenteredTitle from '../../components/UIComponents/CenteredTitle';
import { assetImages } from '../../constant/images';

const WordpressDev = () => {
  const features = [
    {
      id: 1,
      title: 'INCREDIBLE CMS PROPERTIES',
      des: 'CMS features makes it highly convenient for the end users to manage or edit the website.',
      image: assetImages.wordpress1,
    },
    {
      id: 2,
      title: 'INTEGRATION',
      des: 'Provides seamless third-party and payment gateway integration.',
      image: assetImages.wordpress2,
    },
    {
      id: 3,
      title: 'SEO FRIENDLY',
      des: 'Websites built using WP have simple and constant codes making them ideal for Google indexing.',
      image: assetImages.wordpress3,
    },
    {
      id: 4,
      title: 'COST EFFECTIVE',
      des: 'Website designing and development is extremely cost effective and offers maintenance expenses.',
      image: assetImages.wordpress4,
    },
    {
      id: 6,
      title: 'RESPONSIVE WEB DESIGN',
      des: 'With WordPress website design, one enjoys the benefit of responsive web technology.',
      image: assetImages.wordpress5,
    },
    {
      id: 7,
      title: 'CONTENT MARKETING',
      des: 'Within no time you are able to update the content of your site.',
      image: assetImages.wordpress6,
    },
    {
      id: 5,
      title: 'INCREASED SITE SECURITY',
      des: 'Comes up with high security and in protection against CSRF and XSS attacks.',
      image: assetImages.wordpress7,
    },
    {
      id: 8,
      title: 'CUSTOMIZABLE DESIGN',
      des: 'The look and feel of the site can be 100% customized.',
      image: assetImages.wordpress8,
    },
    {
      id: 9,
      title: 'ADD PLUGINS',
      des: 'WordPress offers a variety of plugins with free or reasonable rates.',
      image: assetImages.wordpress9,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Wordpress Development Services"
        description="WordPress web development services help you get faster desired results and to maintain the quality of your website with tailor made solutions such as interactive and informational widgets, database driven extensions, WordPress ecommerce plug-in, API integration and extended installations, WordPress SEO plug-in, etc. At Cybertron Softech, we are having a team of expert WordPress developers who have developed lots of websites using WordPress for our clients across the globe. We offer you bespoke WordPress web development and provide the talented team of WordPress developers to work on your projects."
        serviceImg={assetImages.wordpressbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="What Is Wordpress Development?"
        description="WordPress is an open source platform that uses PHP Coding for blogging. It is one of the most common CMS used around the world and majorly used for blogs, social network, and e-commerce functionalities. It is incredibly popular because of the features and functionality it offers. The incredible CMS properties of the WP makes it highly convenient for the end users to manage or edit the website from the back-end on their own. WordPress provides the world's most popular blogging platform which is affordable, flexible, easy to use, and easily crawled by search engines."
        serviceImg={assetImages.wordpresssec}
        maxHeight="25rem"
      />
      <CenteredTitle
        title="Why Choose Us"
        des="Our experts with incredible knowledge and years of skill guarantee that your business achieves an unrivaled position and sets another level of accomplishment. Cybertron Softech is the place where you can find the best WordPress Website development services, integration and customization tailored as per your requirements."
      />
      <ServiceFeatures features={features} title="Key Benefits Of Wordpress Development" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default WordpressDev;
