import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Gsuites = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: <>MULTIPLE USERS</>,
      des: (
        <>
          Turn your users into heroes! Give them all the powers that GSuite provides, make some admins, give some more
          storage. You have complete control.
          <br />
          Sick of Outlook? Access your emails through Gmail’s world renowned interface. Not sick of Outlook? Send your
          GSuite emails straight to it!
        </>
      ),
      image: assetImages.gsuite1,
    },
    {
      id: 2,
      title: <>MANAGED EMAIL</>,
      des: (
        <>
          You can assign each of your users as much storage as they need, and decide which files are shared with whom.
          Keep your data safe and in your control.
          <br />
          Cybertron Softech Digital can get you started right away. Just get in touch with us and let us know you’re
          interested. We’ll make it happen for you with our GSuite experts.
        </>
      ),
      image: assetImages.gsuite2,
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="GSuite Services (Google Apps) "
        description={
          <>
            GSuite gets your business going with separate users, all with managed email, cloud storage, document
            collaboration and even more.
            <br />
            <br />
            We can do all the heavy lifting to get you setup with GSuite. We can even migrate from your old email
            accounts, so you never lose any of your important correspondence.
            <br />
            <br />
            The best part? Your emails will have your domain name at the end, ie. manager@yourbusinessname.com.au (just
            like ours!)
          </>
        }
        serviceImg={assetImages.gsuitebanner}
        maxHeight="20rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Gsuites;
