import React from 'react';
import CenteredTitle from '../UIComponents/CenteredTitle';

const ServiceCardsHorizon = ({ title, features }) => {
  return (
    <section className="service-horizontal-features">
      <CenteredTitle title={title} />
      <div className="container-lg">
        {features.map((itm, i) => (
          <div className={`row mb-4 ${i % 2 === 0 ? 'is-reverse' : ''} align-items-center`} key={itm.id}>
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="feature-itm-wrapper">
                <div className="orange-line"></div>
                {itm.image && (
                  <div className="image-wrapper">
                    <img src={itm.image} alt={`fet-${itm.id}`} />
                  </div>
                )}
                <div>
                  <h4 className={`fet-title`}>{itm.title}</h4>
                  <p className={`des-text`}>{itm.des}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceCardsHorizon;
