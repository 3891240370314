import React from 'react';
import Layout from '../components/layout/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="container-lg policy-sec">
        <div className="policy-banner">
          <p className="des-text text-center m-0">Current as of 1 June 2023</p>
          <div className="title-wrapper justify-content-center">
            <h2>Privacy Policy</h2>
          </div>
        </div>
        <p className="des-text">
          Cybertron Softech Inc is committed to protecting the privacy and security of your personal information. This
          Privacy Policy outlines how we collect, use, disclose, and protect the information we collect from our website
          visitors and customers.
        </p>
        <h4>1. Information We Collect</h4>
        <div className="policy-des">
          <p className="des-text">We may collect the following types of information:</p>
          <p className="des-text">
            <b>1.1</b> Personal Information: When you visit our website, request information, or use our services, we
            may collect personal information such as your name, email address, phone number, and other relevant contact
            details.
          </p>
          <p className="des-text">
            <b>1.2</b> Non-Personal Information: We may also collect non-personal information, such as your IP address,
            browser type, operating system, and website usage data, which is collected through cookies and similar
            technologies. This information helps us improve our website and services.
          </p>
        </div>
        <h4>2. How We Use Your Information</h4>
        <div className="policy-des">
          <p className="des-text">We use the collected information for the following purposes:</p>
          <p className="des-text">
            <b>2.1</b> Providing Services: We may use your personal information to fulfill your requests, provide you
            with information about our products and services, and process transactions.
          </p>
          <p className="des-text">
            <b>2.2</b> Website Improvement: Non-personal information helps us analyze website usage trends, diagnose
            technical issues, and improve the overall user experience.
          </p>
          <p className="des-text">
            <b>2.3</b> Communication: We may use your contact information to communicate with you, respond to your
            inquiries, provide customer support, and send you important updates or promotional materials related to our
            services.
          </p>
          <p className="des-text">
            <b>2.4</b> Legal Compliance: We may use or disclose your information as required by applicable laws,
            regulations, or legal processes.
          </p>
        </div>
        <h4>3. Information Sharing and Disclosure</h4>
        <div className="policy-des">
          <p className="des-text">
            We do not sell or rent your personal information to third parties. However, we may share your information
            with trusted third-party service providers who assist us in operating our website, conducting our business,
            or servicing you. These third parties have agreed to keep your information confidential and use it solely
            for the purposes of assisting us.
          </p>
          <p className="des-text">
            We may also share your information when required by law, or if we believe it is necessary to protect our
            rights, property, or safety, or the rights, property, or safety of others.
          </p>
        </div>
        <h4>4. Data Security</h4>
        <div className="policy-des">
          <p className="des-text">
            We take appropriate technical and organizational measures to safeguard the information we collect. We use
            industry-standard security measures to protect against unauthorized access, alteration, disclosure, or
            destruction of your personal information. However, no method of transmission over the internet or electronic
            storage is 100% secure, and we cannot guarantee its absolute security.
          </p>
        </div>
        <h4>5. Third-Party Links</h4>
        <div className="policy-des">
          <p className="des-text">
            Our website may contain links to third-party websites or services. We are not responsible for the privacy
            practices or the content of such websites. We encourage you to review the privacy policies of those third
            parties before providing any personal information.
          </p>
        </div>
        <h4>6. Children's Privacy</h4>
        <div className="policy-des">
          <p className="des-text">
            Our website and services are not directed towards individuals under the age of 13. We do not knowingly
            collect personal information from children. If you believe that we have inadvertently collected information
            from a child, please contact us, and we will take steps to remove that information.
          </p>
        </div>
        <h4>7. Changes to this Privacy Policy</h4>
        <div className="policy-des">
          <p className="des-text">
            We reserve the right to update or modify this Privacy Policy at any time. When we make changes, we will
            revise the "Effective Date" at the top of this page. We encourage you to review this Privacy Policy
            periodically to stay informed about how we collect, use, and protect your information.
          </p>
        </div>
        <h4>8. Contact Us</h4>
        <div className="policy-des">
          <p className="des-text">
            If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact
            us at <a href="mailto:hello@cybertronsoftech.ca">hello@cybertronsoftech.ca</a>
          </p>
          <p className="des-text">By using our website or services, you consent to the terms of this Privacy Policy.</p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
