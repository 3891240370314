import React from 'react';
import Slider from 'react-slick';
import { assetImages } from '../../constant/images';

const OurCertificate = () => {
  const platforms = [
    {
      id: 1,
      image: assetImages.certi1,
    },
    {
      id: 2,
      image: assetImages.certi2,
    },
    {
      id: 3,
      image: assetImages.certi3,
    },
    {
      id: 4,
      image: assetImages.certi4,
    },
    {
      id: 5,
      image: assetImages.certi5,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 500,
        settings: { slidesToShow: 1, fade: true },
      },
    ],
  };

  return (
    <section className="certificate-sec">
      <div className="container-lg">
        <div className="title-wrapper mb-3">
          <div className="orange-line"></div>
          <h2>Our Certificate</h2>
        </div>
        <Slider {...settings}>
          {platforms.map((item) => (
            <div key={item.id}>
              <div className="d-flex justify-content-center">
                <div className="certificate-card">
                  <img src={item.image} alt={`certificate-${item.id}`} className="img-fluid maxWidths" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default OurCertificate;
