import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const StartUpAppDev = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Own The Feature-Rich Interface',
      des: (
        <>
          Our mobile app development for startups adds mileage to your geared up business' purpose. Whether a low-budget
          idea or a billion-dollar idea, we are enthusiastic about transforming your shapeless idea into a magnetic
          indulgence that manages the trade operations orderly and gives out the maximum advantage to every user. We
          have an expert business associate who will understand your business idea, explain the app features to you and
          do tests based on the market viability and feasibility of your under-developed business app.
          <br />
          <br />
          We do prototype app development, wireframe designing, SRS documentation and UX Design and Development.
          Wireframe designing, optimizes information hierarchy of your design and allows you to visualize the layout of
          your digital product and how your user will process the information on the app.
        </>
      ),
      image: assetImages.startupapp1,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Is my app idea safe with you?',
      des: 'Absolutely. We have a strict policy against any kind of data breaches and we would be happy to sign an NDA with you before you discuss your idea for a business app with our associates',
    },
    {
      id: 2,
      title: '2. How much does an app cost?',
      des: 'It varies and depends on several factors – functionality, the number of features chosen, design elements, to cite just a few. So study the market before you select your vendor, bearing in mind of course that cheap is not always the best.',
    },
    {
      id: 3,
      title: '3. Why should I choose Cybertron Softech?',
      des: 'Because we listen more than we talk. We’ll not do any aggressive selling of an off-the-shelf branded app, we’ll customize it for you, based on your specific required. We are experienced in building the mobile app for startup. We deliver on time, and knowing how cost-sensitive a small entrepreneur is, we will deliver within the estimated budget. Finally, we will help you market that app and recover its cost – thus providing you with an end-to-end service on app development and deployment.',
    },
    {
      id: 4,
      title: '4. Do you outsource your development Work?',
      des: 'Nope. App development is our core competency. We’ll not dilute the quality and functionality of your app by outsourcing the work to a third party vendor.',
    },
    {
      id: 5,
      title: '5. If I contact you, what are the next steps?',
      des: 'We’ll take a complete download from you, understand your needs, requirements and expectations from the app. Where do you want to use it? Do you want it to be a web or a mobile app? Whether you want it to be a paid or a free app? What features do you want to incorporate etc. Once we have completely understood your requirements we will undertake feasibility studies, propose, brainstorm and tweak design features and app functionality with you, prototype (for a small fee), and then design and deploy it.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Brilliant Apps For Budding Ideas"
        description={
          <>
            Building a mobile app is a great thing for startups. The startup idea originator can actually test how their
            idea works by building a startup mobile app. The mobile solutions for startups and individual entrepreneurs
            from our service spectrum are based on customization to suit your startup business needs. We are a startup
            app development company that converts your business idea into a winning app. We discard all the unnecessary
            interruptions that make your new business look clutter and incorporate things, which can make your business
            look smart in delivering desired services. We can engage your targeted customers by giving them the value of
            what your business idea was built on while generating more engagements.
          </>
        }
        serviceImg={assetImages.startupapp}
        maxHeight="25rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default StartUpAppDev;
