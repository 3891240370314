import React from 'react';
import Newsletter from '../components/commonComponents/Newsletter';
import Layout from '../components/layout/Layout';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import { assetImages } from '../constant/images';

const Industry = () => {
  const industry = [
    {
      id: 1,
      title: 'Travel & Hospitality',
      image: assetImages.industry1,
      des: (
        <>
          Whether it is about booking a flight ticket or reserving a room in a hotel, people prefer to use websites and
          mobile apps that provide a fast, convenient, and easy way to get their job done in no time. The Travel &
          Hospitality sector is significantly moving towards new engagement models to expand their businesses through an
          online portal.
          <br />
          <br />
          With the growing trend of digitalization, the travel & hospitality industry is leaning towards digital
          solutions that allow people to book a flight ticket or a room in a hotel from anywhere and anytime. If you
          have a business in the travel & hospitality industry, you should have both websites and mobile apps for your
          business to succeed in the right direction.
        </>
      ),
    },
    {
      id: 2,
      title: 'Real Estate & Housing',
      image: assetImages.industry2,
      des: (
        <>
          The real estate sector is the most globally recognized sector, which is a mixture of four sub-sectors,
          including housing, retail, hospitality, and commercial. The way people use to handle properties has changed
          now and has become completely digitized.
          <br />
          <br />
          People now are likely to search for property dealers or brokers online rather than approaching them in person.
          A lot has changed and many industries are shifting towards a digital solution that is convenient and handy and
          provides a fast way to do everything right from your smartphone. Whether you are a property dealer or running
          a business in the real estate sector, Cybertron Softech provides all-in-one solutions that help you manage
          your business easily and effectively.
        </>
      ),
    },
    {
      id: 3,
      title: 'Medical & Healthcare',
      image: assetImages.industry3,
      des: (
        <>
          Medical and Healthcare is a leading and fastest-growing industry and swiftly moving towards digital solutions
          by adopting the latest way to provide medical healthcare services to help and treat patients even in
          emergencies, like coronavirus pandemic. From general medical clinics to leading pharmaceutical companies,
          everything has become digitized and they have gained lots of benefits from emerging technologies.
          <br />
          <br />
          Cybertron Softech is well-recognized for providing customized software solutions for the medical and
          healthcare industry to help them provide online medical healthcare services to their patients worldwide. We
          have many years of experience and expertise in building high-performance, reliable, and fully managed software
          solutions that let you connect with highly qualified doctors on board to provide virtual medical care services
          effectively.
        </>
      ),
    },
    {
      id: 4,
      title: 'Education & Learning',
      image: assetImages.industry4,
      des: (
        <>
          The education industry holds an important place in the global market. With the rapid spread of coronavirus and
          the lockdown, the way of education is completely moved to an online platform. Many education & learning
          industries are seeking out the best and convenient way to provide online educational services to students
          across the globe.
          <br />
          <br />
          When it comes to the education & learning industry, we provide fully managed and customized software solutions
          that provide fast and convenient ways to learn online for students and deliver the best answers to their
          academic-related questions quickly and effectively.
        </>
      ),
    },
    {
      id: 5,
      title: 'Fitness & Wellness',
      image: assetImages.industry5,
      des: (
        <>
          Fitness and wellness cover all physical activities, like yoga, nutrition, and weight loss, healthy eating,
          personal care and beauty, spa retreats, meditation, workplace wellness, much more. Don’t you think IoT devices
          and smartwatches have brought significant changes in the fitness & wellness industry?
          <br />
          <br />
          No doubt! Many fitness & wellness industries have gained tremendous benefits by developing fitness apps and
          smartwatches for their business. Cybertron Softech offers customized smartwatches and fitness app development
          solutions that help you continually expand your business to a new horizon.
        </>
      ),
    },
    {
      id: 6,
      title: 'Media & Entertainment',
      image: assetImages.industry6,
      des: (
        <>
          The Media & Entertainment industry is dramatic and vast. It includes many business activities ranging from TV
          shows, music, radio shows, movies, news, newspapers, magazines, and books. Mobile technology has widely
          impacted the media and entertainment industry, compelling companies to create new business models and
          innovative approaches to capitalize on the growth.
          <br />
          <br />
          Cybertron Softech provides end-to-end software app development services that enable media and entertainment
          companies to keep pace with the ever-changing needs of customers in the modern world. We build super
          performance software solutions that help you reduce operational costs, improve customer experience, and
          provide on-time delivery of your products/services to gain competitive advantages in the digital age.
        </>
      ),
    },
    {
      id: 7,
      title: 'Energy & Utility',
      image: assetImages.industry7,
      des: (
        <>
          Energy & utility industry incorporates water, oil & gas, and electricity services that promote your daily
          activities. Today, everything is moving towards digital solutions that provide modern ways to deliver their
          products or services to the end-customers. We have developed futuristic apps and websites for many clients
          from the energy & utility industry to help them manage all business processes in the form of streamline
          operations, track inventory, monitor infrastructure, and engage customers in better ways.
        </>
      ),
    },
    {
      id: 8,
      title: 'Sports & Gaming',
      image: assetImages.industry8,
      des: (
        <>
          The Sports & gaming industry is a vast industry that plays a vital role in everyone’s life. With the growing
          use of mobile technology and video gaming, the sports and gaming industry is completely moved to the digital
          platform, allowing people to play games and bet online on various e-sports, including cricket, golf, football,
          horse riding, and so on with a seamless gaming experience.
          <br />
          <br />
          In today’s world, online games have become more popular among game lovers. From kids to adults, everyone loves
          to play online games whenever they find themselves free. Cybertron Softech provides fully- customized software
          app development solutions that help you stay relevant in the competitive edge.
        </>
      ),
    },
    {
      id: 9,
      title: 'Banking & Finance',
      image: assetImages.industry9,
      des: (
        <>
          The banking and financial services industry has transformed into a digital solution as everything is dependent
          on the internet and mobile phones. Whether it is about making payments or bringing new innovative banking and
          financial products and services in the market, everything is moved to digital solutions. A lot has changed in
          every segment of banking, including consumer, retailer, mortgage, commercial, wealth and investment, and much
          more.
          <br />
          <br />
          People now are likely to search for property dealers or brokers online rather than approaching them in person.
          A lot has changed and many industries are shifting towards a digital solution that is convenient and handy and
          provides a fast way to do everything right from your smartphone. Whether you are a property dealer or running
          a business in the real estate sector, Cybertron Softech provides all-in-one solutions that help you manage
          your business easily and effectively.
        </>
      ),
    },
    {
      id: 10,
      title: 'Social Networking',
      image: assetImages.industry10,
      des: (
        <>
          Social networking incorporates the use of social media sites to connect with friends, family, colleagues,
          customers, and clients. Social networking is one of the largest online platforms share content about the
          latest news and updates and stay connected with a wide range of audiences to boost conversions. Mobile
          technologies have brought major changes to the social networking industry as almost everything is moved to
          digital. Social networking apps are all that your business needs to stay ahead of your competitors.
          <br />
          <br />
          Cybertron Softech provides the best-in-class social networking app development services ranging from social
          media apps, dating apps, clone apps, and social community apps to cater to the specific needs of your
          business.
        </>
      ),
    },
    {
      id: 11,
      title: 'Logistics & Distribution',
      image: assetImages.industry11,
      des: (
        <>
          The logistics and Distribution is an inseparable part of online retail & eCommerce which involves in-depth
          distribution. The industry is expanding globally to meet the ever-changing needs of its customers through an
          online platform. Many logistics & distribution industries are adopting modern ways of managing warehousing,
          transportation, inventory, material handling, packing, distribution, and security.
          <br />
          <br />
          When it comes to the logistics and distribution industry, Cybertron Softech has many years of experience in
          providing fully customized software solutions that address all your special business requirements easily and
          effectively.
        </>
      ),
    },
    {
      id: 12,
      title: 'Retail & Ecommerce',
      image: assetImages.industry12,
      des: (
        <>
          The retail & eCommerce industry is continually evolving the modern ways of online shopping with the growing
          use of mobile technologies in the competitive world. The way people use to shop has completely changed, now
          they prefer online shopping whether it is about buying clothes or home appliances. With the growing trend of
          online shopping, many retail & eCommerce industries have gained lots of benefits by getting websites and
          mobile apps for their businesses.
          <br />
          <br />
          We offer the best mobile and web development solutions that let you sell your products or services online
          according to your specific business needs.
        </>
      ),
    },
  ];
  return (
    <Layout>
      <section className="industry-page-sec">
        <div className="container-lg">
          {industry?.map((itm, i) => (
            <div
              key={itm.id}
              className={`row industry-sec-wrapper ${i % 2 === 0 ? '' : 'is-reverse'} align-items-center`}
            >
              <div className="col-lg-6">
                <OneSideTitle title={itm.title} des={itm.des} />
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <img src={assetImages.serviceBanner} alt="serviceBanner" className="bg-vector" />
                <img src={itm.image} alt="whatwedo" className="img-fluid sec-image" />
              </div>
            </div>
          ))}
        </div>
      </section>
      <Newsletter />
    </Layout>
  );
};

export default Industry;
