import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import CenteredTitle from '../../components/UIComponents/CenteredTitle';
import { assetImages } from '../../constant/images';

const MagentoWeb = () => {
  const features = [
    {
      id: 1,
      title: 'MULTI STORE FACILITIES',
      des: 'Runs multiple store-fronts for numerous brands from the single back-end.',
      image: assetImages.magento1,
    },
    {
      id: 2,
      title: 'SECURED ADMIN PANEL',
      des: 'Follows universally accepted security standards and many vital protection criteria.',
      image: assetImages.magento2,
    },
    {
      id: 3,
      title: 'VARIED PRODUCT CATALOG',
      des: 'Magento Facilitates various product catalogs.',
      image: assetImages.magento3,
    },
    {
      id: 4,
      title: 'THIRD PARTY INTEGRATION',
      des: 'Magento gives an online store bigger space for custom integrations.',
      image: assetImages.magento4,
    },
    {
      id: 5,
      title: 'SEO-FRIENDLY',
      des: 'Improved SEO functionalities & implementation to attract traffic.',
      image: assetImages.magento5,
    },
    {
      id: 6,
      title: 'MULTIPLE PAYMENT GATEWAYS',
      des: 'Provides multiple payment gateways to make an apt choice.',
      image: assetImages.magento6,
    },
    {
      id: 7,
      title: 'EFFICIENT VENDOR MANAGEMENT',
      des: 'Facilitates easy management of vendor and vendor accounts.',

      image: assetImages.magento7,
    },
    {
      id: 8,
      title: 'API INTEGRATION',
      des: 'API framework provides means to use web services with the Magento system.',
      image: assetImages.magento8,
    },
    {
      id: 9,
      title: 'EFFECTIVE SITE PERFORMANCE',
      des: 'Magento is known for its power to produce fast loading web pages.',
      image: assetImages.magento9,
    },
    {
      id: 10,
      title: 'MULTI-TIER PRICING SETUPE',
      des: 'Enables you to create multiple tiers and offers discounts across multiple products.',
      image: assetImages.magento10,
    },
    {
      id: 11,
      title: 'FLEXIBLE ENGAGEMENT MODELS',
      des: 'Offers significant cost savings and efficiency to clients.',
      image: assetImages.magento11,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Magento Web Development Company"
        description={
          <>
            Being a feature-rich, professional eCommerce platform, Magento website development is preferred by many.
            Cybertron Softech delivers customized solutions and third party plug-ins, for providing its clients with
            greater flexibility and scalability. <br />
            <br />
            Cybertron Softech, being a Magento development company, has a rich history working with Magento and is also
            a center of excellence for Magento Development Services. It’s dedicated and experienced team of Magento
            Certified Developers are well-versed with diverse domains and a plethora of applications, and well- known to
            get to the nitty-gritty of each of the developed sites. What’s more, our comprehensive Magento development
            services are offered to clients on a flexible engagement model, and aligned with international standards in
            terms of quality and performance.
          </>
        }
        serviceImg={assetImages.magentobanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="What Is Magento?"
        description="Magento is a professional open-source eCommerce platform written in PHP programming language that gives you complete control over the intuitive interface, SEO rich functionalities, content management tools, flexibility to the online store. Magento offers companies the ultimate eCommerce solution to enable merchants to create sites that are custom fitted to their one of a kind business needs."
        serviceImg={assetImages.magentosec}
        maxHeight="25rem"
      />
      <CenteredTitle
        title="Why Choose Us"
        des="Cybertron Softech is a leading eCommerce web development company having a certified team of skilled Magento Developers. Our programmers have gained expertise in developing various eCommerce websites. They are well aware of popular platforms trending in the market and come up with the best possible solution."
      />
      <ServiceFeatures features={features} title="Key Benefits Of Magento Development" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};
export default MagentoWeb;
