import React from 'react';
import { Link } from 'react-router-dom';
import { assetImages } from '../../constant/images';
import CenteredTitle from '../UIComponents/CenteredTitle';
import { scrollToTop } from '../../common/ScrollToTop';

const LatestProjects = () => {
  const projects = [
    {
      id: 1,
      image: assetImages.latestpro1,
      title: 'Instant Juice Mix',
      link: 'https://www.flistaa.com/',
    },
    {
      id: 2,
      image: assetImages.latestpro2,
      title: 'Food Ingrdiants',
      link: 'https://prayoshafoodingredients.com/',
    },
    {
      id: 3,
      image: assetImages.latestpro3,
      title: 'Education',
      link: 'https://www.careermakerseducation.in/',
    },
  ];
  return (
    <section className="industry-expertise">
      <CenteredTitle
        title="Our Latest Projects"
        des="Have a glance at the most recent work and remarkable projects that we have developed for our truly
          inspiring clients ranging from various industries."
      />
      <div className="container-lg">
        <div className="row justify-content-center g-4">
          {projects.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-sm-12">
              <div className="project-card">
                <img src={item.image} alt={item.title} className="img-fluid" />
                <div className="project-title-wrapper">
                  <h4 className="item-title">{item.title}</h4>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={assetImages.arrowround} alt="arrowround" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center pt-3">
          <Link to="/portfolio" onClick={scrollToTop}>
            <button className="blue-bg-button">View All</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LatestProjects;
