import React from 'react';
import { Shape1, Shape2, Shape3 } from '../../constant/Icon';
import { assetImages } from '../../constant/images';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';

const HomeBanner = () => {
  return (
    <section className="home-banner">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-6">
            <h1>Empowering Growth with Seamless Technical Solutions</h1>
            <p className="des-text">
              We build scalable products that streamline your business process, maximize efficiency, and improve your
              business productivity.
            </p>
            <Link to="/contact-us" onClick={scrollToTop}>
              <button className="blue-bg-button">Get A Quote</button>
            </Link>
          </div>
          <div className="col-lg-6">
            <div className="banner-right-col">
              <img src={assetImages.homebanner} className="img-fluid" alt="homebanner"></img>
              <Shape1 className="shape-1 zooming" />
              <Shape2 className="shape-2 rotate-center" />
              <Shape3 className="shape-3 rotate-center" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
