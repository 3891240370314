import React from 'react';
import Slider from 'react-slick';
import { assetImages } from '../../constant/images';

const PlatformCarousal = () => {
  const platforms = [
    {
      id: 1,
      image: assetImages.adobeLogo,
    },
    {
      id: 2,
      image: assetImages.yahoologo,
    },
    {
      id: 3,
      image: assetImages.ibmlogo,
    },
    {
      id: 4,
      image: assetImages.applePay,
    },
    {
      id: 5,
      image: assetImages.googlelogo,
    },
    {
      id: 6,
      image: assetImages.microsoftlogo,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 500,
        settings: { slidesToShow: 2 },
      },
    ],
  };

  return (
    <section className="platform-sec">
      <div className="container-lg">
        <Slider {...settings}>
          {platforms.map((item) => (
            <div key={item.id}>
              <div className="platform-card">
                <img src={item.image} alt={`platform-${item.id}`} className="img-fluid maxWidths" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default PlatformCarousal;
