import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import SerKeyBenefits from '../../components/services/SerKeyBenefits';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const AwsDeployment = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Why AWS Deployment',
      des: (
        <>
          This services automates to Amazon EC2 instances. AWS can deploy any number of application content like code,
          web and configuration files, executables, packages, scripts, multimedia files, and so on. It is capable enough
          to handle complexities involved and quickly release your features and functionalities so that you can easily
          deploy to one instance or thousands.{' '}
        </>
      ),
      image: assetImages.awssec,
    },
  ];
  const benefits = [
    {
      id: 1,
      title: <>AUTOMATED DEPLOYMENTS</>,
      des: <>AWS scales with your infrastructure to deploy from one instance to another over the network.</>,
    },
    {
      id: 2,
      title: <>REDUCE DOWNTIME</>,
      des: (
        <>
          AWS CodeDeploy maximizes your application availability. It performs a rolling update across Amazon EC2
          instances.
        </>
      ),
    },
    {
      id: 3,
      title: <>CONTROL OVER EVERYTHING CENTRALLY</>,
      des: <>Tracks the status of deployments of applications through AWS codeDeploy console or the AWS CLI.</>,
    },
    {
      id: 4,
      title: <>EASY ADOPTION</>,
      des: <>AWS CodeDeploy is a platform and works with any application. Code reuse is done easily.</>,
    },
    {
      id: 5,
      title: <>INTEGRATION </>,
      des: <>AWS CodeDeploy can integrate your software process to continuous delivery tool chain.</>,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="AWS Deployment Services"
        description={
          <>
            <span className="text-orage">WHY AWS DEPLOYMENT</span>
            <br />
            AWS is an automated code deployment service that deploys your code and makes it easier to connect with new
            features, also helps you to avoid downtime while application deployment. We build highly scalable systems on
            Amazon web services. AWS deployment tools can handle system and application level deployment giving ensuring
            consistency, predictability and integrity across multiple environments. AWS Code Deploy can deploy
            application content stored in Amazon S3 buckets, Github repositories and Bitbucket repositories.{' '}
          </>
        }
        serviceImg={assetImages.awsBanner}
        maxHeight="18rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg
        servicesWeProvide={servicesWeProvide}
        whyChoose={true}
        des="Cybertron Softech has Experts of technologies, who not only help reduce the migration costs, but also maintain the efficiency of the application well. 
Seamless migration remains the motto of the professional experts that helps out in maintaining the fidelity of the business logic."
      />
      <SerKeyBenefits title="Key Benefits of AWS Deployment Services" benefits={benefits} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};
export default AwsDeployment;
