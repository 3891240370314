import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';

const TechnologyExp = () => {
  return (
    <section>
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <OneSideTitle
              title={'Technology Expertise'}
              des="We have hands-on experience and expertise in various technologies to design and develop the most perfect
              solutions based on your custom business requirements. We make use of powerful frameworks, modern tools and
              the right technology platform that meet your business goal more effectively."
            />
            <div className="d-flex justify-content-lg-center">
              <Link to="/contact-us" onClick={scrollToTop}>
                <button className="blue-bg-button">Talk to an Expert</button>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={assetImages.tecexp} alt="whatwedo" className="img-fluid sec-image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologyExp;
