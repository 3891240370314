import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';
const EmailMarketing = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'What is Email Marketing',
      des: (
        <>
          Email Marketing is the perfect opportunity to remain at the forefront of your customers minds. It’s an
          opportunity to re-engage old customers, grow your community, and create stronger customer bonds. These days
          most people receive all their communication electronically (what is a letterbox?). Though we’re still fond of
          the carrier pigeon as an effective mode of delivery, they’re going out of fashion & quickly being replaced by
          a much faster mode of communication delivery that doesn’t get lost nearly as much, it’s called email. What
          that means is that traditional marketing approaches are no where near as cost and time effective, and people
          will probably shrug you off as junk mail – basically, unless there’s that unholy little police or government
          stamp on the front of your envelop, no one’s going to open you. Fortunately, email marketing offers an
          effective and cost efficient solution.
        </>
      ),
      image: assetImages.emailMarkFet1,
      maxHeight: '25rem',
    },
    {
      id: 2,
      title: 'The Challenge of Email Marketing',
      des: (
        <>
          Effective email marketing is not about bombarding potential customers with spammy messages. Fortunately,
          professional email marketing services can help you to tailor your campaigns and ensure that you offer value to
          your potential customers. An email marketing agency can help to identify your target market and assist you in
          generating effective content that will keep your customers interested in your products and services.
          <br />
          <br />
          The key to successfully email marketing businesses is about understanding your readers, and using engaging and
          valuable content to develop the relationship, your expertise and ultimately your brand.
        </>
      ),
      image: assetImages.emailMarkFet2,
    },
    {
      id: 3,
      title: "Why Cybertron Softech's Email Marketing Software",
      des: (
        <>
          Cybertron Softech’s Email Marketing software is suitable for all budgets, allowing you to either self manage
          it or handball the task over to us which we can manage for a small monthly fee. Our email marketing software
          not only helps you create amazing email campaign designs with it’s powerful visual editor, but it’s also
          incredibly user friendly, and packed with a tonne of awesome analytics features. So what are you waiting for?
        </>
      ),
      image: assetImages.emailMarkFet3,
    },
    {
      id: 4,
      title: <>What makes our Email Software Great?</>,
      des: <></>,
      listItems: [
        'Based on a successful business model, Cybertron Softech’s email software was designed by professionals in multiple industries.',
        'The system rectifies all pain points endured within the week-to-week tasks of operating and marketing a business.',
        'The product and team are incredibly innovative with new and emerging social trends always on the frontier of developer trends.',
        'Email has been around for years. Our email marketing packages give you a dynamic edge over your competitors and the easiest user interface on the market.',
      ],
      image: assetImages.emailMarkFet4,
    },
    {
      id: 5,
      title: 'Insightful Analytics',
      des: <></>,
      image: assetImages.emailMarkFet5,
      listItems: [
        'Who opens your campaigns, when and how many times. Drill right down to the subscriber level.',
        'See which subscribers forwarded your email to their friends or shared it on Facebook and Twitter.',
        'Which emails bounced back and couldn’t be delivered. We’ll automatically remove invalid addresses.',
        'Built-in integration makes it easy to see what site activity, sales, conversions and ROI your campaign generated.',
      ],
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Email Marketing Services"
        description={
          <>
            <span className="text-orage">
              TILISE EMAIL MARKETING SERVICES TO RETAIN, NURTURE & EDUCATE YOUR EXISTING AND POTENTIAL CLIENTS.
            </span>
            <br />
            Stay front of mind, build fans and create repeat business with the power of Email Marketing.
          </>
        }
        serviceImg={assetImages.emailMarkBanner}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default EmailMarketing;
