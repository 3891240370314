import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

import { assetImages } from '../../constant/images';
import CenteredTitle from '../UIComponents/CenteredTitle';

const ClientTestimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Jason Ferreira',
      des: 'I highly recommend this web development agency. Their team is skilled, professional, and efficient. Even with my relatively tight deadline, they delivered an exceptional website that perfectly met my needs. A top-notch experience from start to finish!',
      profile: assetImages.clientone,
    },
    {
      id: 2,
      name: 'Cindy Rosen',
      des: 'I had the pleasure of working with an exceptional mobile app development company, and I am incredibly impressed with their services. From concept to launch, they showcased expertise, professionalism, and a keen eye for detail. Highly recommended!',
      profile: assetImages.clientTwo,
    },
    {
      id: 3,
      name: 'Robert Madden',
      des: 'I am extremely satisfied with the SEO service provided to Cybertron Softech. Their expertise in search engine optimization has significantly improved our online visibility and organic rankings. The team was professional, responsive, and delivered impressive results. Highly recommended!',
      profile: assetImages.clientThree,
    },
    {
      id: 4,
      name: 'Burhan Kouri',
      des: "I wholeheartedly recommend Cybertron Softech to anyone in need of innovative and tailored software solutions. Their unmatched ingenuity, professionalism, and dedication will undoubtedly take your business to new heights. Trust them with your custom software needs, and you won't be disappointed.",
      profile: assetImages.clientfour,
    },
  ];
  return (
    <section className="client-testimonials">
      <CenteredTitle
        title="Client Testimonials"
        des="We have built a strong relationship, working partnership and true friendship while working with our amazing clients ranging from different industry verticals. Hear it straight from our valuable clients to know what they say about our services and the value we offer!"
      />
      <div className="container-lg">
        <div className="row">
          {testimonials.map((itm) => (
            <div key={itm.id} className="col-lg-6">
              <div className="testi-card">
                <div className="testi-img-wrapper" style={{ backgroundImage: `url(${itm.profile})` }}>
                  {/* <img src={itm.profile} alt={itm.name} className="img-fluid" /> */}
                </div>
                <p className="testi-name">{itm.name}</p>
                <div className="testi-detail-wrapper">
                  <FaQuoteLeft /> <span>{itm.des}</span> <FaQuoteRight />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonials;
