import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Domains = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: <>What We Do</>,
      image: assetImages.domain1,
      listItems: [
        <>
          <span className="text-orage">SELECTED FOR YOU</span> – You need a domain which reflects your business. Ask us
          about our domain registration services, and we’ll find you one which is guaranteed to bring you the right kind
          of customers.
        </>,
        <>
          <span className="text-orage">MANAGED BY US</span> – When you’re busy managing your business, you don’t want to
          be busy managing your domain. Put it in Cybertron Softech hands, and we’ll handle the nerdy stuff.
        </>,
        <>
          <span className="text-orage">EASY TRANSFERS</span> – Sometimes it’s hard to get control of your domain. We can
          help with that – we’ll bring it across to us so you have everything in one safe place. Cybertron Softech
          Digital.
        </>,
      ],
    },
    {
      id: 2,
      title: <>Your Domain is Everything</>,
      des: (
        <>
          Your domain is what gives people their very first impression of your business. For this reason, it needs to be
          highly targeted to your products and services, while also being formatted professionally and drawing your
          customers in with an enticing initial spark. Let Cybertron Softech domain name services find you a new domain,
          and we’ll make sure it’s perfect for your business. Put your current domain in Cybertron Softech hands, and
          we’ll ensure your records are updated right away, whenever you need.
        </>
      ),
      image: assetImages.domain2,
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="Domain Name Registration"
        description={
          <>
            <span className="text-orage">FIND YOUR ONLINE REAL ESTATE TODAY</span>
            <br />
            .com, .com.au, .net, .ca, .biz – whatever your extension, a good business needs a solid domain, and
            Cybertron Softech knows just how to help you with that. If you don’t have a domain, we can help you find
            one. We’ll check against your business’ best keywords to find a domain that will bring you the most business
            for your location. If you do have a domain, chances are it’s sitting somewhere, un-managed and nagging at
            your credit card regularly. We can help you take it and use it to its full potential. Transfer your domain
            to Cybertron Softech and we’ll take care of making sure everything connected to it works just the way it
            should.
          </>
        }
        serviceImg={assetImages.domainbanner}
        maxHeight="23rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Domains;
