import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import { assetImages } from '../../constant/images';

const MEANStack = () => {
  const features = [
    {
      id: 1,
      des: 'Our MEAN experts use MongoDB – a NoSQL database because it stores data similarly to the JSON format. As applications evolve over time, the dynamic schemas Inherent in MongoDB evolve along with it.',
      image: assetImages.mongobd,
    },
    {
      id: 2,
      des: 'Our developers love to use Express as it is a flexible Node Js web application framework to develop web and mobile applications with a robust set of features for businesses of any type or size.',
      image: assetImages.expressjs,
    },
    {
      id: 3,
      des: 'Our team uses Angular to extend basic HTML function- ality to more easily work with the dynamic views inherent in web apps. Angular JS can also be extended easily.',
      image: assetImages.angularjs,
    },
    {
      id: 4,
      des: 'Developers at Cybertron Softech create server-side Javascript web applications using the Node Js runtime environment. Since Node Js is asynchronous, it is far more scalable than traditional thread-based networking.',
      image: assetImages.nodejs,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="MEAN Stack Development Company"
        description="MEAN Stack is the modern software stack and a compilation of four advanced JavaScript-based technologies MongoDB, Express JS, Angular JS and Node Js. MEAN provides a full stack web application development environment for building fast, secure and scalable applications using single language across all the layers. Cybertron Softech provides end-to-end MEAN Stack development services using a clean code base prototyped by MEAN experts for your online business."
        serviceImg={assetImages.meanstackbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="What Is The Anatomy Of The MEAN Stack Web Application?"
        description="The MEAN stack application structure is a combination of MongoDB – 
        a NoSQL database that provides flexible data storage layers, Express 
        JS – that standardizes web application development, Angular JS – 
        that allows interactive gestures on web applications and Node Js – that 
        keeps your server running continuously, and together they form the 
        MEAN stack."
        serviceImg={assetImages.meanstackanatomy}
      />
      <ServiceFeatures
        features={features}
        title="Thrive Mean Stack Development To Enable Quicker And Glass Case Technical Innovation."
      />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default MEANStack;
