import React from 'react';
import Newsletter from '../components/commonComponents/Newsletter';
import Layout from '../components/layout/Layout';

import ServiceCardsHorizon from '../components/services/ServiceCardsHorizon';
import ServiceBanner from '../components/services/ServiceBanner';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import { assetImages } from '../constant/images';

const TestingAndQuality = () => {
  const features = [
    {
      id: 1,
      des: (
        <>
          Our team of expert QA testers performs high-quality testing focusing on user experience, performance,
          security, database, client-server applications, APIs, and other features and functionality of your
          applications to meet the unique business needs of our clients.
        </>
      ),
      title: 'Functional Testing',
      image: assetImages.testing1,
    },
    {
      id: 2,
      des: (
        <>
          This testing is done to check how applications are working at the initial phase. Our skilled QA testers are
          capable of identifying the initial potential usability issues of your applications and strive to perform
          high-grade testing to deliver a flawless user experience for your customers.
        </>
      ),
      title: 'Usability Testing',
      image: assetImages.uiux1,
    },
    {
      id: 3,
      des: (
        <>
          Compatibility testing identifies whether the application is working correctly or not on different browsers and
          devices. Cybertron Softech provides end-to-end testing services to ensure your app is fully compatible with
          multiple browsers and devices.
        </>
      ),
      title: 'Compatibility Testing',
      image: assetImages.uiux3,
    },
    {
      id: 4,
      des: (
        <>
          Unit testing is performed during the development of your applications to identify whether each unit is working
          perfectly or not as per the expectations. We check all components and units of your app to meet all the
          requirements of our clients.
        </>
      ),
      title: 'Unit Testing',
      image: assetImages.uiux4,
    },
    {
      id: 5,
      des: (
        <>
          We perform high-grade penetration testing and risk assessments to keep your applications safe and secure from
          spammers, attackers, and hackers. Our QA testers perform testing on various stages to bring top-notch security
          to your app without any bugs & vulnerabilities.
        </>
      ),
      title: 'Security Testing',
      image: assetImages.testing5,
    },
    {
      id: 6,
      des: (
        <>
          Stress testing is done to determine the maximum operating capacity of your applications at heavy load
          conditions. Our qualified QA testers will check your applications to ensure it doesn't fail at any load
          conditions, including colossal traffic, download, and usage.
        </>
      ),
      title: 'Stress Testing',
      image: assetImages.testing6,
    },
    {
      id: 7,
      des: (
        <>
          User interface testing is carried out to check whether the application is working fine as per the
          specifications. User interface testing includes in- depth testing of menus, icons, and buttons of your
          applications to ensure they are entirely working fine.
        </>
      ),
      title: 'User Interface Testing',
      image: assetImages.uiux7,
    },
    {
      id: 8,
      des: (
        <>
          We offer your applications' high performance, scalable, and fast-loading speed through our in-depth quality
          checks and software behavior analysis. Our QA professionals always aim to perform high-quality testing of your
          apps that deliver the best level of performance.
        </>
      ),
      title: 'Performance Testing',
      image: assetImages.uiux8,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Pre-eminent Software 
        Testing and Quality 
        Assurance Company"
        description={
          <>
            Offering high-end quality and software testing services to ensure your applications deliver the best
            software quality solutions for an exceptional user experience.
          </>
        }
        isVectorHidden={true}
        serviceImg={assetImages.testingbanner}
        maxHeight="20rem"
      />
      <section className="service-banner">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
              <img
                src={assetImages.testingsec1}
                alt={'uiuxsec'}
                style={{ maxHeight: '20rem' }}
                className="serviceImg"
              />
            </div>
            <div className="col-lg-6">
              <OneSideTitle
                title={'Best Software Testing and Quality Assurance Services in the UK'}
                des="In this fast-paced world, the demand for quick-loading applications has 
increased. People love to use applications that provide a seamless 
customer experience on all mobile devices. Many businesses are giving 
attention to testing and quality to optimize the user experience and 
provide the best-in-software quality solutions accordingly. Being a leading 
QA software testing company in the UK, at Cyubertron, we provide 
distinctive testing and quality assurance services to ensure your 
applications deliver outstanding user experiences with the utmost quality. 
Looking to get the best testing and quality assurance services then, talk 
to our experts. "
              />
            </div>
          </div>
        </div>
      </section>
      <ServiceCardsHorizon
        title="Our Testing and Quality 
Assurance Services"
        features={features}
      />
      <ServiceBanner
        title="What Makes Cybertron Softech The 
        Best Software Testing Company"
        description={
          <>
            At Cybertron Softech, we have a dynamic team of expert QA testers who can quickly identify and resolve any
            complex bug presented in your web and mobile apps and ensure to deliver the highest quality applications
            without any bug or threat. Our team of talented QA testers follows a meticulous process to perform
            high-quality testing on different parameters to ensure everything is fully functional and working fine
            without compromising the quality and quantity of features. We provide highly-performing, scalable, and
            highly fast-loading applications with high-end security solutions with our best-in-class testing and quality
            assurance services.
          </>
        }
        isVectorHidden={true}
        serviceImg={assetImages.testingsec2}
        maxHeight="20rem"
      />
      <Newsletter />
    </Layout>
  );
};

export default TestingAndQuality;
