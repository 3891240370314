import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import { assetImages } from '../../constant/images';

const MERNStack = () => {
  const features = [
    {
      id: 1,
      des: 'Our MERN experts use MongoDB – a NoSQL database because it stores data similarly to the JSON format. As applications evolve over time, the dynamic schemas Inherent in MongoDB evolve along with it.',
      image: assetImages.mongobd,
    },
    {
      id: 2,
      des: 'Express JS is a flexible and minimal Node Js web application framework which provides us with a robust set of features to build dynamic portal applications and APIs using Express JS. We have extensive experience in using Express JS as a server-side framework.',
      image: assetImages.expressjs,
    },
    {
      id: 3,
      des: 'React JS is a modern and extensive JavaScript framework, widely used to build ultra-fast JavaScript based isomorphic web applications. With proven skills in React Development Services, we are pioneers in building high-performing web applications.',
      image: assetImages.reactjslogo,
    },
    {
      id: 4,
      des: 'Node Js is an open-source, cross-platform used for building fast and scalable server-side applications. Node Js applicat ions are built on Google Chrome’s V8 JavaScript Engine which has a rich library of various JavaScript modules. Node Js is our core area of expertise.',
      image: assetImages.nodejs,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="MERN Stack Development Company"
        description="MERN Stack is an open source framework that consists of best JavaScript based technologies - MongoDB as Database, Express JS as Framework, React JS as Frontend Framework and Node Js as Server Platform. At Cybertron Softech, we make efforts to enhance the user experiences by our MERN stack development services. Our MERN stack developers will boost your business growth with our MERN stack development services that are specifically tailored to build integrated solutions."
        serviceImg={assetImages.mernbanner}
        maxHeight="23rem"
      />
      <ServiceSecTwo
        title="How MERN Stack Development Transforms Your Business?"
        description="The MERN Framework is becoming increasingly popular and is a powerful stack to work in. MERN application development is a combination of best technologies and with one language across all tiers, MERN Stack developers can build highly efficient, dynamic and secure APIs and web applications to deliver rich web user practice and more lively features to transform your business."
        serviceImg={assetImages.mernstackdevelopment}
      />
      <ServiceFeatures
        features={features}
        title="Leverage The Power Of MERN Stack Development Service For Remarkable Benefit & Great Development Experience"
      />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default MERNStack;
