import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import SerKeyBenefits from '../../components/services/SerKeyBenefits';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const AwsMig = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Why AWS Data Migration',
      des: (
        <>
          AWS Database Migration Service are cloud based and have virtually no downtime. It gives benefit of scaling,
          functional efficiency and multitude of data storage options. It gives an ease to migrate data between
          heterogeneous or homogeneous database engines. AWS Migration includes various features that were lacking in
          previous databases and can be accessed through both console and the command line interface.
        </>
      ),
      image: assetImages.awsmigsec,
    },
  ];
  const benefits = [
    {
      id: 1,
      title: <>COST EFFECTIVE</>,
      des: <>It reduces operational cost and increase effectiveness.</>,
    },

    {
      id: 3,
      title: <>DRIVES TRAFFIC</>,
      des: <>Your application experience increased traffic.</>,
    },
    {
      id: 4,
      title: <>SIMPLE TO USE</>,
      des: <>Simplified process, no need of any installation of any drivers or applications</>,
    },
    {
      id: 2,
      title: <>EFFICIENT PERFORMANCE</>,
      des: <>Faster application implementation and deployment reducing infrastructure overhead.</>,
    },
    {
      id: 5,
      title: <>SIMPLIFIED ADMINISTRATION</>,
      des: (
        <>
          Tracking and upgrading underlying server software can consume a lot of time. So a cloud provider will take
          care and handle all administrative tasks like database backup, software upgrades, and periodic maintenance.
          SIMPLE TO USE - Simplified process, no need of any installation of any drivers or applications.
        </>
      ),
    },
    {
      id: 6,
      title: <>SUPPORTS ALL DATABASES</>,
      des: (
        <>Supports homogeneous migrations such as Oracle to Oracle as well as heterogeneous Oracle to Amazon Aurora.</>
      ),
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="AWS Data Migration Services "
        description={
          <>
            AWS data migration helps you to migrate your data easily and securely. With the most widely used data system
            for migration is used to fully operate your data with minimal downtime. The service supports homogeneous
            migrations such as Oracle to Oracle, and also heterogeneous migrations between different database platforms,
            such as Oracle to MySQL or MySQL to Amazon Aurora. The source or target database must be on an AWS service.
          </>
        }
        serviceImg={assetImages.awsmigbanner}
        maxHeight="18rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg
        servicesWeProvide={servicesWeProvide}
        whyChoose={true}
        des="Cybertron Softech has Experts of technologies, who not only help reduce the migration costs, but also maintain the efficiency of the application well. 
Seamless migration remains the motto of the professional experts that helps out in maintaining the fidelity of the business logic."
      />
      <SerKeyBenefits
        title="Key Benefits of AWS 
Data Migration"
        benefits={benefits}
      />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default AwsMig;
