import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import { assetImages } from '../../constant/images';

const PythonWeb = () => {
  const features = [
    {
      id: 1,
      title: 'POWERFULLY EFFICIENT',
      des: 'Django is a free and open source web framework known for optimal memory utilisation and computes more functions successfully, ensuring minimal server-side congestion.',
      image: assetImages.python1,
    },
    {
      id: 2,
      title: 'SUPREMELY SECURE',
      des: 'Django applications are highly secure in managing user authentication requests. It is a superior framework that blocks masqueraders and hackers to enter into the system.',
      image: assetImages.python2,
    },
    {
      id: 3,
      title: 'HIGHLY SCALABLE',
      des: 'Django can manage multiple user requests simultaneously, without any glitches. The complete Django web app structure is super-scalable to handle any possible scenario.',
      image: assetImages.highlyScalable,
    },
    {
      id: 4,
      title: 'RAPID DEVELOPMENT',
      des: 'Unlike other platforms, Django web framework promotes swift development and shorter project life cycle due to prompt design, development, and deployment process.',
      image: assetImages.rapiddev,
    },
    {
      id: 6,
      title: 'COMMUNITY SUPPORT',
      des: 'Django web framework has been in the sphere for quite some time. A thriving online developer community is present for quick support and pro-bono consulting to solve critical issues, immediately.',
      image: assetImages.python5,
    },
    {
      id: 7,
      title: 'OPEN SOURCE',
      des: 'Django is an open source framework which follows the model view template architecture pattern that can be downloaded for digital integration with no hidden cost or subscription charges.',
      image: assetImages.python6,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Python Web Development Company"
        description="Django is a Python-based open source library with exceptional database management capabilities for hefty sites. Search engines, social networking sites and a host of popular web platforms use Python and Django web framework. The combination accelerates the web development process by encouraging rational design and scalable database management. As a skilled Python development company, we use the Django web framework that provides efficient solutions. It helps the business to engage end-users & grow their online presence."
        serviceImg={assetImages.pythonbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="Why Python Development Is Perfect For Digital Innovation?"
        description="The workflow of Python sets it apart from its counterparts. The versatile Django web framework has become a hot technology for the Fintech ecosystem. The impeccable combination of Python Development with Django programming is a gem for various industry segments because of a host of benefits"
        serviceImg={assetImages.pythonsec}
        maxHeight="25rem"
      />
      <ServiceFeatures features={features} title="Django Web Framework For Python App Development" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default PythonWeb;
