import React from 'react';
import Layout from '../components/layout/Layout';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import CenteredTitle from '../components/UIComponents/CenteredTitle';
import { assetImages } from '../constant/images';
import ServiceCardsHorizon from '../components/services/ServiceCardsHorizon';

const AboutUs = () => {
  const features = [
    {
      id: 1,
      des: (
        <>
          Before we do anything, we have to understand you. We need to understand what make you and your business tick.
          We want to see what your compelling future look like,so that “everything” we do is aligned to ultimately
          achieve results.
        </>
      ),
      title: '1. Discover',
    },
    {
      id: 2,
      des: (
        <>
          Once we have real clarity, know who your Competitors are and what you are trying to achieve, we build roadmap
          for success. This may include building beautifully designed website to showcase your brand and spread your
          message. once we have that covered, we build kick-ass marketing campaigns to drive your target market to the
          message.
        </>
      ),
      title: '2. Build',
    },
    {
      id: 3,
      des: (
        <>
          Once we have everything in place we can launch your business online. A lot of Planning and determination gets
          us to the point where we go live with your web and marketing campaign.
        </>
      ),
      title: '3. Launch',
    },
    {
      id: 4,
      des: (
        <>
          The process is ongoing- the fast-paced, and continuously changing online environ- ment means that we monitor,
          test and adjust on a daily basis. Just like any space program, we regularly check the trajectory of your
          campaign to make sure we are on course towards that compelling future.
        </>
      ),
      title: '4. Grow',
    },
  ];
  return (
    <Layout>
      <section className="about-us-banner">
        <div className="container-lg">
          <div className={`row align-items-center`}>
            <div className="col-lg-6">
              <OneSideTitle
                title="Our Treasure Is Our Work, People, Vision, Mission & Values"
                des={
                  <>
                    “We give like-minded businesses more time to follow their passion by building real human
                    relationships through results-driven digital marketing and application development”
                  </>
                }
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={assetImages.serviceBanner} alt="serviceBanner" className="bg-vector" />
              <img src={assetImages.aboutBanner} alt="whatwedo" className="img-fluid sec-image" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-us-content">
        <CenteredTitle
          title="About Us"
          des={
            <>
              Cybertron Softech is one of the leading web and mobile app development company and we still continue to
              soar even higher. Cybertron Softech is a pool of proficient thinkers, designers, developers,
              story-tellers, and marketers. Our team employs the art of designing and advertising to create unfailing
              brands for your business. The services are extended to the companies in India as well as abroad.
              <br />
              <br />
              <br />
              We analyze your business and goals not only with your vision but also on the ground of competitive
              landscape around. Hence every designing, advertising, and marketing campaign created by us brings
              guaranteed results. Regardless of the nature and size of the business, we deliver websites that will
              represent your business with grace.
              <br />
              <br />
              <br />
              Our result-driven design, development, digital marketing, and online reputation building services give an
              air of confidence to the businesses by making them popular and trusted among the users and potential
              customers.
            </>
          }
        />
        <img src={assetImages.aboutsecbg} alt="serviceBanner" className="bg-vector" />
      </section>
      <section className="about-us-banner">
        <div className="container-lg">
          <div className={`row align-items-center`}>
            <div className="col-lg-6">
              <OneSideTitle
                title="We Are Specialize in"
                des={
                  <>
                    Cybertron Softech is a tech company that specializes in offering Web Design services, Web
                    Development services and mobile app development services. Our team of developers consists of young,
                    dynamic university graduates who are as creative as they are eager to learn. We are always open and
                    accessible to all your website and app development ideas regardless of your budget or kind of
                    business. We develop apps that are compatible with whatever OS you’re using on your phone.
                  </>
                }
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img
                style={{ maxHeight: '17rem' }}
                src={assetImages.specialize}
                alt="whatwedo"
                className="img-fluid sec-image"
              />
            </div>
          </div>
        </div>
      </section>
      <CenteredTitle
        title="Why Cybertron Softech?"
        des={
          <>
            We provide a solution to almost all new technologies which mainly include: Node Js, Angular.js,
            React-Native, Amazon web services, PHP, Laravel, WordPress, Magento, Android, iOS app development.Get in
            touch today, We are always ready to hear your queries.
          </>
        }
      />
      <ServiceCardsHorizon title="Our Approach" features={features} />
    </Layout>
  );
};

export default AboutUs;
