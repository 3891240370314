import React from 'react';
import OneSideTitle from '../UIComponents/OneSideTitle';

const ServiceSecTwo = ({ title, description, serviceImg, maxHeight }) => {
  let style = {};
  if (maxHeight) {
    style = {
      maxHeight: maxHeight,
      width: 'auto',
    };
  }
  return (
    <section className="service-sec-two">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6 d-flex justify-content-center">
            <img src={serviceImg} alt={title} className="serviceImg" style={style} />
          </div>
          <div className="col-lg-6">
            <OneSideTitle title={title} des={description} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSecTwo;
