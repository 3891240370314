import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceCardsHorizon from '../../components/services/ServiceCardsHorizon';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const WebDesign = () => {
  const features = [
    {
      id: 1,
      des: (
        <>
          We come up with our own code and our own web designs. No off-the-shelf websites here. At Cybertron Softech, we
          build websites only for you.
        </>
      ),
      title: 'Originality',
      image: assetImages.webdevfe1,
    },
    {
      id: 2,
      des: (
        <>
          We translate your vision into something beautiful and more importantly, a brand that you can be proud of. And
          we don’t take the voice away from you. It’s your business after all and we want to build a website you will
          love.
        </>
      ),
      title: 'Creativity',
      image: assetImages.webdevfe2,
    },
    {
      id: 3,
      des: (
        <>
          We tell you what we’re worth and we never change our minds halfway. We only start the web development when
          you’re ready.
        </>
      ),
      title: 'Up-front Pricing',
      image: assetImages.webdevfe3,
    },
    {
      id: 4,
      des: (
        <>
          Our web design services work with marketing in mind. We create designs and functionality for the search
          engines. After all, if Google can’t find you, who can?
        </>
      ),
      title: 'Strategy',
      image: assetImages.webdevfe4,
    },
    {
      id: 5,
      des: (
        <>
          We build websites that you’re comfortable managing. We use open source platforms that provide you with
          visibility, flexibility and security.
        </>
      ),
      title: 'Flexibility',
      image: assetImages.webdevfe5,
    },
  ];
  const servicesWeProvide = [
    {
      id: 1,
      title: <>Custom Web Design with SEO in mind</>,
      des: (
        <>
          At Cybertron Softech, we strive to be the preferred provider of custom websites, designing powerful &
          interactive digital experiences that not only stand out from the crowd, but also best tells your story.
          <br />
          <br />
          Having worked with a diverse range of clients allows us to bring our knowledge and experiences to the table
          when helping clients design and build a website that represents their brand and most importantly, places it in
          front of the people who matter.
          <br />
          <br />
          Your journey with us is a carefully measured process. Because we’re a marketing agency first, we’re strategic
          in our approach and we reverse- engineer the development of your websites to create a final product that is
          built ultimately for the user, and therefore search engine friendly.
          <br />
          <br />
          We customize our web design packages to suit the varying needs of our clients and we always place your
          interests first.
          <br />
          <br />
          To give you an idea of what to expect, we’ll take you on a quick tour, starting right from the beginning.
        </>
      ),
      image: assetImages.webdev1,
      maxHeight: '35rem',
    },
    {
      id: 2,
      title: <>Website Design and Development</>,
      des: (
        <>
          At this first meeting, you’ll be introduced to our Digital Creative (and Head of Development) along with our
          Project Manager. You’ll be asked many questions so that we can understand your business in depth.
          <br />
          <br />
          This step is critical to ensure we know your business and it’s goals inside and out. We want to get to know
          your business completely to come up with the ultimate game plan and the most professional website design
          possible.
          <br />
          <br />
          During the meeting, we’ll ask you to share your story with us, the unique selling points of your business and
          your target audience. We’ll also get you to think of any websites that have made an impression on you along
          with any competitor sites that you’d like to smash out of park. At this stage, we’ll probably be able to
          identify the most appropriate platform to build your website on.
        </>
      ),
      image: assetImages.webdev2,
      maxHeight: '32rem',
    },
    {
      id: 3,
      title: <>Our Website CMS Platforms</>,
      des: (
        <>
          We’re not limited to certain platforms however our most popular options are WordPress and Shopify. We like
          WordPress because it’s easy- to-use, highly flexible and robust. It’s also extremely easy to update your
          content and to make aesthetic changes to the site.
          <br />
          <br />
          For an ecommerce website, we’ll probably recommend Shopify to build your online store. Shopify provides a
          range of unique features and advanced functionalities to sell your products digitally. Similar to Word Press,
          it’s easy-to-use, dynamic and very adaptable.
          <br />
          <br />
          And if your situation is super unique and neither WordPress or Shopify are right, we will then look at
          custom-coding a website for you.
          <br />
          <br />
          At the conclusion of this meeting, our goal is to walk away with enough information to know what ticks your
          boxes and an idea of what we can build for your business. Oh and if available, we’d also like to leave the
          meeting with all your design files and style guides in hand!
        </>
      ),
      image: assetImages.webdev3,
    },
    {
      id: 4,
      title: <>Responsive Web Design</>,
      des: (
        <>
          When building your website, our number one priority is for your business to be found on Google. This is
          because we’re not just professional web designers , we’re also a very smart marketing company with lots of
          experience in Search Engine Optimization, Conversion Optimization and Paid Advertising campaigns.
          <br />
          <br />
          As a result, when we start building a website, we look at it from a marketing perspective to give you the
          optimal chance of driving successful leads to your business and, obtaining a worthwhile return on investment.
        </>
      ),
      image: assetImages.webdev4,
    },
    {
      id: 5,
      title: <>High Converting Website Design Services</>,
      des: (
        <>
          This is seriously the best part and our chance to really show-off our website design services.
          <br />
          <br />
          We take what we’ve discovered from our meeting, brainstorm with the team and hand it over to our talented
          designers to make it happen. The design stage is around four weeks and by the second week, you’ll receive a
          sneak peek into design greatness.
          <br />
          <br />
          In total, there are three rounds to our web design services and by the fourth week, we should be well and
          truly on our way to signing off on the designs.
          <br />
          <br />
          Creating the best web design has to offer.
          <br />
          <br />
          The website design stage is definitely the most enjoyable part because it allows you to conceptualize what
          your future website will look like. We’ll also provide you with your mobile web design so that you have an
          idea of what your website will look like on a mobile and tablet device.
        </>
      ),
      image: assetImages.webdev5,
      maxHeight: '32rem',
    },
    {
      id: 6,
      title: <>Bringing your Website design to life</>,
      des: (
        <>
          We go quiet here not because we’ve slipped off the radar, but rather because we’re busy doing all the work in
          the back-end to bring your responsive web design to life.
          <br />
          <br />
          At this point, our developers have taken over and are focused on making the website happen and, ensuring that
          the site functions according to our project specifications. A few things happen here:
        </>
      ),
      image: assetImages.webdev6,
      listItems: [
        'We bring everything to life.',
        'We develop and test the functionalities.',
        'We build super responsive websites (and a mobile website).',
        'We work on site speed and ensure that the website is mobile friendly and adaptable for different screen sizes, and a variety of desktop computers (there’s no point building a site that mobile users won’t be able to access from their phones).',
        'We migrate the content across to the staging site.',
        'We place the staging site through our extensive QA process.',
      ],
      des2: (
        <>
          It takes 4 weeks for us to be in touch with the staging site, ready for your review. You’ll have time to
          really test out your website and make sure you’re happy with it. Any changes to content should also happen at
          this stage.
        </>
      ),
      maxHeight: '32rem',
    },
    {
      id: 7,
      title: <>And it's launch time!</>,
      des: (
        <>
          It’s the day we make your website live and debut it for the online world to see.
          <br />
          <br />
          While it sounds scary, rest assured it’s most definitely not. By the time we get to this point, we would’ve
          gone through numerous trials and multiple checklists to get the site perfect for launch.
          <br />
          <br />
          If you have an existing site, we would’ve also ensured that whatever traffic you were getting to your old site
          isn’t lost but rather, re-directed towards your new website. And as a part of our SEO web design, we set your
          site up with all the right meta descriptions, title and image tags to make it easy for Google to crawl through
          your site.
          <br />
          <br />
          Additionally, if you need any Hosting services, you’ve also come to the right place. We offer two Hosting
          packages either on our shared or dedicated servers. Speak to us just prior to launch and we will set you up on
          the most appropriate server for your business.
        </>
      ),
      image: assetImages.webdev7,
      maxHeight: '28rem',
    },
    {
      id: 8,
      title: <>Website Training</>,
      des: (
        <>
          Don’t fret, it’s never really goodbye. We envision a long-term relationship with you and regardless of this
          being mutual or not, as your website developer, we promise that we will never abandon you or your new website.
          <br />
          <br />
          In fact at the time of launch, we’ll be in touch for a complimentary training session with one of our amazing
          developers. We’ll make sure that you’re provided with a proper handover, training manuals and all.
        </>
      ),
      image: assetImages.webdev8,
      maxHeight: '25rem',
    },
    {
      id: 9,
      title: <>Accurate Tracking and Data Driven</>,
      des: (
        <>
          We’re all about the post-project service and we want to ensure that you’re fully equipped with the marketing
          standard analytics that allow you to truly thrive in the digital realm. That’s why we also ensure your website
          is built with relevant web analytics which not only track the amount of website visitors you have, but also
          more specific metrics like:
        </>
      ),
      listItems: [
        <>Which pages they’re converting on</>,
        <>How long they’re spending on your site</>,
        <>Which pages they’re bouncing on</>,
        <>The general demographics of your audience.</>,
      ],
      image: assetImages.webdev9,
    },
    {
      id: 10,
      title: <>Perhaps Marketing?</>,
      des: (
        <>
          So we’ve built you a site with basic Search Engine Optimisation (SEO). While this doesn’t mean that your
          website will start ranking on the first page of Google for its entirety, it does mean that your website is now
          registered with Google and, built with all the important features to enable you to undertake further SEO
          services.
          <br />
          <br />
          Acknowledging that Search Engines are dynamic and forever changing, achieving first page rankings on Google is
          a continuous process that requires constant content production centred around ever-changing search terms, and
          an array of other SEO work (eg. creating extra landing pages) that happens behind-the-scenes.
        </>
      ),
      image: assetImages.webdev10,
    },
    {
      id: 11,
      title: <>Contact the best Web Design Agency today!</>,
      des: (
        <>
          Apart from being your preferred Cybertron Softech web company, we’re also your local digital agency. We help
          clients from small businesses to large corporates realize their online dreams. We don’t believe in lock-in
          contracts or call-out fees, but rather we believe only in our ability to help you and your business.
        </>
      ),
      image: assetImages.webdev11,
      maxHeight: '25rem',
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. How a good web Design helps Businesses?',
      des: (
        <>
          In the real world, would you be enticed to walk into a dark and dingy store? Probably not. Well it’s
          essentially the same with the online world.
          <br />A website ultimately represents your business and if you’re trying to portray quality and project your
          expertise but your website looks unprofessional, you’re definitely sending the wrong message. And it’s not
          just about looking good, it’s about strategically looking amazing and trust us, there are many elements behind
          creating the right design.
        </>
      ),
    },
    {
      id: 2,
      title: '2. What is the difference between web design and web development?',
      des: (
        <>
          Web design conceptualises what your website will look like (like a house plan) whilst web development is
          actually building the house. We’re pretty special in that we can do both and because we design and build with
          marketing in mind, we do them well.
        </>
      ),
    },
    {
      id: 3,
      title: '3. Are our websites mobile responsive?',
      des: (
        <>
          Making sure your website is mobile friendly and can be accessed from both Mobile and Tablet devices is a huge
          priority for us. Acknowledging that mobile phones are the most popular choice of device used to visit any
          website makes this a crucial element in our design and development. So important that when we design, we
          always design with the mobile user in mind.{' '}
        </>
      ),
    },
    {
      id: 4,
      title: '4. Can i get access to the website after it’s finished?',
      des: (
        <>
          You’ll have access to a one-hour training session to make sure you’re equipped with the know-how of your new
          website. We’ll provide you with all the log-ins, run you through the process of updating your content and
          imagery and also answer any questions you have. And as I said before, it’s never goodbye. We’re always here
          for you and will not leave you or your website stranded.
        </>
      ),
    },
    {
      id: 5,
      title: '5. Do i have to host my website with you?',
      des: (
        <>
          Absolutely not! We’re not a hosting company but we do maintain our own servers which we manage as a part of
          our web design services. We provide either managed or shared servers and we’re happy to speak to you about the
          differences between the two.
          <br />
          If you have an existing hosting provider, that’s cool as well. We can communicate with them to coordinate a
          seamless migration of your new website onto their server.
        </>
      ),
    },
    {
      id: 6,
      title: '6. Why do we specialize in wordpress?',
      des: (
        <>
          Due to its flexibility and ultimately, being very easy to use, WordPress is definitely one of our preferred
          options. WordPress is an open source software (where the code is readily available) which makes it highly
          adaptable for expansion and affords clients the ability to take their website to any other provider. Due to
          its clean code, it’s also search engine friendly and makes it very easy to continuously maintain for SEO
          purposes. Our developers are experienced and incredibly familiar with WordPress and we can most definitely
          help you out.{' '}
        </>
      ),
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Web Design & Development"
        description={
          <>
            <span className="text-orage">SEO & MOBILE FRIENDLY WEBSITES BUILT TO CONVERT</span>
            <br />
            Beautifully designed, custom made websites built with the end in mind. Whether you are looking for build
            your brand and online presence or wanting to generate sales through improved user experience and conversion
            the team at Cybertron Softech can help.
          </>
        }
        isVectorHidden={true}
        serviceImg={assetImages.webDesBanner}
      />
      <ServiceCardsHorizon title="Why Build With Us" features={features} />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default WebDesign;
