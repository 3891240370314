import React from 'react';
import CenteredTitle from '../UIComponents/CenteredTitle';

const ServiceFeatures = ({ title, features, isFromAdword }) => {
  return (
    <section className="service-features">
      <CenteredTitle title={title} />
      <div className="container-lg">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-center gy-5">
              {features.map((itm) => (
                <div key={itm.id} className="col-lg-6 col-md-6">
                  <div className="feature-itm-wrapper">
                    <div className="orange-line"></div>
                    {itm.image && (
                      <div className="image-wrapper">
                        <img src={itm.image} alt={`fet-${itm.id}`} />
                      </div>
                    )}
                    <h4 className={`fet-title ${isFromAdword ? 'isFromAdword' : ''}`}>{itm.title}</h4>
                    <p className={`des-text ${isFromAdword ? '' : 'text-center'}`}>{itm.des}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceFeatures;
