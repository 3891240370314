import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';
import { assetImages } from '../../constant/images';

const Newsletter = () => {
  const { addToast } = useToasts();
  const [disabled, setDisabled] = useState(false);
  const onsubmit = (values, resetForm) => {
    setDisabled(true);
    axios
      .post('https://cybertronsoftech.ca/api/newsletter.php', { email: values.email })
      .then((response) => {
        setDisabled(false);
        resetForm();
        addToast('You have successfully Signed up for our newsletter!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: '1500',
        });
        console.log(response, 'response');
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };
  return (
    <section className="newsletter-sec">
      <div className="container-lg orange-bg-box">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <h2>Newsletter</h2>
            <p className="des-text" style={{ fontSize: '18px' }}>
              Sign-up for our newsletter to get the latest tech news, business stories and more straight to your
              mailbox.
            </p>
          </div>
          <div className="col-lg-7">
            <Formik
              enableReinitialize
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Please enter valid Email').required('Email is required!').nullable(),
              })}
              onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className="news-letter-form-wrapper">
                    <input
                      placeholder="Enter your email"
                      value={values.email}
                      className={`input-field ${errors.email && touched.email ? 'error' : ''}`}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      type="email"
                    />
                    <button type="submit" disabled={disabled}>
                      Subscribe
                    </button>
                  </div>
                  <span className="des-text">{errors.email && touched.email && <>{errors.email}</>}</span>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <img src={assetImages.newsHand} alt="newsHand" className="newsHand" />
    </section>
  );
};

export default Newsletter;
