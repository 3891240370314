import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdEmail, MdLocalPhone } from 'react-icons/md';

import { scrollToTop } from '../../common/ScrollToTop';
import { Menu } from '../../constant/Icon';
import { assetImages } from '../../constant/images';
import MobileMenu from './MobileMenu';
import Navigation from './Navigation';

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mobileMenu, setMobileMenu] = useState(false);
  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    setHeaderHeight(header.offsetHeight);
    window.addEventListener('scroll', handleScroll);
    scroll > headerTop ? (document.body.style.paddingTop = `${headerHeight}px`) : (document.body.style.paddingTop = 0);
    return () => {
      window.removeEventListener('scroll', handleScroll());
    };
    // eslint-disable-next-line
  }, []);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const menu = [
    {
      id: 1,
      name: 'Home',
      link: '/',
    },
  ];
  return (
    <>
      <header>
        <div className="header-top">
          <div className="container-lg">
            <div className="row justify-content-end gx-2">
              <div className="col-lg-auto">
                <a href="mailto:hello@cybertronsoftech.ca">
                  <MdEmail />
                  <span>hello@cybertronsoftech.ca</span>
                </a>
              </div>
              <div className="col-lg-auto"></div>
              <div className="col-lg-auto ">
                <a href="tel:+1 (639) 999-9820">
                  <MdLocalPhone />
                  <span>+1 (639) 999-9820</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={`${scroll > headerTop + 40 ? 'is-sticky' : ''} header-content`}>
          <div className="container-lg d-flex align-items-center">
            <div className="header-content__logo space-pr--15">
              <Link className="navbar-brand" to="/" onClick={scrollToTop}>
                <img src={assetImages.logo} alt="Logo" className="lozad" />
              </Link>
            </div>
            <Navigation />
            <div className="d-flex align-items-center justify-content-end" style={{ flex: 1 }}>
              <button onClick={() => setMobileMenu(true)} className="mobile-menu-icon d-block d-lg-none btn p-0">
                <Menu />
              </button>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu activeStatus={mobileMenu} getActiveStatus={setMobileMenu} menu={menu} scrollToTop={scrollToTop} />
    </>
  );
};

export default Header;
