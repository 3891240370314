import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const SEOServices = () => {
  const servicesWeProvide = [
    {
      id: 3,
      title: 'What Is Our Approach To SEO?',
      des: (
        <>
          Understanding that every SEO campaign is different. We carry out extensive research and obtain an in depth
          understanding of your industry. We take the time to understand your services and the level of competition in
          order to formulate a killer SEO strategy.
          <br />
          <br />
          We look at web analytics data and critical business information to create a winning formula. This ensures you
          achieve a return on your investment (ROI). If during the research phase we find the numbers don’t stack up, we
          won’t put out hand up for the job. Our reputation and our clients success is our driving focus.
        </>
      ),
      image: assetImages.seoFet1,
    },
    {
      id: 4,
      title: 'What Do Our SEO Services Include?',
      des: 'Forget the small-talk, we get on with the key things that matter. After understanding your business, we start implementing the work right away. SEO is a long term strategy but it’s even longer if your SEO team are slow to implement. Cybertron Softech gets sh#t done and doesn’t mess around.',
      image: assetImages.seoFet2,
      listItems: [
        'On Page Technical SEO (Get the house in order)',
        'Off Page SEO  (Authority Building In the Industry)',
        'Local SEO (Google My Business)',
        'Conversion Rate Optimisation (More leads from existing traffic)',
        'Web Analytics and Insights ',
        'Penalty Recovery (We can undo work that doesn’t meet the Google Guidelines)',
        'Content writing (We can provide content briefs if you want to handle content in-house)',
        'Content Marketing (Get eyes on your freshly written content)',
        'Website Migration (Ensure you don’t drop off the face of the earth when moving to your new website)',
      ],
      maxHeight: '25rem',
    },
    {
      id: 5,
      title: 'How Highly Relevant Traffic Increases Conversion',
      des: (
        <>
          Our end goal is to convert your traffic from Google. What this really means is that we focus on getting you
          quality organic traffic based on findings from our market research.
          <br />
          <br />
          By conducting thorough keyword research we pinpoint the search terms that are relevant to your business. This
          ensures we generate the greatest search results. From this, we marry up the right keywords to the right pages.
          After the mapping stage is complete, it’s at this point we know whether new pages (and new content) are
          needed.
          <br />
          <br />
          Essentially it means that we reverse engineer your website from what the user is searching for based within
          the search engine.
          <br />
          <br />
          Our SEO management services ensure that your website is SEO ready and driven for conversion. Our data driven
          marketers will conduct a website audit to highlight possible improvements for increasing your conversion rate
          online.
        </>
      ),
      image: assetImages.seoFet3,
      maxHeight: '25rem',
    },
    {
      id: 6,
      title: 'SEO Reporting & Management',
      des: (
        <>
          Your dedicated Account Manager will customise your report. Your Key Performance Indicators and overall
          business goals will dictate how we personalise your report. This means you can better understand the metrics
          that matter. If you prefer we can also record a video walking you through the report to educate you along the
          way.
          <br />
          <br />
          At the start of every month, you will receive a detailed report on how the previous month went. A huge part of
          what we do differently is that we remain transparent and accountable to you. Whether those results are good or
          bad, we’ll make sure you completely understand the how and why.
        </>
      ),
      image: assetImages.seoFet4,
    },
    {
      id: 7,
      title: "Ontario's Leading SEO Agency",
      des: (
        <>
          We’re an SEO company that never stops. We don’t just set and forget. In fact we pride ourselves on providing
          the best SEO services Ontario has to offer.
          <br />
          <br />
          We continuously look for growth opportunities. Whether it’s expanding your targeted keywords or developing the
          readability of your on-page content.
          <br />
          <br />
          We set year-on-year goals for growth in online presence and conversions in organic traffic. When you come
          onboard the Cybertron Softech Ship, you’re not just getting a one-off service. You’re entering a lifelong
          partnership with us, knowing we’re in it for the long run.
        </>
      ),
      image: assetImages.seoFet5,
      maxHeight: '25rem',
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. What is SEO?',
      des: 'Search Engine Optimization (SEO) is the process of making your website as user relevant as possible. In doing so increase your ranking within a search engine. It’s essentially getting your website to number 1 on Google. Whilst there are over 200 ranking factors, a few SEO strategies may include',
      listItems: [
        'Creating a better User Experience',
        'Including more relevant content',
        'Increasing website load speed',
        'Featuring in reputable external websites (news or social media) ',
        'Develop a strong content marketing strategy.',
      ],
    },
    {
      id: 2,
      title: '2. Who needs SEO Services?',
      des: (
        <>
          Anyone who has a product or service that people are actively searching for online (i.e. they are searching
          with intent). However in some instances SEO may not be right for all businesses. <br />
          <br />
          For example if your product is new to the market and we need to educate the consumer it exists. In that case,
          we can suggest interruption based marketing that will help people find your company website. This in turn will
          ultimately grow your business.
        </>
      ),
    },
    {
      id: 3,
      title: '3. How much does SEO Cost?',
      des: (
        <>
          Think about a painter, he can’t give you a quote without knowing the floor plan. First we must understand your
          business. Get to know the products or services you provide and the competitive landscape you are playing in.
          Once we have the lay of the land we can let you know the cost of SEO for your business.
        </>
      ),
    },
    {
      id: 4,
      title: '4. What do our SEO Service Include?',
      des: (
        <>
          We didn’t become Ontario's premium SEO company by taking shortcuts. We take a holistic approach to our SEO
          services. From keyword research, on page optimization, a clear content strategy and local SEO. Yep it’s all
          included in our fixed monthly SEO management services. Transparent SEO pricing with no surprises.
        </>
      ),
    },
    {
      id: 5,
      title: '5. What are the benefits of SEO?',
      des: (
        <>
          Unlike Adwords, when you stop spending your Adword account will stop generating leads. Search Engine
          Optimisation if different. Should you cease services, once you rank for your desired search queries you will
          still reap the benefits. It’s a long term investment in your online brand.
        </>
      ),
    },
    {
      id: 6,
      title: '6. How to measure results or success of an SEO Campaign? ',
      des: (
        <>
          Typically it’s the cost per acquisition that matters most. Organic Traffic through Keyword Ranking will drive
          revenue and additional brand awareness and authority to your business. A successful SEO campaign will grow
          your business and provide your business with return on investment.
        </>
      ),
    },
    {
      id: 7,
      title: "7. How do I improve my website's authority?",
      des: (
        <>
          You build website authority by “earning backlinks” from other websites. By producing informative and engaging
          content other websites will naturally link to that information. Naturally this improves your authority in your
          respective field.{' '}
        </>
      ),
    },
    {
      id: 8,
      title: '8. How long does SEO take?',
      des: (
        <>
          In the same way it takes time to build your authority and trust offline, building your online authority takes
          time. Whilst typically you can see some quick wins in the early months, it can take anywhere from 3 – 24
          months.{' '}
        </>
      ),
    },
  ];
  const features = [
    {
      id: 1,
      des: 'At Cybertron Softech we don’t believe in lock in contracts. We want to earn our keep and win your business month after month. Plus we get it, sometimes circumstances change. So we simply ask for the courtesy of 30 days notice should you wish to cease services.',
      title: 'No lock in Contacts',
      image: assetImages.seochoose1,
    },
    {
      id: 2,
      title: 'Proven Track Record',
      des: 'This may seem obvious, however, we only take on a limited number of SEO clients in each niche. Otherwise we are taking from Peter to give to Paul. The fact is that there are only so many top spots in the SERP (Search Engine Results Pages). So we pick the clients we work with wisely, to create both industry leaders and a good reputation.',
      image: assetImages.seochoose2,
    },
    {
      id: 3,
      title: 'Monthly Reporting',
      des: 'Clear and transparent reporting as to the progress of your SEO results. We like to keep it simple with Leads, Organic Traffic and Rankings along with the next steps in your SEO campaign. We have two options for reporting you can choose from based on your preferred communication style.',
      image: assetImages.seochoose3,
    },
    {
      id: 4,
      title: 'No Surprises',
      des: 'We manage expectations from the start. Our SEO services are delivered with a fixed monthly fee. This fee is based on the work required to achieve the outcomes within the desired timeframe. We perform thorough keyword and competitor research in order to manage expectations at the start of any SEO campaign.',
      image: assetImages.seochoose4,
    },
    {
      id: 5,
      title: 'We walk the talk',
      des: 'Naturally it doesn’t get any harder than trying to rank for SEO related terms against other SEO providers. If we can win that battle then you know we have what it takes to win in your industry.',
      image: assetImages.seochoose5,
    },
    {
      id: 6,
      title: 'Educational approach',
      des: (
        <>
          Our team of seo experts take an educational approach. We teach you along the way what, when and why things are
          being done.
          <br />
          <br />
          There’s a portion of work that goes on behind the scenes of an SEO campaign. So it’s often difficult to
          understand what you are paying for. Our SEO team in Ontario do our best to shed light on the work being done
          and the next steps on our radar.
        </>
      ),
      image: assetImages.seochoose6,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="No SEO Contracts, Just Long Term Results"
        description={
          <>
            <span className="text-orage">QUALIFIED RANKINGS, INCREASED TRAFFIC & A FOCUS ON EPIC ROI</span>
            <br /> A transparent SEO agency delivering measurable results from Ontario's most reputable SEO team. We’ll
            guide you on the SEO process and demystify what’s involved. The key for us is to educate, under promise and
            over deliver.
          </>
        }
        serviceImg={assetImages.seoBanner}
        maxHeight="24rem"
      />
      <ServiceFeatures features={features} title="Why Choose Us" />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default SEOServices;
