import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';

import Layout from '../components/layout/Layout';
import CenteredTitle from '../components/UIComponents/CenteredTitle';
import { scrollToTop } from '../common/ScrollToTop';

const EstimateProject = () => {
  const [disabled, setDisabled] = useState(false);
  const [briefDesErr, setBriefDesErr] = useState(false);
  const { addToast } = useToasts();
  useEffect(() => {
    scrollToTop();
  }, []);

  const onsubmit = (values, resetForm) => {
    setDisabled(true);
    values.finalString = `<p><b>What kind of project are you developing?</b> - ${values.projectKind}</p>
    ${
      values.projectKind === 'Mobile App Development' || values.projectKind === 'Website Development'
        ? `<p><b>How would you like to start?</b> - ${values.oldnew}</p>`
        : `<p><b>What game would you like?</b> - ${values.gameplatform}</p>`
    }
    ${values.gameplatform !== '' && `<p><b>What type of game will it be?</b> - ${values.gameType}</p>`}
    ${values.gameType !== '' && `<p><b>Single or Multiplayer?</b> - ${values.gamePlayerType}</p>`}
    ${
      values.gamePlayerType !== '' &&
      `<p><b>Which category best defines your game?</b> - ${values.gameCategory}${
        values.gameCategory === 'Other' && `, ${values.gameCategoryOther}`
      }</p>`
    }
    ${values.gameCategory !== '' && `<p><b>Will you need the graphics designed?</b> - ${values.graphicsDes}</p>`}
    ${values.graphicsDes !== '' && `<p><b>How will the user log in to the game?</b> - ${values.howloginGame}</p>`}
    ${values.howloginGame !== '' && `<p><b>Do you require an admin panel?</b> - ${values.gameAdmin}</p>`}
    ${values.gameAdmin !== '' && `<p><b>How will you earn from the game?</b> - ${values.gameEarn}</p>`}
    ${
      values.oldnew !== '' &&
      values.projectKind === 'Mobile App Development' &&
      `<p><b>What platform do you want?</b> - ${values.newProjecttype}</p>`
    }
    ${
      values.oldnew !== '' &&
      values.projectKind === 'Website Development' &&
      `<p><b>What type of web solution do you need?</b> - ${values.webappType}</p>`
    }
    ${
      values.webappType === 'Dynamic' &&
      `<p><b>What category best describes your web application?</b> - ${values.dynamicwebappType}</p>`
    }
    ${
      values.dynamicwebappType !== '' &&
      `<p><b>Which technology would you like to use?</b> - ${values.dynamicwebappTech}${
        values.dynamicwebappTech === 'Other' && `, ${values.dynamicwebappTechOther}`
      }</p>`
    }
    ${
      values.webappType === 'Static' &&
      `<p><b>Which technology would you like to use?</b> - ${values.staticwebappTech}${
        values.staticwebappTech === 'Other' && `, ${values.staticwebappTechOther}`
      }</p>`
    }
    ${values.dynamicwebappTech !== '' && `<p><b>Design Options</b> - ${values.webdesignOpt}</p>`}
    ${values.staticwebappTech !== '' && `<p><b>Design Options</b> - ${values.webdesignOpt}</p>`}
    ${values.webdesignOpt !== '' && `<p><b>Payment integration required?</b> - ${values.paymentIntReq}</p>`}
    ${values.newProjecttype === 'IOS' && `<p><b>What device will the app be used on?</b> - ${values.iosDevice}</p>`}
    ${
      (values.newProjecttype === 'Android' ||
        values.newProjecttype === 'Both' ||
        values.newProjecttype === 'Not Sure') &&
      `<p><b>What device will the app be used on?</b> - ${values.androidDevice}</p>`
    }
    ${
      values.iosDevice !== '' &&
      values.oldnew === 'Creating An Old Project' &&
      `<p><b>What technology used to develop the app?</b> - ${values.iosOldTech}${
        values.iosOldTech === 'Other' && `, ${values.otheroldTech}`
      }</p>`
    }
    ${
      values.oldnew === 'Creating An Old Project' &&
      values.iosOldTech !== '' &&
      `<p><b>What will the app be used for?</b> - ${values.appusage}${
        values.appusage === 'Other' && `, ${values.otherUsage}`
      }</p>`
    }
    ${
      (values.iosDevice !== '' || values.androidDevice !== '') &&
      values.oldnew === 'Creating A New Project' &&
      `<p><b>Do you need us to design the app?</b> - ${values.iphoneDesign}</p>`
    }
    ${
      (values.iphoneDesign !== '' || values.iosOldTech !== '') &&
      values.oldnew === 'Creating A New Project' &&
      `<p><b>What will the app be used for?</b> - ${values.appusage}${
        values.appusage === 'Other' && `, ${values.otherUsage}`
      }</p>`
    }
    ${
      values.appusage !== '' &&
      values.oldnew === 'Creating A New Project' &&
      `<p><b>What type of login is needed?</b> - ${values.typeOfLogin}</p>`
    }
    ${values.typeOfLogin !== '' && `<p><b>Does the app need a chat feature?</b> - ${values.needChatFeature}</p>`}
    ${
      values.needChatFeature !== '' && `<p><b>Do you require an admin panel for app?</b> - ${values.needAdminPanel}</p>`
    }
    ${
      (values.needAdminPanel !== '' || values.paymentIntReq !== '') &&
      `<p><b>Is GPS integration required?</b> - ${values.needGPS}</p>`
    }
    ${values.needGPS !== '' && `<p><b>How will you earn from the app?</b> - ${values.howearn}</p>`}
    `;
    axios
      .post('https://cybertronsoftech.ca/api/estimate.php', { ...values })
      .then((response) => {
        setDisabled(false);
        resetForm();
        addToast('Thank you for reaching out to us!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: '1500',
        });
        console.log(response, 'response');
      })
      .catch((error) => {
        setDisabled(false);
        console.log(error, 'error');
      });
  };
  const projectKindArr = [
    {
      valueofopt: 'Mobile App Development',
      label: <>Mobile App Development</>,
    },
    {
      valueofopt: 'Website Development',
      label: (
        <>
          Website
          <br />
          Development
        </>
      ),
    },
    {
      valueofopt: 'Game Development',
      label: (
        <>
          Game
          <br />
          Development
        </>
      ),
    },
  ];
  const gameCategories = [
    'Sport',
    'Shooting',
    'Racing',
    'Adventure',
    'Puzzle',
    'Fighting',
    'Action Game',
    'Board',
    'Simulator',
    'Other',
  ];
  const gameEarn = ['In-App Purchase', 'Sell Products', 'Advertise', 'Subscription', 'Paid App', 'None'];
  const renderOptCard = (colclass, key, valueofopt, label, setFieldValue, value, setOtherValues) => {
    return (
      <div className={colclass}>
        <div
          onClick={() => {
            setFieldValue(key, valueofopt);
            if (setOtherValues) {
              setOtherValues(setFieldValue, key);
            }
          }}
          className={`opt-cards ${value === valueofopt ? 'active' : ''}`}
        >
          <div className="orange-line"></div>
          {label}
        </div>
      </div>
    );
  };
  const renderTitle = (title) => {
    return <h4 className="item-title">{title}</h4>;
  };
  const renderDes = (des) => {
    return <p className="des-text">{des}</p>;
  };
  const renderTypeOfLogin = (setFieldValue, values) => {
    return (
      <>
        {renderTitle('What type of login is needed?')}
        <div className="row align-items-center">
          {renderOptCard('col-lg-2', 'typeOfLogin', 'Email', 'Email', setFieldValue, values.typeOfLogin)}
          {renderOptCard('col-lg-2', 'typeOfLogin', 'Social Media', 'Social Media', setFieldValue, values.typeOfLogin)}
          {renderOptCard('col-lg-2', 'typeOfLogin', 'Both', 'Both', setFieldValue, values.typeOfLogin)}
          {renderOptCard('col-lg-2', 'typeOfLogin', 'None', 'None', setFieldValue, values.typeOfLogin)}
        </div>
      </>
    );
  };
  const renderWhatWillAppUsed = (setFieldValue, values, errors, touched) => {
    return (
      <>
        {renderTitle('What will the app be used for?')}
        <div className="row align-items-center">
          {renderOptCard('col-lg-3', 'appusage', 'E-Commerce', 'E-Commerce', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Travel', 'Travel', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Chatting', 'Chatting', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Education', 'Education', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Food Delivery', 'Food Delivery', setFieldValue, values.appusage)}
          {renderOptCard(
            'col-lg-3',
            'appusage',
            'Health & Fitness',
            'Health & Fitness',
            setFieldValue,
            values.appusage
          )}
          {renderOptCard('col-lg-3', 'appusage', 'Live Streaming', 'Live Streaming', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Social Media', 'Social Media', setFieldValue, values.appusage)}
          {renderOptCard('col-lg-3', 'appusage', 'Taxi Booking', 'Taxi Booking', setFieldValue, values.appusage)}
          {renderOptCard(
            'col-lg-3',
            'appusage',
            'Other',
            <>
              <p>Other</p>
              {values.appusage === 'Other' && (
                <>
                  <input
                    placeholder="Please Specify"
                    value={values.otherUsage}
                    className={`input-field ${errors.otherUsage && touched.otherUsage ? 'error' : ''}`}
                    onChange={(e) => setFieldValue('otherUsage', e.target.value)}
                  />
                  <span className="error-text">
                    {errors.otherUsage && touched.otherUsage && <>{errors.otherUsage}</>}
                  </span>
                </>
              )}
            </>,
            setFieldValue,
            values.appusage
          )}
        </div>
      </>
    );
  };
  const renderBriefDes = (setFieldValue, values, errors, touched) => {
    return (
      <>
        <p className="label">Please provide a brief description of the changes required</p>
        <textarea
          rows="4"
          maxLength={1000}
          placeholder="Please include details of all Frontend and Backend modification that are required in your existing application.."
          value={values.briefDes}
          className={`input-field ${briefDesErr ? 'error' : ''}`}
          onChange={(e) => setFieldValue('briefDes', e.target.value)}
        />
        <span className="error-text">{briefDesErr && <>Please provide brief description.</>}</span>
        <div className="row justify-content-center">
          <div className="col-auto">
            <button
              className="blue-bg-button"
              onClick={() => {
                if (values.briefDes) {
                  setFieldValue('briefDesAdded', true);
                  setBriefDesErr(false);
                } else {
                  setBriefDesErr(true);
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  };
  const designOptions = (setFieldValue, values) => {
    return (
      <>
        {renderTitle('Design Options')}
        <div className="row align-items-center">
          {renderOptCard('col-lg-3', 'webdesignOpt', 'Ready Theme', 'Ready Theme', setFieldValue, values.webdesignOpt)}
          {renderOptCard('col-lg-3', 'webdesignOpt', 'Custom UI', 'Custom UI', setFieldValue, values.webdesignOpt)}
        </div>
      </>
    );
  };
  const graphicsOptions = (setFieldValue, values) => {
    return (
      <>
        {renderTitle('Will you need the graphics designed?')}
        <div className="row align-items-center">
          {renderOptCard(
            'col-lg-6',
            'graphicsDes',
            'Yes, Graphics designe is required',
            'Yes, Graphics designe is required',
            setFieldValue,
            values.graphicsDes
          )}
          {renderOptCard(
            'col-lg-6',
            'graphicsDes',
            'No, Graphics designe is not required',
            'No, Graphics designe is not required',
            setFieldValue,
            values.graphicsDes
          )}
        </div>
      </>
    );
  };
  const setUpperLevelValues = (setFieldValue, key) => {
    if (key === 'projectKind') {
      setFieldValue('oldnew', '');
      setFieldValue('gameplatform', '');
      setFieldValue('iosDevice', '');
      setFieldValue('newProjecttype', '');
      setFieldValue('iosOldTech', '');
      setFieldValue('otheroldTech', '');
      setFieldValue('iphoneDesign', '');
      setFieldValue('appusage', '');
      setFieldValue('otherUsage', '');
      setFieldValue('typeOfLogin', '');
      setFieldValue('needChatFeature', '');
      setFieldValue('needAdminPanel', '');
      setFieldValue('needGPS', '');
      setFieldValue('howearn', '');
      setFieldValue('androidDevice', '');
      setFieldValue('webappType', '');
      setFieldValue('dynamicwebappType', '');
      setFieldValue('staticwebappTech', '');
      setFieldValue('staticwebappTechOther', '');
      setFieldValue('dynamicwebappTech', '');
      setFieldValue('dynamicwebappTechOther', '');
      setFieldValue('webdesignOpt', '');
      setFieldValue('email', '');
      setFieldValue('name', '');
      setFieldValue('phone', '');
      setFieldValue('companyName', '');
      setFieldValue('description', '');
      setFieldValue('website', '');
      setFieldValue('briefDesAdded', false);
      setFieldValue('paymentIntReq', '');
      setFieldValue('gameType', '');
    } else if (key === 'oldnew') {
      setFieldValue('gameplatform', '');
      setFieldValue('newProjecttype', '');
      setFieldValue('newProjecttype', '');
      setFieldValue('iosDevice', '');
      setFieldValue('iosOldTech', '');
      setFieldValue('otheroldTech', '');
      setFieldValue('iphoneDesign', '');
      setFieldValue('appusage', '');
      setFieldValue('otherUsage', '');
      setFieldValue('typeOfLogin', '');
      setFieldValue('needChatFeature', '');
      setFieldValue('needAdminPanel', '');
      setFieldValue('needGPS', '');
      setFieldValue('howearn', '');
      setFieldValue('androidDevice', '');
      setFieldValue('webappType', '');
      setFieldValue('dynamicwebappType', '');
      setFieldValue('staticwebappTech', '');
      setFieldValue('staticwebappTechOther', '');
      setFieldValue('dynamicwebappTech', '');
      setFieldValue('dynamicwebappTechOther', '');
      setFieldValue('webdesignOpt', '');
      setFieldValue('email', '');
      setFieldValue('name', '');
      setFieldValue('phone', '');
      setFieldValue('companyName', '');
      setFieldValue('description', '');
      setFieldValue('website', '');
      setFieldValue('briefDesAdded', false);
      setFieldValue('paymentIntReq', '');
      setFieldValue('gameType', '');
    } else if (key === 'gameplatform') {
      setFieldValue('oldnew', '');
      setFieldValue('newProjecttype', '');
      setFieldValue('newProjecttype', '');
      setFieldValue('iosDevice', '');
      setFieldValue('iosOldTech', '');
      setFieldValue('otheroldTech', '');
      setFieldValue('iphoneDesign', '');
      setFieldValue('appusage', '');
      setFieldValue('otherUsage', '');
      setFieldValue('typeOfLogin', '');
      setFieldValue('needChatFeature', '');
      setFieldValue('needAdminPanel', '');
      setFieldValue('needGPS', '');
      setFieldValue('howearn', '');
      setFieldValue('androidDevice', '');
      setFieldValue('webappType', '');
      setFieldValue('dynamicwebappType', '');
      setFieldValue('staticwebappTech', '');
      setFieldValue('staticwebappTechOther', '');
      setFieldValue('dynamicwebappTech', '');
      setFieldValue('dynamicwebappTechOther', '');
      setFieldValue('webdesignOpt', '');
      setFieldValue('email', '');
      setFieldValue('name', '');
      setFieldValue('phone', '');
      setFieldValue('companyName', '');
      setFieldValue('description', '');
      setFieldValue('website', '');
      setFieldValue('briefDesAdded', false);
      setFieldValue('paymentIntReq', '');
      setFieldValue('gameType', '');
    }
  };
  return (
    <Layout>
      <CenteredTitle
        title="Estimate Your Project"
        des={
          <>
            Tell us about your project we will respond within one business day. Fill in the below form for get
            estimation.
          </>
        }
      />
      <div className="container-lg">
        <Formik
          initialValues={{
            projectKind: '',
            oldnew: '',
            gameplatform: '',
            newProjecttype: '',
            iosDevice: '',
            iosOldTech: '',
            otheroldTech: '',
            iphoneDesign: '',
            appusage: '',
            otherUsage: '',
            typeOfLogin: '',
            needChatFeature: '',
            needAdminPanel: '',
            needGPS: '',
            howearn: '',
            androidDevice: '',
            webappType: '',
            dynamicwebappType: '',
            staticwebappTech: '',
            staticwebappTechOther: '',
            dynamicwebappTech: '',
            dynamicwebappTechOther: '',
            webdesignOpt: '',
            email: '',
            name: '',
            phone: '',
            companyName: '',
            description: '',
            website: '',
            briefDesAdded: false,
            paymentIntReq: '',
            gameType: '',
            gamePlayerType: '',
            gameCategory: '',
            gameCategoryOther: '',
            graphicsDes: '',
            howloginGame: '',
            gameAdmin: '',
            gameEarn: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Please enter valid Email').required('Email is required').nullable(),
            // website: Yup.string().url('Please enter valid URL').nullable(),
            name: Yup.string()
              .min(3, 'Name must be at least 3 characters.')
              .max(50, 'Maximum limit of characters allowed is 50.')
              .required('Name is required.')
              .matches(new RegExp('^[a-zA-Z\\-_ ]*$'), 'Name is not valid.')
              .nullable(),
            companyName: Yup.string()
              .max(100, 'Maximum limit of characters allowed is 100.')
              .matches(new RegExp('^[a-zA-Z\\-_ ]*$'), 'Name is not valid.')
              .nullable(),
            phone: Yup.string()
              .test(`phone`, 'Please enter a valid Phone number.', (value) => {
                if (value) {
                  if (isPossiblePhoneNumber(value)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              })
              .nullable(),
          })}
          onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="contact-form">
              {renderTitle('What kind of project are you developing?')}
              <div className="row align-items-center">
                {projectKindArr?.map((prk) => (
                  <React.Fragment key={prk.valueofopt}>
                    {renderOptCard(
                      'col-lg-3',
                      'projectKind',
                      prk.valueofopt,
                      prk.label,
                      setFieldValue,
                      values.projectKind,
                      setUpperLevelValues
                    )}
                  </React.Fragment>
                ))}
              </div>
              {values.projectKind &&
              (values.projectKind === 'Mobile App Development' || values.projectKind === 'Website Development') ? (
                <>
                  {renderTitle('How would you like to start?')}
                  {renderDes(
                    `Please specify if your ${
                      values.projectKind === 'Website Development' ? 'website or web application' : 'mobile application'
                    } needs to be developed from scratch, or if modifications are required to an existing system.`
                  )}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'oldnew',
                      'Creating A New Project',
                      <>
                        Creating A<br />
                        New Project
                      </>,
                      setFieldValue,
                      values.oldnew,
                      setUpperLevelValues
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'oldnew',
                      'Creating An Old Project',
                      <>
                        Creating An
                        <br />
                        Old Project
                      </>,
                      setFieldValue,
                      values.oldnew,
                      setUpperLevelValues
                    )}
                  </div>
                </>
              ) : (
                values.projectKind === 'Game Development' && (
                  <>
                    {renderTitle('What game would you like?')}
                    <div className="row align-items-center">
                      {renderOptCard('col-lg-2', 'gameplatform', 'IOS', 'IOS', setFieldValue, values.gameplatform)}
                      {renderOptCard(
                        'col-lg-2',
                        'gameplatform',
                        'Android',
                        'Android',
                        setFieldValue,
                        values.gameplatform
                      )}
                      {renderOptCard(
                        'col-lg-2',
                        'gameplatform',
                        'Desktop',
                        'Desktop',
                        setFieldValue,
                        values.gameplatform
                      )}
                      {renderOptCard('col-lg-2', 'gameplatform', 'Web', 'Web', setFieldValue, values.gameplatform)}
                      {renderOptCard('col-lg-2', 'gameplatform', 'Steam', 'Steam', setFieldValue, values.gameplatform)}
                      {renderOptCard('col-lg-2', 'gameplatform', 'All', 'All', setFieldValue, values.gameplatform)}
                    </div>
                  </>
                )
              )}
              {values.gameplatform !== '' && (
                <>
                  {renderTitle('What type of game will it be?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-3', 'gameType', '2D Game', '2D Game', setFieldValue, values.gameType)}
                    {renderOptCard('col-lg-3', 'gameType', '3D Game', '3D Game', setFieldValue, values.gameType)}
                  </div>
                </>
              )}
              {values.gameType !== '' && (
                <>
                  {renderTitle('Single or Multiplayer?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'gamePlayerType',
                      'Single',
                      'Single',
                      setFieldValue,
                      values.gamePlayerType
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'gamePlayerType',
                      'Multiple',
                      'Multiple',
                      setFieldValue,
                      values.gamePlayerType
                    )}
                  </div>
                </>
              )}
              {values.gamePlayerType !== '' && (
                <>
                  {renderTitle('Which category best defines your game?')}
                  <div className="row align-items-center">
                    {gameCategories?.map((prk) => (
                      <React.Fragment key={prk}>
                        {renderOptCard(
                          'col-lg-3',
                          'gameCategory',
                          prk,
                          prk === 'Other' ? (
                            <>
                              <p>Other</p>
                              {values.gameCategory === 'Other' && (
                                <>
                                  <input
                                    placeholder="Please Specify"
                                    value={values.gameCategoryOther}
                                    className={`input-field ${
                                      errors.gameCategoryOther && touched.gameCategoryOther ? 'error' : ''
                                    }`}
                                    onChange={(e) => setFieldValue('gameCategoryOther', e.target.value)}
                                  />
                                  <span className="error-text">
                                    {errors.gameCategoryOther && touched.gameCategoryOther && (
                                      <>{errors.gameCategoryOther}</>
                                    )}
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            prk
                          ),
                          setFieldValue,
                          values.gameCategory,
                          setUpperLevelValues
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
              {values.gameCategory !== '' &&
                (values.gameCategory !== 'Other'
                  ? graphicsOptions(setFieldValue, values)
                  : values.gameCategoryOther !== '' && graphicsOptions(setFieldValue, values))}
              {values.graphicsDes !== '' && (
                <>
                  {renderTitle('How will the user log in to the game?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-3', 'howloginGame', 'Email', 'Email', setFieldValue, values.howloginGame)}
                    {renderOptCard(
                      'col-lg-3',
                      'howloginGame',
                      'Social Media',
                      'Social Media',
                      setFieldValue,
                      values.howloginGame
                    )}
                    {renderOptCard('col-lg-3', 'howloginGame', 'Both', 'Both', setFieldValue, values.howloginGame)}
                    {renderOptCard('col-lg-3', 'howloginGame', 'None', 'None', setFieldValue, values.howloginGame)}
                  </div>
                </>
              )}
              {values.howloginGame !== '' && (
                <>
                  {renderTitle('Do you require an admin panel?')}
                  {renderDes(
                    'Will your app need to access data from a game, or use content management systems to manage data from the backend.'
                  )}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'gameAdmin',
                      'Admin Panel',
                      'Admin Panel',
                      setFieldValue,
                      values.gameAdmin
                    )}
                    {renderOptCard('col-lg-3', 'gameAdmin', 'Static', 'Static', setFieldValue, values.gameAdmin)}
                    {renderOptCard(
                      'col-lg-3',
                      'gameAdmin',
                      "I don't Know",
                      "I don't Know",
                      setFieldValue,
                      values.gameAdmin
                    )}
                  </div>
                </>
              )}
              {values.gameAdmin !== '' && (
                <>
                  {renderTitle('How will you earn from the game?')}
                  <div className="row align-items-center">
                    {gameEarn?.map((prk) => (
                      <React.Fragment key={prk}>
                        {renderOptCard(
                          'col-lg-3',
                          'gameEarn',
                          prk,
                          prk,
                          setFieldValue,
                          values.gameEarn,
                          setUpperLevelValues
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
              {values.oldnew !== '' && values.projectKind === 'Mobile App Development' && (
                <>
                  {renderTitle('What platform do you want?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-2', 'newProjecttype', 'IOS', 'IOS', setFieldValue, values.newProjecttype)}
                    {renderOptCard(
                      'col-lg-2',
                      'newProjecttype',
                      'Android',
                      'Android',
                      setFieldValue,
                      values.newProjecttype
                    )}
                    {renderOptCard('col-lg-2', 'newProjecttype', 'Both', 'Both', setFieldValue, values.newProjecttype)}
                    {values.oldnew === 'Creating A New Project' &&
                      renderOptCard(
                        'col-lg-2',
                        'newProjecttype',
                        'Not Sure',
                        'Not Sure',
                        setFieldValue,
                        values.newProjecttype
                      )}
                  </div>
                </>
              )}

              {values.projectKind === 'Website Development' && values.oldnew !== '' && (
                <>
                  {renderTitle('What type of web solution do you need?')}
                  {renderDes(
                    'Please specify if your website or web application needs to be developed from scratch, or if modifications are required to an existing system.'
                  )}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-3', 'webappType', 'Dynamic', 'Dynamic', setFieldValue, values.webappType)}
                    {renderOptCard('col-lg-3', 'webappType', 'Static', 'Static', setFieldValue, values.webappType)}
                  </div>
                </>
              )}
              {values.webappType === 'Dynamic' && (
                <>
                  {renderTitle('What category best describes your web application?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappType',
                      'E-Commerce Solution',
                      'E-Commerce Solution',
                      setFieldValue,
                      values.dynamicwebappType
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappType',
                      'Custom Web Development',
                      <>
                        Custom Web
                        <br />
                        Development
                      </>,
                      setFieldValue,
                      values.dynamicwebappType
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappType',
                      'Portal Development',
                      'Portal Development',
                      setFieldValue,
                      values.dynamicwebappType
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappType',
                      'Blog Development',
                      'Blog Development',
                      setFieldValue,
                      values.dynamicwebappType
                    )}
                  </div>
                </>
              )}
              {values.dynamicwebappType !== '' && (
                <>
                  {renderTitle('Which technology would you like to use?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'WooCommerce',
                      'WooCommerce',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'Magento',
                      'Magento',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'Shopify',
                      'Shopify',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'PHP',
                      'PHP',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'Laravel',
                      'Laravel',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'Codeigniter',
                      'Codeigniter',
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      "I don't Know",
                      "I don't Know",
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'dynamicwebappTech',
                      'Other',
                      <>
                        <p>Other</p>
                        {values.dynamicwebappTech === 'Other' && (
                          <>
                            <input
                              placeholder="Please Specify"
                              value={values.dynamicwebappTechOther}
                              className={`input-field ${
                                errors.dynamicwebappTechOther && touched.dynamicwebappTechOther ? 'error' : ''
                              }`}
                              onChange={(e) => setFieldValue('dynamicwebappTechOther', e.target.value)}
                            />
                            <span className="error-text">
                              {errors.dynamicwebappTechOther && touched.dynamicwebappTechOther && (
                                <>{errors.dynamicwebappTechOther}</>
                              )}
                            </span>
                          </>
                        )}
                      </>,
                      setFieldValue,
                      values.dynamicwebappTech
                    )}
                  </div>
                </>
              )}
              {values.webappType === 'Static' && (
                <>
                  {renderTitle('Which technology would you like to use?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      'PHP',
                      'PHP',
                      setFieldValue,
                      values.staticwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      'Laravel',
                      'Laravel',
                      setFieldValue,
                      values.staticwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      'Codeigniter',
                      'Codeigniter',
                      setFieldValue,
                      values.staticwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      'WordPress',
                      'WordPress',
                      setFieldValue,
                      values.staticwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      "I don't Know",
                      "I don't Know",
                      setFieldValue,
                      values.staticwebappTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'staticwebappTech',
                      'Other',
                      <>
                        <p>Other</p>
                        {values.staticwebappTech === 'Other' && (
                          <>
                            <input
                              placeholder="Please Specify"
                              value={values.staticwebappTechOther}
                              className={`input-field ${
                                errors.staticwebappTechOther && touched.staticwebappTechOther ? 'error' : ''
                              }`}
                              onChange={(e) => setFieldValue('staticwebappTechOther', e.target.value)}
                            />
                            <span className="error-text">
                              {errors.staticwebappTechOther && touched.staticwebappTechOther && (
                                <>{errors.staticwebappTechOther}</>
                              )}
                            </span>
                          </>
                        )}
                      </>,
                      setFieldValue,
                      values.staticwebappTech
                    )}
                  </div>
                </>
              )}
              {values.dynamicwebappTech !== '' &&
                (values.dynamicwebappTech !== 'Other'
                  ? designOptions(setFieldValue, values)
                  : values.dynamicwebappTechOther !== '' && designOptions(setFieldValue, values))}
              {values.staticwebappTech !== '' &&
                (values.staticwebappTech !== 'Other'
                  ? designOptions(setFieldValue, values)
                  : values.staticwebappTechOther !== '' && designOptions(setFieldValue, values))}
              {values.webdesignOpt !== '' && (
                <>
                  {renderTitle('Payment integration required?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-6',
                      'paymentIntReq',
                      'Yes, Payment integration is required',
                      'Yes, Payment integration is required',
                      setFieldValue,
                      values.paymentIntReq
                    )}
                    {renderOptCard(
                      'col-lg-6',
                      'paymentIntReq',
                      'No, Payment integration is not required',
                      'No, Payment integration is not required',
                      setFieldValue,
                      values.paymentIntReq
                    )}
                  </div>
                </>
              )}
              {values.newProjecttype === 'IOS' && (
                <>
                  {renderTitle('What device will the app be used on?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-2', 'iosDevice', 'iPhone', 'iPhone', setFieldValue, values.iosDevice)}
                    {renderOptCard('col-lg-2', 'iosDevice', 'iPad', 'iPad', setFieldValue, values.iosDevice)}
                    {renderOptCard('col-lg-2', 'iosDevice', 'Both', 'Both', setFieldValue, values.iosDevice)}
                    {values.oldnew === 'Creating A New Project' &&
                      renderOptCard('col-lg-2', 'iosDevice', 'Not Sure', 'Not Sure', setFieldValue, values.iosDevice)}
                  </div>
                </>
              )}
              {(values.newProjecttype === 'Android' ||
                values.newProjecttype === 'Both' ||
                values.newProjecttype === 'Not Sure') && (
                <>
                  {renderTitle('What device will the app be used on?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-2', 'androidDevice', 'Phone', 'Phone', setFieldValue, values.androidDevice)}
                    {renderOptCard(
                      'col-lg-2',
                      'androidDevice',
                      'Tablet',
                      'Tablet',
                      setFieldValue,
                      values.androidDevice
                    )}
                    {renderOptCard('col-lg-2', 'androidDevice', 'Both', 'Both', setFieldValue, values.androidDevice)}
                    {values.oldnew === 'Creating A New Project' &&
                      renderOptCard(
                        'col-lg-2',
                        'androidDevice',
                        'Not Sure',
                        'Not Sure',
                        setFieldValue,
                        values.androidDevice
                      )}
                  </div>
                </>
              )}
              {values.iosDevice !== '' && values.oldnew === 'Creating An Old Project' && (
                <>
                  {renderTitle('What technology used to develop the app?')}
                  <div className="row align-items-center">
                    {renderOptCard('col-lg-3', 'iosOldTech', 'Swift', 'Swift', setFieldValue, values.iosOldTech)}
                    {renderOptCard(
                      'col-lg-3',
                      'iosOldTech',
                      'Objective C',
                      'Objective C',
                      setFieldValue,
                      values.iosOldTech
                    )}
                    {renderOptCard('col-lg-3', 'iosOldTech', 'Xamarin', 'Xamarin', setFieldValue, values.iosOldTech)}
                    {renderOptCard('col-lg-3', 'iosOldTech', 'Ionic', 'Ionic', setFieldValue, values.iosOldTech)}
                    {renderOptCard('col-lg-3', 'iosOldTech', 'Flutter', 'Flutter', setFieldValue, values.iosOldTech)}
                    {renderOptCard(
                      'col-lg-3',
                      'iosOldTech',
                      'React Native',
                      'React Native',
                      setFieldValue,
                      values.iosOldTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'iosOldTech',
                      "I don't know",
                      "I don't know",
                      setFieldValue,
                      values.iosOldTech
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'iosOldTech',
                      'Other',
                      <>
                        <p>Other</p>
                        {values.iosOldTech === 'Other' && (
                          <>
                            <input
                              placeholder="Please Specify"
                              value={values.otheroldTech}
                              className={`input-field ${errors.otheroldTech && touched.otheroldTech ? 'error' : ''}`}
                              onChange={(e) => setFieldValue('otheroldTech', e.target.value)}
                            />
                            <span className="error-text">
                              {errors.otheroldTech && touched.otheroldTech && <>{errors.otheroldTech}</>}
                            </span>
                          </>
                        )}
                      </>,
                      setFieldValue,
                      values.iosOldTech
                    )}
                  </div>
                </>
              )}
              {values.oldnew === 'Creating An Old Project' &&
                values.iosOldTech !== '' &&
                (values.iosOldTech !== 'Other'
                  ? renderWhatWillAppUsed(setFieldValue, values, errors, touched)
                  : values.otheroldTech !== '' && renderWhatWillAppUsed(setFieldValue, values, errors, touched))}
              {(values.iosDevice !== '' || values.androidDevice !== '') &&
                values.oldnew === 'Creating A New Project' && (
                  <>
                    {renderTitle('Do you need us to design the app?')}
                    {renderDes(
                      `Designing the app requires producing UI designs on Photoshop, Sketch, XD or Figma. If you already have designs you can share with the team.`
                    )}
                    <div className="row align-items-center">
                      {renderOptCard(
                        'col-lg-4',
                        'iphoneDesign',
                        'Need to design an app',
                        'Need to design an app',
                        setFieldValue,
                        values.iphoneDesign
                      )}
                      {renderOptCard(
                        'col-lg-4',
                        'iphoneDesign',
                        'No Need to design an app',
                        'No Need to design an app',
                        setFieldValue,
                        values.iphoneDesign
                      )}
                    </div>
                  </>
                )}
              {(values.iphoneDesign !== '' || values.iosOldTech !== '') &&
                values.oldnew === 'Creating A New Project' &&
                renderWhatWillAppUsed(setFieldValue, values, errors, touched)}
              {values.appusage !== '' &&
                values.oldnew === 'Creating A New Project' &&
                (values.appusage !== 'Other'
                  ? renderTypeOfLogin(setFieldValue, values)
                  : values.otherUsage !== '' && renderTypeOfLogin(setFieldValue, values))}
              {values.appusage !== '' &&
                values.oldnew === 'Creating An Old Project' &&
                (values.appusage !== 'Other'
                  ? renderBriefDes(setFieldValue, values)
                  : values.otherUsage !== '' && renderBriefDes(setFieldValue, values, errors, touched))}

              {values.typeOfLogin !== '' && (
                <>
                  {renderTitle('Does the app need a chat feature?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-5',
                      'needChatFeature',
                      'Yes, the app needs a chat feature',
                      'Yes, the app needs a chat feature',
                      setFieldValue,
                      values.needChatFeature
                    )}
                    {renderOptCard(
                      'col-lg-5',
                      'needChatFeature',
                      'No, the app does not require a chat feature',
                      'No, the app does not require a chat feature',
                      setFieldValue,
                      values.needChatFeature
                    )}
                  </div>
                </>
              )}
              {values.needChatFeature !== '' && (
                <>
                  {renderTitle('Do you require an admin panel for app?')}
                  {renderDes(
                    'Will your app need to access data from a website, or use content management systems to manage data from the backend.'
                  )}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-5',
                      'needAdminPanel',
                      'Yes, Admin panel is required',
                      'Yes, Admin panel is required',
                      setFieldValue,
                      values.needAdminPanel
                    )}
                    {renderOptCard(
                      'col-lg-5',
                      'needAdminPanel',
                      'No, Admin panel is not required',
                      'No, Admin panel is not required',
                      setFieldValue,
                      values.needAdminPanel
                    )}
                  </div>
                </>
              )}
              {(values.needAdminPanel !== '' || values.paymentIntReq !== '') && (
                <>
                  {renderTitle('Is GPS integration required?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-5',
                      'needGPS',
                      'Yes, GPS integration is required',
                      'Yes, GPS integration is required',
                      setFieldValue,
                      values.needGPS
                    )}
                    {renderOptCard(
                      'col-lg-5',
                      'needGPS',
                      'No, GPS integration is not required',
                      'No, GPS integration is not required',
                      setFieldValue,
                      values.needGPS
                    )}
                  </div>
                </>
              )}
              {values.needGPS !== '' && (
                <>
                  {renderTitle('How will you earn from the app?')}
                  <div className="row align-items-center">
                    {renderOptCard(
                      'col-lg-3',
                      'howearn',
                      'In-App Purchase',
                      'In-App Purchase',
                      setFieldValue,
                      values.howearn
                    )}
                    {renderOptCard(
                      'col-lg-3',
                      'howearn',
                      'Sell Products',
                      'Sell Products',
                      setFieldValue,
                      values.howearn
                    )}
                    {renderOptCard('col-lg-3', 'howearn', 'Advertise', 'Advertise', setFieldValue, values.howearn)}
                    {renderOptCard(
                      'col-lg-3',
                      'howearn',
                      'Subscription',
                      'Subscription',
                      setFieldValue,
                      values.howearn
                    )}
                    {renderOptCard('col-lg-3', 'howearn', 'Paid App', 'Paid App', setFieldValue, values.howearn)}
                    {renderOptCard('col-lg-3', 'howearn', 'None', 'None', setFieldValue, values.howearn)}
                  </div>
                </>
              )}
              {((values.oldnew === 'Creating An Old Project' && values.briefDesAdded === true) ||
                (values.oldnew === 'Creating A New Project' && values.howearn !== '') ||
                (values.projectKind === 'Website Development' && values.needGPS !== '') ||
                (values.projectKind === 'Game Development' && values.gameEarn !== '')) && (
                <>
                  {renderTitle('Fill in the Below Form to Get Estimation')}
                  <div className="row align-items-center gx-4 gy-3">
                    <div className="col-lg-6">
                      <p className="label">Full Name</p>
                      <input
                        placeholder="Your Full Name"
                        value={values.name}
                        className={`input-field ${errors.name && touched.name ? 'error' : ''}`}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <span className="error-text">{errors.name && touched.name && <>{errors.name}</>}</span>
                    </div>
                    <div className="col-lg-6">
                      <p className="label">Email</p>
                      <input
                        type="email"
                        placeholder="Your Email"
                        value={values.email}
                        className={`input-field ${errors.email && touched.email ? 'error' : ''}`}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <span className="error-text">{errors.email && touched.email && <>{errors.email}</>}</span>
                    </div>
                    <div className="col-lg-6">
                      <p className="label">Phone Number</p>
                      <PhoneInput
                        // countryCallingCodeEditable={false}
                        placeholder="Your Phone Number"
                        value={values.phone}
                        onChange={(value) => {
                          console.log(value, 'value');
                          setFieldValue('phone', value);
                        }}
                      />
                      <span className="error-text">{errors.phone && touched.phone && <>{errors.phone}</>}</span>
                    </div>
                    <div className="col-lg-6">
                      <p className="label">Company Name</p>
                      <input
                        placeholder="Your Company Name"
                        value={values.companyName}
                        className={`input-field ${errors.companyName && touched.companyName ? 'error' : ''}`}
                        onChange={(e) => setFieldValue('companyName', e.target.value)}
                      />
                      <span className="error-text">
                        {errors.companyName && touched.companyName && <>{errors.companyName}</>}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <p className="label">Website URL</p>
                      <input
                        // type="url"
                        placeholder="Your Website URL"
                        value={values.website}
                        className={`input-field ${errors.website && touched.website ? 'error' : ''}`}
                        onChange={(e) => setFieldValue('website', e.target.value)}
                      />
                      <span className="error-text">{errors.website && touched.website && <>{errors.website}</>}</span>
                    </div>
                    <div className="col-lg-12">
                      <p className="label">Description</p>
                      <textarea
                        rows="4"
                        maxLength={1000}
                        placeholder="Write your project description here..."
                        value={values.description}
                        className={`input-field ${errors.description && touched.description ? 'error' : ''}`}
                        onChange={(e) => setFieldValue('description', e.target.value)}
                      />
                      <span className="error-text">
                        {errors.description && touched.description && <>{errors.description}</>}
                      </span>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <button className="blue-bg-button" type="submit" disabled={disabled}>
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default EstimateProject;
