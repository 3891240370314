import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import Layout from '../components/layout/Layout';
import CenteredTitle from '../components/UIComponents/CenteredTitle';
import OneSideTitle from '../components/UIComponents/OneSideTitle';
import { assetImages } from '../constant/images';
import { scrollToTop } from '../common/ScrollToTop';

const ContactUs = () => {
  const { addToast } = useToasts();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    scrollToTop();
  }, []);

  const onsubmit = (values, resetForm) => {
    setDisabled(true);
    axios
      .post('https://cybertronsoftech.ca/api/sendEmail.php', { ...values })
      .then((response) => {
        setDisabled(false);
        resetForm();
        addToast('Thank you for reaching out to us!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: '1500',
        });
        console.log(response, 'response');
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };
  return (
    <Layout>
      <section className="about-us-banner">
        <div className="container-lg">
          <div className={`row align-items-center`}>
            <div className="col-lg-6">
              <OneSideTitle
                title="Contact Us"
                des={
                  <>
                    We give like-minded businesses more time to follow their passion by building real human
                    relationships through results driven IT Services & Digital Marketing. So reach out today, and
                    discover how Cybertron Softech can help you.
                  </>
                }
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={assetImages.serviceBanner} alt="serviceBanner" className="bg-vector" />
              <img
                src={assetImages.contactbanner}
                alt="whatwedo"
                className="img-fluid sec-image"
                style={{ maxHeight: '18rem' }}
              />
            </div>
          </div>
        </div>
      </section>
      <CenteredTitle title="Get In Touch" des={<>Reach out to us at any time and we’ll be happy to hear you.</>} />
      <div className="container-lg">
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            name: '',
            phone: '',
            subject: '',
            message: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Please enter valid Email').required('Email is required').nullable(),
            name: Yup.string()
              .min(3, 'Name must be at least 3 characters.')
              .max(50, 'Maximum limit of characters allowed is 50.')
              .required('Name is required.')
              .matches(new RegExp('^[a-zA-Z\\-_ ]*$'), 'Name is not valid.')
              .nullable(),
            subject: Yup.string()
              .min(3, 'Subject must be at least 3 characters.')
              .max(100, 'Maximum limit of characters allowed is 100.')
              .required('Subject is required.')
              .nullable(),
            phone: Yup.string()
              .required('Phone number is required.')
              .test(`phone`, 'Please enter a valid Phone number.', (value) => {
                if (value) {
                  if (isPossiblePhoneNumber(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }
              })
              .nullable(),
          })}
          onSubmit={(values, { resetForm }) => onsubmit(values, resetForm)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form className="contact-form">
              <div className="row align-items-center gx-4 gy-3">
                <div className="col-lg-6">
                  <p className="label">Full Name</p>
                  <input
                    placeholder="Enter your name"
                    value={values.name}
                    className={`input-field ${errors.name && touched.name ? 'error' : ''}`}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                  <span className="error-text">{errors.name && touched.name && <>{errors.name}</>}</span>
                </div>
                <div className="col-lg-6">
                  <p className="label">Email</p>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={values.email}
                    className={`input-field ${errors.email && touched.email ? 'error' : ''}`}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  <span className="error-text">{errors.email && touched.email && <>{errors.email}</>}</span>
                </div>
                <div className="col-lg-6">
                  <p className="label">Phone Number</p>
                  <PhoneInput
                    // countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    value={values.phone}
                    onChange={(value) => {
                      console.log(value, 'value');
                      setFieldValue('phone', value);
                    }}
                  />
                  <span className="error-text">{errors.phone && touched.phone && <>{errors.phone}</>}</span>
                </div>
                <div className="col-lg-6">
                  <p className="label">Subject</p>
                  <input
                    placeholder="Enter subject"
                    value={values.subject}
                    className={`input-field ${errors.subject && touched.subject ? 'error' : ''}`}
                    onChange={(e) => setFieldValue('subject', e.target.value)}
                  />
                  <span className="error-text">{errors.subject && touched.subject && <>{errors.subject}</>}</span>
                </div>
                <div className="col-lg-12">
                  <p className="label">Your Message</p>
                  <textarea
                    rows="4"
                    maxLength={1000}
                    placeholder="Your Message"
                    value={values.message}
                    className={`input-field ${errors.message && touched.message ? 'error' : ''}`}
                    onChange={(e) => setFieldValue('message', e.target.value)}
                  />
                  <span className="error-text">{errors.message && touched.message && <>{errors.message}</>}</span>
                </div>
              </div>
              <button className="blue-bg-button" type="submit" disabled={disabled}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default ContactUs;
