import React from 'react';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';

const UltimateExp = () => {
  return (
    <section className="ultimate-exp">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img src={assetImages.ultimateexp} alt="whatwedo" className="img-fluid sec-image" />
          </div>
          <div className="col-lg-6">
            <OneSideTitle
              title={'Ultimate Experience with Great Story, Vision & Values'}
              des="  We have been offering world-class customized software solutions for startups, SMEs and brands to help them
              provide an amazing experience and expand their businesses overseas to build brand recognition in the
              world."
            />
            <div className="d-flex justify-content-end justify-content-lg-center">
              <Link to="/about-us" onClick={scrollToTop}>
                <button className="blue-bg-button">Meet Our Team</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UltimateExp;
