import React from 'react';
import Newsletter from '../components/commonComponents/Newsletter';
import ClientTestimonials from '../components/home/ClientTestimonials';
import EstimateCost from '../components/home/EstimateCost';
// import ExploreCaseStudies from '../components/home/ExploreCaseStudies';
import HomeBanner from '../components/home/HomeBanner';
import IndustryExpertise from '../components/home/IndustryExpertise';
import LatestProjects from '../components/home/LatestProjects';
import OurAchivements from '../components/home/OurAchivements';
import OurCertificate from '../components/home/OurCertificate';
import OurMilestone from '../components/home/OurMilestone';
import PlatformCarousal from '../components/home/PlatformCarousal';
import TechnologyExp from '../components/home/TechnologyExp';
import UltimateExp from '../components/home/UltimateExp';
import WhatWeDo from '../components/home/WhatWeDo';
import Layout from '../components/layout/Layout';

const Home = () => {
  return (
    <Layout>
      <HomeBanner />
      <PlatformCarousal />
      <WhatWeDo />
      <OurMilestone />
      <IndustryExpertise />
      <UltimateExp />
      <TechnologyExp />
      <EstimateCost />
      {/* <ExploreCaseStudies /> */}
      <LatestProjects />
      <ClientTestimonials />
      <OurAchivements />
      <OurCertificate />
      <Newsletter />
    </Layout>
  );
};

export default Home;
