import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const AndroidAppDev = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Techs To Uplift Android App Expertness',
      des: (
        <>
          Building an Android app to fit best on every device’s screen, giving smooth user flows and extending best
          protection with Android security features is our ardour.
          <br />
          <br />
          As an android app development company we understand our responsibility and hence also give creative inputs, as
          we guide you during the development process to get a clear picture of how your purpose will be met. It's our
          keenness to be on the edge with every update of android technologies to provide commendable android app
          development services. We do best to develop smooth activites on apps and give fluent user flows while
          protecting it with android security features.
        </>
      ),
      listItems: [
        'Integrated and Enigmatic User Experience',
        'Adaptability of Apps to Upgraded Versions',
        'Brilliant Portfolio of Android Apps',
        'Experienced Technical Team',
        'Excellence in Design, Development and Deployment',
        'Thoroughly Tested and Bug-free Apps',
        'Working on Timeline',
        'Confidentiality of Project',
      ],
      image: assetImages.androidapp1,
    },
    {
      id: 2,
      title: 'Highly accessible and agreeable device functionality',
      des: 'A systematic Native Android App Development that takes advantage of the latest technology available and attains the full-fledged functionality of the Android mobile devices is a boon to the Android user community. Our team has a vast experience of building Android apps that work fluidly on the native platform.',
      listItems: [
        'Offline Access Possible',
        'Advantage of Device features Attained',
        'Maximum Performance',
        'Deployment on Single Platform',
        'Finest User Experience',
      ],
      image: assetImages.androidapp2,
    },
    {
      id: 3,
      title: 'Great Business Opportunities',
      des: 'You can reach massive Android users by porting your existing app to the Android platform irrespective of the platform it was built for. This can immensely increase your business opportunities in the Android community around the globe. We incorporate definitive and operable Android UI/UX elements to give an enhanced user experience on the Android platform to the users.',
      listItems: [
        'Cross-Platform Accessibility',
        'App Upgradation',
        'Caters Android’s Large User-base',
        'Increased Business Opportunities',
        'More Revenue',
      ],
      image: assetImages.androidapp3,
    },
    {
      id: 4,
      title: 'Suitable and Functional Android Apps',
      des: 'Due to the immense popularity of Android, business-minded people are diverting themselves in building niche android apps. When an app is made it has to impart value for it is made. An app has to be scalable, engaging and purposeful. Taking these requirements into the account, we build apps that are custom-made for suitable to your business and which are remarkably functional.',
      listItems: [
        'User-friendly Experience',
        'Business Compatible Features',
        'Productive and Rewarding Apps',
        'Tailor-made apps to standout',
        'Highly-secured Apps',
      ],
      image: assetImages.androidapp4,
    },
    {
      id: 5,
      title: 'Refreshed And Reasonable Apps All The Time',
      des: 'Maintaining your Android Applications is equally important after making it. When you want to add additional security features, remove expired offers and add new content or functionality on your app or solve the bugs or errors, Android App Maintenance contract proves to be worthwhile for making your app fresh and feasible.',
      listItems: [
        'Improved App Efficiency',
        'App testing',
        'Adding and Editing of new and old data',
        'Performance Monitoring',
        'Solves Bug-related Issues',
      ],
      image: assetImages.androidapp5,
    },
    {
      id: 6,
      title: 'Close Insights About Android Apps',
      des: 'Get close insights about Android Apps by availing our Android App Consultation Service. We have business experts who understand your idea, guide you with their proficiency and experience on how your app can be better and competitive in the market and make profitable business plans for you.',
      listItems: [
        'Idea Interpretation',
        'Startup Android App Consultation',
        'Market Risk Counselling',
        'Business-related Consultation',
        'App Budget Consultation',
      ],
      image: assetImages.androidapp6,
    },
    {
      id: 7,
      title: 'A Crackerjack User-Base',
      des: 'It takes appealing UI/UX design to create a crackerjack user-base for your apps. From flexible layouts to effortless navigation, we think user first before we create futuristic Android apps.',
      image: assetImages.androidapp7,
    },
    {
      id: 8,
      title: 'Propel The Presence',
      des: 'Go, meet the world and propel your presence in the app store first and then on Android devices with our mobile app benefits to capture the large number of mobile addicts today.',
      image: assetImages.androidapp8,
    },
    {
      id: 9,
      title: 'Super-Structured Services',
      des: 'Strategic planning, well-synced development methodology, fully- confidential and secured communication threads and undivided attention to the assigned technical team keep you aware of your project and deliver your product on time.',
      image: assetImages.androidapp9,
    },
    {
      id: 10,
      title: 'Fasten Your Fair Place On Android',
      des: 'Business is a multiplex of complexities which is made easier in apps. Our Android App Development makes the user feel that he’s the captain of the ship and he is sailing smoothly with the simplicity of app steerage',
      image: assetImages.androidapp10,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Who will take care of my project during development process?',
      des: 'A project leader will be assigned to you, who will keep you updated about the development, save all the communication and passes your requirements and approvals to the team.',
    },
    {
      id: 2,
      title: '2. What are the fees  to create developer account on Google?',
      des: 'For Android developer account, you will need to pay $25 USD/ one time',
    },
    {
      id: 3,
      title: '3. can I get changes done in the app after it is launched?',
      des: 'Yes, minor fixes, bug fixing, and maintenance can be done after it is launched.',
    },
    {
      id: 4,
      title: '4. Will you help us to upload the app after development ?',
      des: 'Yes, we will upload the app in the Google play store after the development.',
    },
    {
      id: 5,
      title: '5. Are there possibilities of my app getting rejected by Play Store?',
      des: 'Yes, there are such possibilities but the company takes responsibility to get it approved.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Android Application Development Company For Friendly and Futuristic Apps"
        description={
          <>
            Android dominates 87% of the market share, meaning every 9 of 10 mobile users are on Android OS. Who will
            not want to be interested in Android Application Development to cater to such a high cast of Android users
            for their business interest? We are an Android mobile app development company in India continuously working
            to create updated and high-performance android applications. Android Mobile Application Development Services
            from 360 Degree that lead to result-driven Android apps are – Android Phone App Development, Android OS
            Customization, Android App Redesign, Virtual Reality App Development, Android Wear App Development and more.
            <br />
            <br />
            <b>Build your android app with us</b>
            <br />
            Android apps that we build are capable of doing your business, becoming a proficient accelerator for all
            operations and helping in getting connected to the greatest population of users on the most popular Android
            OS worldwide.
          </>
        }
        serviceImg={assetImages.androidappdev}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default AndroidAppDev;
