import React from 'react';

const CenteredTitle = ({ title, des, disablemb }) => {
  return (
    <div className={`container-lg ${disablemb ? '' : 'mb-5'}`}>
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <div className="title-wrapper justify-content-center">
            {/* <div className="orange-line"></div> */}
            <h2>{title}</h2>
          </div>
          <p className="des-text text-center">{des}</p>
        </div>
      </div>
    </div>
  );
};

export default CenteredTitle;
