import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import SerKeyBenefits from '../../components/services/SerKeyBenefits';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const AwsCloud = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'What is AWS Cloud Consulting',
      des: (
        <>
          Adopting with AWS Cloud has not only given businesses an advantage but have substantially reduced complexities
          involved in migrating workload to more efficient strong base. Shifting to cloud means to raise new servers in
          minutes which in turn leads to shorter downtimes , increased global reach, more innovation etc. Because of the
          specialized platform developed by Cloud to deploy their services within minutes, reduced load time and helps
          you to work in any traffic situation.
        </>
      ),
      image: assetImages.awssec,
    },
  ];
  const benefits = [
    {
      id: 1,
      title: <>SIMPLIFIED</>,
      des: <>Cloud services are one time install as a platform</>,
    },
    {
      id: 2,
      title: <>SECURE</>,
      des: (
        <>
          Highly secure data as Amazon holds many security concerned certificates like PCI, HIPAA, Sarbanes-Oxley, and
          ISO.
        </>
      ),
    },
    {
      id: 3,
      title: <>COST EFFECTIVE</>,
      des: <>Cloud solutions costs less than on premise solution.</>,
    },
    {
      id: 4,
      title: <>HARDWARE</>,
      des: <>No hardware installation is required, so it takes no time to install.</>,
    },
    {
      id: 5,
      title: <>EFFECTIVE</>,
      des: <>Cloud services can work without falling apart.</>,
    },
    {
      id: 6,
      title: <>SCALABILITY</>,
      des: (
        <>
          Cloud services are scalable as if we need to increase its capacity, we need just a click and it continues
          providing that service.
        </>
      ),
    },
    {
      id: 7,
      title: <>RELIABILITY</>,
      des: <>Offer Services without any failure.</>,
    },
    {
      id: 8,
      title: <>PROFITABLE</>,
      des: (
        <>
          Cloud services, being cheap and reliable as they increase sales and thus which further increases
          profitability.
        </>
      ),
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="AWS Cloud Consulting Service"
        description={
          <>
            AWS is an Amazon web services with cloud consulting providing with developers and IT departments to
            understand the infrastructure services such as database storage, networking, deliverance and application
            deployment. We help you navigate the complete AWS pack of powerful cloud services. With our skilled and
            well-knowledged experts of what it means to work with big institutions and play with their sensitive data.
            We have helped many enterprises to achieve their business goals in AWS. Adopting AWS is a big thing and
            requires patience to successfully implement it.
          </>
        }
        serviceImg={assetImages.awsBanner}
        maxHeight="18rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg
        servicesWeProvide={servicesWeProvide}
        whyChoose={true}
        des="Cybertron Softech has Experts of technologies, who not only help reduce the migration costs, but also maintain the efficiency of the application well. Seamless migration remains the motto of the professional experts that helps out in maintaining the fidelity of the business logic."
      />
      <SerKeyBenefits title="Key Benefits of AWS Cloud Consulting" benefits={benefits} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default AwsCloud;
