import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import CenteredTitle from '../../components/UIComponents/CenteredTitle';
import { assetImages } from '../../constant/images';

const CodeigniterDev = () => {
  const features = [
    {
      id: 1,
      title: 'ADVANCE FEATURES',
      des: 'Serve you with additional functionalities to make an astounding site.',
      image: assetImages.codeigniter1,
    },
    {
      id: 2,
      title: 'HIGHLY SCALABLE',
      des: 'Highly adaptable application architecture and development.',
      image: assetImages.highlyScalable,
    },
    {
      id: 3,
      title: 'COMPATIBLE',
      des: 'Broad compatibility with a variety of PHP versions and configurations.',
      image: assetImages.codeigniter2,
    },
    {
      id: 4,
      title: 'MVC ARCHITECTURE',
      des: 'Follows MVC structure and pattern with all ease.',
      image: assetImages.codeigniter3,
    },
    {
      id: 6,
      title: 'FLEXIBLE URL DIRECTING',
      des: 'User friendly search engine URL.',
      image: assetImages.codeigniter4,
    },
    {
      id: 7,
      title: 'EXCEPTIONAL PERFORMANCE',
      des: 'Has outstanding performance and efficiency level.',
      image: assetImages.codeigniter5,
    },
    {
      id: 5,
      title: 'HIGHLY SECURE',
      des: 'Comes with built-in protection against CSRF and XSS attacks.',
      image: assetImages.codeigniter6,
    },
    {
      id: 8,
      title: 'CLEAR DOCUMENTATION',
      des: 'Documentation is systematic as well as easy to follow.',
      image: assetImages.codeigniter7,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Codeigniter Development Services"
        description={
          <>
            At Cybertron Softech Development, CodeIgniter Development Company, our expert CodeIgniter Developers can
            avail you of all the powerful and exceptional features of PHP framework for result oriented web application
            development. We are a leading Offshore CodeIgniter Development Company and offer various CodeIgniter
            Development services which includes hire dedicated resources or fixed cost development. <br />
            <br />
            We create full-featured web applications with the help of CodeIgniter framework and offer clients unique
            solutions with quality work. We provide clients a propelled CMS framework that empowers them to run various
            sites while utilizing a solitary back-end code motor and this is made conceivable with the assistance of
            CodeIgniter structure.
          </>
        }
        serviceImg={assetImages.codeigniterbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="What Is Codeigniter?"
        description="CodeIgniter is one of the most popular and open source PHP application development frameworks in the world of web development. It is based on the MVC development pattern that helps to develop dynamic and instinctual websites. Compared to other top frameworks CodeIgniter is fastest, lightest and behaves less like a framework and has a highly interactive interface with development friendly tools. CodeIgniter has in-built libraries and reduces the time involved in writing complex codes from scratch."
        serviceImg={assetImages.codeignitersec}
        maxHeight="27rem"
      />
      <CenteredTitle
        title="Why Choose Us"
        des="Our experts with incredible knowledge and years of skill guarantee that your business achieves an unrivaled position and sets another level of accomplishment. Cybertron Softech is the place where you can find the best CodeIgniter development services, integration and customization tailored as per your requirements."
      />
      <ServiceFeatures features={features} title="Key Benefits Of Codeigniter Development" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};
export default CodeigniterDev;
