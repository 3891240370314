import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const IphoneAppDev = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Iphone App Development Solutions',
      des: (
        <>
          What’s the fun if idea doesn’t happen?
          <br />
          <br />
          If your idea is already a big business, if your idea is a baby business, if you ’re a Columbus and are in
          search of a right solution, or if you’re someone who only has an idea, we would love to create an app for you.
        </>
      ),
      image: assetImages.iphoneapp1,
    },
    {
      id: 2,
      title: 'App Flamboyancy',
      des: (
        <>
          These Tricky Techs Make Your Apps
          <br />
          <br />
          We work with these complicated things to keep your app users away from the thought of deleting any extra thing
          from their phone.
        </>
      ),
      image: assetImages.iphoneapp2,
      listItems: [
        'Inescapable User Experience',
        'Seel in Your Picks',
        'The excitement lies in making people’s lives easy and bringing everything dear and useful closer to them. Our reliable and comprehensive iPhone Application Development Services have imparted benefits to many industry verticals to bring the next-level app experience.',
        'Quick response',
        'User-focussed approach',
        'Client-centric solutions',
        'Security & Confidentiality',
        'On time & in budget',
        'High-scalability & Flexibility',
        'Overlooking Experience in industry',
        'Global Profile',
      ],
    },
    {
      id: 3,
      title: 'Users First, Business Robust',
      des: 'Our undirected focus on UI/UX iPhone Application Design creates avenues for the users to navigate the app on simple finger touches. The attention-gripping UI/UX designs give a feel of personalized connectivity to the users and substantial coding from our iOS developers takes the perfection ahead.',
      listItems: [
        'Customized UI/UX design',
        'Prioritise the tab navigation',
        'Add value to app development',
        'Enhance user experience',
        'Visually represent the brand',
        'Analyse the finest functionality and usability',
      ],
      image: assetImages.iphoneapp3,
    },
    {
      id: 4,
      title: 'Reliable Technical Team Ready',
      des: 'Maintaining apps is of significance as they have become an inseparable part of everybody’s daily lives. Once an iPhone App is developed, regular checkouts are considered to ensure its smooth working condition with necessary alterations or up-gradation. Our technically expert team handles everything actively to stop the app from going on a vacation.',
      listItems: [
        'Solves bugs and errors in codes',
        'Transcends the designing aspect of an app',
        'Upgrades app to cope up with new versions of iOS',
        'Online reviewing and testing of apps',
        'App optimization and appendance of app elements',
      ],
      image: assetImages.iphoneapp4,
    },
    {
      id: 5,
      title: 'Valuable, Viable, Versatile',
      des: 'These are Web apps given in encased nativity, Hybrid iPhone App Development can work for multiple app stores. Hybrid apps have the best performance with combinational features of both native and web applications to work best for multiple iOS platforms. It provides the best connectivity even when the device is not connected.',
      listItems: [
        'Cost-effective',
        'Good coordination with device’s system',
        'Adding and Editing of new and old data',
        'Cross-Platform User Interface',
        'Access to Multiple Mobile Platforms with sole code',
        'System Integration with web-based program',
      ],
      image: assetImages.iphoneapp5,
    },
    {
      id: 6,
      title: 'Appealing Seams, Easy Transitions',
      des: 'Building best native apps that have full access to offline possibilities, working excellently with the hardware and co-working with the operating system is our forte. Native iPhone apps are able to take the maximum advantage of iOS features and give maximum usability to the users.',
      listItems: [
        'High Performance',
        'Benefit of Thorough Device Features',
        'Receptive Data Synchronization/li>',
        'Remarkable User Experience',
        'Push functionalities',
      ],
      image: assetImages.androidapp6,
    },
    {
      id: 7,
      title: 'All Of Your Business',
      des: 'Our business experts provide earnest insights about your project and relieve you from the stress factors of new ideas or any existing business project which is in the phase of entering the app development process. The designer and developer team come together to produce a business plan that can satisfy every question you have in mind.',
      listItems: [
        'Analysis of Idea',
        'Prospecting Market Trends',
        'Mobile Consulting Services',
        'Startup and Enterprise Business requirement consultation',
        'Design, Technology, Product-based iPhone solutions',
        'Budget guidance and advisement of the risk of new market',
      ],
      image: assetImages.iphoneapp7,
    },
    {
      id: 8,
      title: 'Benefits Of Iphone App Development',
      des: (
        <>
          Our passionate team of iOS developers, designers and QA engineers are pivots of the injected benefits. All the
          pluses come along with our iPhone mobile app development project.
          <br />
          <br />
          iPhone App Development topper From a user’s end or industrial perspective, our iPhone app provides enhanced
          experience with greater scrolling advantages and fewer clicks.
        </>
      ),
      image: assetImages.iphoneapp8,
    },
    {
      id: 9,
      title: 'Prevail The Profits',
      des: 'Being the frontier of iPhone Apps, we offer highly functional and indulging app solutions which earn you bountiful profits (or best ROIs) at a competitive cost.',
      image: assetImages.mobileapp3,
    },
    {
      id: 10,
      title: 'Meet The Stature',
      des: 'Our ultramodern approach and ceaseless operative features facilitate the stature of iPhone users which they are already enjoying globally.',
      image: assetImages.androidapp10,
    },
    {
      id: 11,
      title: 'Win Over Compeers',
      des: 'Maintaining your project incognito is our priority and providing an extra edge of tomorrow to your iPhone today over compeers is our competence.',
      image: assetImages.iphoneapp11,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Who will take care of my project during development process?',
      des: 'A project leader will be assigned to you, who will keep you updated about the development, save all the communication and passes your requirements and approvals to the team.',
    },
    {
      id: 2,
      title: '2. can I get changes done in the app after it is launched?',
      des: 'Yes, you can. But that will cost you extra. We recommend you to identify your requirements and project it properly before the app development project commences to save both time and money.',
    },
    {
      id: 3,
      title: '3. Will you help us to upload the app after development ?',
      des: 'Absolutely, we do it cost-free but you will have to pay the minimum Apple AppStore Yearly Charges which are mandatory.',
    },
    {
      id: 4,
      title: '4. Are there possibilities of my app getting rejected by Apple?',
      des: 'Most of the time there are not. Because we follow all the guidelines, standard functionality defined by Apple and tests your app highly professionally, leaving no gateway for rejection.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Iphone Application Development Company"
        description={
          <>
            IOS smartphone is an 'apple' of the world’s eye. Knowing this fact we became an iPhone App Development
            Company in India for worldwide clients as soon as an iPhone was opened for apps. Cybertron Softech is an iOS
            App Development Company with an exhilarated and skilled team of professionals offering customized iOS app
            development services and competitive iOS solutions. iPhone Application Development services we take care of
            – iPhone app design, iPhone enterprise applications, iPhone mobile eCommerce applications, iPhone app
            consultancy and testing, iPhone app performance optimization, iPhone app marketing and more. Have a robust
            experience of supremely scalable and high utility apps to achieve elevated productivity.
          </>
        }
        serviceImg={assetImages.iphoneappdev}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default IphoneAppDev;
