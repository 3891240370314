import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Copywriting = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: <>Copywriting Research to fit your brand</>,
      des: (
        <>
          At Cybertron Softech, our content writing services team has years of experience writing for a multitude of
          businesses so they understand how to adapt to your personal brand tone and personality. That’s why when you
          choose to invest in Cybertron Softech as your content writing agency, we ensure that we clearly define what
          your brand tone is and what language you want us to use before we start. We take the time to thoroughly
          research who you are, your industry, and the audience you attract. This way we are able to create content
          that’s relevant, engaging and relatable for your businesses target audience.
        </>
      ),
      image: assetImages.copyw1,
    },
    {
      id: 2,
      title: <>Developing Your Ideal Content</>,
      des: (
        <>
          Once we’ve got all the details on your dream content, we work hard to package it up for you so that it is an
          accurate representation of your brand. Your business is unique, and so are your potential customers, so
          digital content is not a one-size-fits-all service! We are a passionate content writing company, and we love
          developing content to suit your needs, and take all the hard work out so you don’t have to.
        </>
      ),
      image: assetImages.copyw2,
    },
    {
      id: 3,
      title: <>Content designed for Distribution</>,
      des: (
        <>
          As a premium copywriter in Ontario, we’re proud of the work we do and of the clients we work with, so we do
          what it takes to get your content seen. We do this by designing your content around Search Engine
          optimization's and industry relevant terms. This way we know that we’re positioning your webpage to get as
          many hits as possible. We then repurpose your content and distribute it on pre qualified, industry relevant
          platforms to ensure you’re reaching a wider audience and connecting your business with potential clients and
          partners, whilst establishing you as a leader in your field.
          <br />
          <br />
          If you’re interested in seeing how SEO content writing services can help your business grow and gain you
          credibility, get in touch with us today and let’s chat!
        </>
      ),
      image: assetImages.copyw3,
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="Copywriting"
        description={
          <>
            We’re copywriting & content marketing evangelists. Seriously, content marketing is the future, and we think
            it’s time you consider investing in some. Here’s why – businesses with blogs receive, on average, 126% more
            conversions than businesses without them. Not only that, but businesses who upload fresh, relevant content
            on a regular basis experience 7.8X more growth than businesses who don’t invest their money or time in it.
            If that’s not enough to convince you then we think this figure will – the likelihood of someone purchasing
            your service and/or product increases 66% after they’ve read a blog on the topic. Now that I’ve piqued your
            interest, let’s talk about how we can help you create a killer content campaign.
          </>
        }
        serviceImg={assetImages.copywbanner}
        maxHeight="20rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Copywriting;
