import React from 'react';
import { Link } from 'react-router-dom';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { MdLocationOn, MdEmail, MdLocalPhone } from 'react-icons/md';
import { Location } from '../../constant/Icon';
import { scrollToTop } from '../../common/ScrollToTop';

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="footer-upper">
        <div className="container-lg">
          <Link onClick={scrollToTop} to="/">
            <div className="footer-logo">Cybertron Softech</div>
          </Link>
        </div>
        <div className="container-lg">
          <div className="row footer-items-wrapper">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="contact-title-wrapper">
                <div className="contact-item-icon-wrapper">
                  <MdLocationOn />
                </div>
                <div className="title">Location</div>
              </div>
              {/* <div className="contact-title-wrapper">
                <div className="location-icon-wrapper">
                  <Location />
                </div>
                <div className="location-link">
                  <a rel="noreferrer" href="https://goo.gl/maps/5ozJeEsLh27PppBx9" target={'_blank'}>
                    708, Naroda Business Hub Opp. Satva Green Party Plot, Naroda Ahmedabad, Gujarat, India - 382350
                  </a>
                </div>
              </div> */}
              <div className="contact-title-wrapper">
                <div className="location-icon-wrapper">
                  <Location />
                </div>
                <div className="location-link">
                  <a rel="noreferrer" href="https://goo.gl/maps/oBQs7VyVhSnHT1ePA" target={'_blank'}>
                    Ontario, Canada
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="contact-title-wrapper m-0">
                <div className="contact-item-icon-wrapper">
                  <MdEmail />
                </div>
                <div className="title">Email</div>
              </div>
              <div className="contact-title-wrapper">
                <div className="location-icon-wrapper"></div>
                <div className="location-link">
                  <a href="mailto:hello@cybertronsoftech.ca">hello@cybertronsoftech.ca</a>
                  <br />
                  <a href="mailto:support@cybertronsoftech.com">support@cybertronsoftech.com</a>
                </div>
              </div>
              <div className="contact-title-wrapper m-0">
                <div className="contact-item-icon-wrapper">
                  <MdLocalPhone />
                </div>
                <div className="title">Phone</div>
              </div>
              <div className="contact-title-wrapper">
                <div className="location-icon-wrapper" style={{ height: 'auto' }}></div>
                <div className="location-link">
                  <a href="tel:+1 (639) 999-9820">+1 (639) 999-9820</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer-logo quick-link">Quick Link</div>
              <div className="quick-links">
                <Link to="/" onClick={scrollToTop} className="mt-2">
                  Home
                </Link>
                <Link to="/about-us" onClick={scrollToTop}>
                  About Us
                </Link>
                {/* <Link to="/" onClick={scrollToTop}>
                  Case Studies
                </Link> */}
                <Link to="/portfolio" onClick={scrollToTop}>
                  Our Work Portfolio
                </Link>
                <Link to="/contact-us" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-lg">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-auto footer-bottom-left">
              <div className="row">
                {/* <div className="col-md-auto">
                  <Link to={'/'} onClick={scrollToTop}>
                    FAQs
                  </Link>
                </div> */}
                <div className="col-md-auto">
                  <Link to={'/privacy-policy'} onClick={scrollToTop}>
                    Privacy Policy
                  </Link>
                </div>
                <div className="col-md-auto">
                  <Link to={'/terms-and-conditions'} onClick={scrollToTop}>
                    Terms & Conditions
                  </Link>
                </div>
                <div className="col-md-auto">
                  Copyright <span className="text-orage">Cybertron Softech</span> © {new Date().getFullYear()}
                </div>
              </div>
            </div>
            <div className="col-md-auto footer-bottom-right">
              <div className="row g-3 justify-content-end">
                <div className="col-md-auto col-sm-auto col-xs-auto">
                  <a
                    href="https://www.facebook.com/cybertronsoftechca?mibextid=LQQJ4d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsFacebook />
                  </a>
                </div>
                <div className="col-md-auto col-sm-auto col-xs-auto">
                  <a
                    href="https://instagram.com/cybertronsoftechinc?igshid=NTc4MTIwNjQ2YQ=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram />
                  </a>
                </div>
                <div className="col-md-auto col-sm-auto col-xs-auto">
                  <a href="https://www.linkedin.com/company/cybertronsoftechinc/" target="_blank" rel="noreferrer">
                    <BsLinkedin />
                  </a>
                </div>
                <div className="col-md-auto col-sm-auto col-xs-auto">
                  <a
                    href="https://twitter.com/cybertron_llp?s=11&t=OGcKi7TkUbuQW0WH4keUaA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsTwitter />
                  </a>
                </div>
                <div className="col-md-auto col-sm-auto col-xs-auto">
                  <a href="https://www.youtube.com/@cybertronsoftechinc" target="_blank" rel="noreferrer">
                    <BsYoutube />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
