import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFeatures from '../../components/services/ServiceFeatures';
import ServiceSecTwo from '../../components/services/ServiceSecTwo';
import { assetImages } from '../../constant/images';

const AngularStack = () => {
  const features = [
    {
      id: 1,
      des: 'We have a million dollar track record in building and designing 360° front and architecture for numerous businesses and web applications. The services include layout planning and architectural design framework for a resilient, reliable UX.',
      title: 'ANGULAR JS CONSULTATION',
      image: assetImages.consultant,
    },
    {
      id: 2,
      title: 'ANGULAR JS WEB APPLICATION DEVELOPMENT',
      des: 'Angular JS web application development is one of the leading skills of the diligent front-end web development team. They are well versed with the newest version of Angular JS and can help you build classic UI in no time..',
      image: assetImages.angwebdev,
    },
    {
      id: 3,
      title: 'ANGULAR JS MOBILE APP DEVELOPMENT',
      des: 'Capitalize on the power of Angular JS with one shot web and mobile application UI development. Our team helps you build a front-end framework which leverages impactful UX and better business conversion.',
      image: assetImages.angmobdev,
    },
    {
      id: 4,
      title: 'COMPLETE MIGRATION SERVICES',
      des: 'We believe in spreading technology beyond businesses. Avail core services of the leading Angular JS development company for migrating from any standard web development environment to the all-new more capable Angular JS.',
      image: assetImages.migration,
    },
    {
      id: 5,
      title: 'ANGULAR JS SUPPORT AND MANAGEMENT',
      des: 'Enjoy the support after sales and web management services from Cybertron Softech for a never dipping performance of your digital resources and a higher ROI. It is a masterstroke of Business will help keep up the performance consistently.',
      image: assetImages.angmanagsup,
    },
    {
      id: 6,
      title: 'ANGULAR JS ELABORATION AN UPGRADE',
      des: 'Avail the services for an outright elaboration of the Angular JS framework and stable integration of other JavaScript platforms for a completely upgraded and recently updated platform that keeps you cope up with the dynamic tech trends.',
      image: assetImages.angelaup,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Angular JS Development Company"
        description="Use the ultimate power of JavaScript well-crafted into a full-fledged front-end development framework for all your business needs. The framework leverages the total completeness of a business solution. It is capable of empowering your web apps for unmatched performance and a sustainable, resilient UI. Avail quality driven services from the best Angular JS development company for a plethora of services including Angular JS consulting, Angular JS mobile app development and Angular JS web application development."
        serviceImg={assetImages.angularjsbanner}
        maxHeight="25rem"
      />
      <ServiceSecTwo
        title="The Best Companion Through The Digital Transformation"
        description="Angular JS web application development is all about creating sustainable digital ecosystems for businesses and leaving consumers with a breathtaking UX. Angular JS development is a one-stop solution to all your front-end development requirements to align your business goals with higher ROI and better performance."
        serviceImg={assetImages.angularjssec}
      />
      <ServiceFeatures features={features} title="Leverage Precision & Performance ANGULAR JS" />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default AngularStack;
