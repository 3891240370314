import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const MobileAppDev = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Our Route To Your Roots',
      des: 'Our experience and knowledge of how users interact with the app help in planning and executing the high-tech mobile app development for iPhone, Android and iPad. Our primary mobile application development services come with perks of creative inputs, guidance of market trends and creation of apps that are worthy of your time and money, apps which work after real downloads.',
      listItems: [
        'Experienced Team of Developers',
        'Safe and secured Data Communication',
        'Yielding Business Models',
        'Client-focused and Economical Budgeting',
        'Accurate Project Timeline',
        'Attractive App Development Profile',
      ],
      image: assetImages.mobileapp1,
    },
    {
      id: 2,
      title: 'Smart Investment, Up- And-Coming',
      des: 'Both customer and employee belong to the mobile-savvy generation and an app can address the requirements of both. Investing in the mobile phone app development can take your business to heights. An app made especially for internal use can ease in-house communication and speed-up the work performances.',
      image: assetImages.mobileapp2,
    },
    {
      id: 3,
      title: 'Profitable And Productive Quotient',
      des: 'A customized mobile app can increase business’ productivity as business operations can be run smoothly, data stays safe and the management of multiple channels becomes sorted with a single app. More concentration can be given to the expansion of business and not on the smaller things as they’re managed automatically.',
      image: assetImages.mobileapp3,
    },
    {
      id: 4,
      title: 'Personable User Manipulation',
      des: 'Data says that every year new community of app-users adds to the existing. Apps make consumers’ routine and infrequent tasks easy. Above all, consumers get personable manipulation with privacy while doing transactions through an app. These benefits of the app have a literal connection with graceful mobile app development.',
      image: assetImages.mobileapp4,
    },
    {
      id: 5,
      title: 'Easily Adaptable And Accessible',
      des: 'Apps create a large set of libraries for the user to explore e-catalogues, media files and other facilities. Offers can be easily promoted and can be grabbed on a finger-touch. Apps bolster the visibility of brands and brands have the greatest advantage of access to prospects all over the world with a single app. Some apps are made to adapt the offline access as well.',
      image: assetImages.mobileapp5,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Who will take care of my project during development process?',
      des: 'A project leader will be assigned to you, who will keep you updated about the development, save all the communication and passes your requirements and approvals to the team.',
    },
    {
      id: 2,
      title: '2. What are the fees  to create developer account on Google?',
      des: 'For Android developer account, you will need to pay $25 USD/ one time',
    },
    {
      id: 3,
      title: '3. can I get changes done in the app after it is launched?',
      des: 'Yes, minor fixes, bug fixing, and maintenance can be done after it is launched.',
    },
    {
      id: 4,
      title: '4. Will you help us to upload the app after development?',
      des: 'Yes, definitely we will help you to upload the app on both Android and iOS platforms.',
    },
    {
      id: 5,
      title: '5. can I hire developer for hourly or project based task?',
      des: 'Yes, If you know the task to get done from the developer, then you can hire a developer on hourly or project/task base. Even we give flexibility to change the hire model on later stage.',
    },
    {
      id: 6,
      title: '6. Are there possibilities of my app getting rejected by Apple?',
      des: 'Most of the time there are not. Because we follow all the guidelines, standard functionality defined by Apple and tests your app highly professionally, leaving no gateway for rejection.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Extending Universal Connections"
        description={
          <>
            We are a Mobile App Design and Development Company in India working for brands across the globe. Tell us
            what fascinates you most about your business and we’ll highlight that fascination in a fashionable mobile
            app by creating adaptable mobile layouts with error-free development and right hit buttons to bring your
            user closer to their needs and you to your wants. Android dominates 87% of the market share, meaning every 9
            of 10 mobile users are on Android OS. Who will not want to be interested in Android Application Development
            to cater to such a high cast of Android users for their business interest? We are an Android mobile app
            development company in India continuously working to create updated and high-performance android
            applications. Android Mobile Application Development Services from 360 Degree that lead to result-driven
            Android apps are – Android Phone App Development, Android OS Customization, Android App Redesign, Virtual
            Reality App Development, Android Wear App Development and more.
            <br />
            <br />
            Multiple businesses are driving their commerce to mobiles for a direct business connection with their
            current clients or customers as well as building new connections with ingenious mobile apps. Mobile app
            development increase business opportunities and give your users exactly what you want to give and exactly
            what they need. Mobile Apps are easy to refer, one recommendation can create a chain of millions and
            trillions of downloads.
          </>
        }
        serviceImg={assetImages.mobileappdev}
        maxHeight="25rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default MobileAppDev;
