import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Ecom = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Why Choose Cybertron Softech',
      listItems: [
        <>
          <span className="text-orage">EASY PLATFORMS</span> – We use Shopify and WooCommerce for our online stores.
          These are the easiest to use and most stable platforms available today.
        </>,
        <>
          <span className="text-orage">AFFORDABLE</span> – We’re affordable for small-medium sized business and
          start-ups! Our online carts have plenty of bandwidth, free of additional charges.
        </>,
        <>
          <span className="text-orage">WE CAN HELP</span> – 5+ years of experience building killer websites and enabling
          businesses to experience exponential growth.
        </>,
        <>
          <span className="text-orage">TRACK RECORD</span> – Meticulously producing some of the best ecommerce websites
          has to offer, Cybertron Softech has the experience and knowledge to generate sales & grow your business.
        </>,
      ],
      image: assetImages.ecome1,
    },
    {
      id: 2,
      title: "We're Your Ecommerce Website Design Company",
      des: (
        <>
          With 5+ years experience in eCommerce website design the Cybertron Softech team are highly specialized in the
          creation and maintenance of online shops. We appreciate that building and maintaining an e-commerce website is
          a complex & ongoing process. We understand this fear & frustrated associated with the general knowledge gap
          that circulates this industry and we proactively use our skills and knowledge to create online shops that are
          easy to afford, driven for sales and optimized for SEO, all whilst educating you throughout the entire
          process. Our team are dedicated professionals committed to creating innovative websites that are a cut above
          the rest and stand out in a crowded market. We can help with our initial e-commerce website design services
          and also help you to also look after them.
        </>
      ),
      image: assetImages.ecome2,
    },
    {
      id: 3,
      title: 'Showcase Your Business',
      des: (
        <>
          A great e-commerce website should showcase your products and services in a way that builds customer trust. The
          aesthetic design will compliment your products and brand, with a functionality that ensures ease of use for
          your potential customers. Allow us to showcase your business and remove the frustration of looking after your
          online shop with the expertise of our specialized e-commerce website design department. We also provide
          training to minimize the ongoing cost of your store, empowering our clients to be able to update products and
          site wide information confidently without thinking they’ll “break it”.
        </>
      ),
      image: assetImages.ecome3,
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="E-commerce Website Design That Sells"
        description={
          <>
            <span className="text-orage">WEBSITES BUILT TO CONVERT POTENTIAL CUSTOMERS INTO LOYAL ADVOCATES</span>
            <br />
            E-commerce business is quickly surpassing brick and mortar, with more people turning to online platforms to
            find what they’re looking for. One, because it’s convenient & two, because it’s just so much quicker.
            Customers don’t have time to waste gallivanting around the shopping centre for hours only to end up walking
            away with nothing because none of the shops had what they needed. So naturally, society quickly binned the
            effort of physically visiting the shopping center's and streamlined the process turning instead to the
            internet, where the options are endless. Regardless of what size your business is, whether you have a brick
            and mortar store, or what you sell – if you have a retail business, you absolutely need to have a high
            functioning website.
            <br />
            <br />
            But an awesome e-commerce site is far more than a stock standard template, an add to cart page and a few
            buttons here and there. Comprehensive e-commerce website site should be built for the customer to convert.
            This means it should have a consistent aesthetic, psychologically designed navigation, curated customer
            experience, optimizer page speed, easy and logical functionality, trusted & secure payment portals and an
            SEO optimizer structure so you can be found.
            <br />
            <br />
            Fortunately, Cybertron Softech is here to help.
          </>
        }
        serviceImg={assetImages.ecombanner}
        maxHeight="25rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Ecom;
