import React from 'react';
import Slider from 'react-slick';
import { assetImages } from '../../constant/images';
import OneSideTitle from '../UIComponents/OneSideTitle';

const OurAchivements = () => {
  const achievements = [
    // {
    //   id: 1,
    //   image: assetImages.ach1,
    // },
    {
      id: 2,
      image: assetImages.ach2,
    },
    {
      id: 3,
      image: assetImages.ach3,
    },
    {
      id: 4,
      image: assetImages.ach4,
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: achievements?.length < 5 ? 4 : achievements?.length,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 2, autoplay: true, infinite: true },
      },
      {
        breakpoint: 500,
        settings: { slidesToShow: 1, autoplay: true, infinite: true, fade: true },
      },
    ],
  };

  return (
    <section className="achivements">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-9">
            <OneSideTitle
              title={'Our Achievements'}
              des="Being the Canada's trusted IT company, Cybertron Softech has achieved many certifications in core areas and technologies and ranked on the top position among the top 10 mobile apps, web and custom software development companies in the world."
            />
          </div>
        </div>
        <Slider {...settings}>
          {achievements.map((item) => (
            <div key={item.id}>
              <div className="d-flex justify-content-center">
                <div className="achieve-card">
                  <img src={item.image} alt={`achieve-${item.id}`} className="img-fluid maxWidths" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default OurAchivements;
