import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import SerKeyBenefits from '../../components/services/SerKeyBenefits';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const CloudApp = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'What is Cloud Application Services',
      des: (
        <>
          The cloud application is a software program, that depends on remote servers for preparing and processing logic
          that is accessed through a web program with a constant internet connection. These cloud application servers
          are located in remote data centers operated by third party provider.
        </>
      ),
      image: assetImages.cloudapp1,
    },
  ];
  const benefits = [
    {
      id: 1,
      title: <>QUICK RESPONSE</>,
      des: <>Cloud services are quick in deploying, updating and testing providing fast response.</>,
    },
    {
      id: 2,
      title: <>SIMPLE OPERATION</>,
      des: <>Foundation, infrastructure management can be outsourced to third party cloud providers.</>,
    },
    {
      id: 3,
      title: <>SCALABILITY</>,
      des: <>Can scale and up down according to the business needs.</>,
    },
    {
      id: 4,
      title: <>API</>,
      des: (
        <>API based back-end services that can speed up development, processing, analytics and yields great results.</>
      ),
    },
    {
      id: 5,
      title: <>COST EFFECTIVE</>,
      des: <>These services are less expensive in terms of processing and management.</>,
    },
    {
      id: 6,
      title: <>IMPROVED DATA SHARING</>,
      des: <>Data storage on cloud are easily accessible to authorized users.</>,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Cloud Application Services"
        description={
          <>
            Cloud application development services help you move to the cloud developing a new application from scratch.
            It is designed with flexibility, efficiency. We also provide migration and monitoring solutions running on
            SaaS cloud platforms. Once cloud-enabled, you can more easily scale Web applications, set up testing
            environments, experiment with HPC applications and more. Our Cloud app developers provide you with quick and
            easy deployment of applications stored in iCloud that allows users to develop and run their own applications
            over the network with minimal downtime and secure server.
          </>
        }
        serviceImg={assetImages.cloudapp}
        maxHeight="18rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg
        servicesWeProvide={servicesWeProvide}
        whyChoose={true}
        des="Cybertron Softech has Experts of technologies, who not only help reduce the migration costs, but also maintain the efficiency of the application well. 
Seamless migration remains the motto of the professional experts that helps out in maintaining the fidelity of the business logic."
      />
      <SerKeyBenefits title="Key Benefits of Cloud Application Services" benefits={benefits} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default CloudApp;
