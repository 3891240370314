import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';
const SpotifyAds = () => {
  const servicesWeProvide = [
    {
      id: 3,
      title: <>Benefits of Spotify Advertising Services</>,
      des: (
        <>
          <span className="text-orage">Reach your audience</span>
          <br />
          Your audience will hear and see your brand through the shopify platform. Giving you the ability to connect
          with your audience throughout the day.
          <br />
          <br />
          <span className="text-orage">Tell you brand’s story on Spotify</span>
          <br />
          Make your brand stand out above the crowd, with fantastic audio, video and display advertising formats. Giving
          your brand the exposure it deserves.
          <br />
          <br />
          <span className="text-orage">Understand the impact</span>
          <br />
          The platform has intelligent performance measurement and analytics which enables you to pin point your perfect
          audioence, and maximise return on investment.
          <br />
          <br />
        </>
      ),
      image: assetImages.spotifyAdFet1,
    },
    {
      id: 4,
      title: <>Spotify Ad Formats</>,
      des: <>Spotify Ads has expanded into a variety of different ad units:</>,
      image: assetImages.spotifyAdFet2,
      listItems: [
        <>
          <span className="text-orage">Audio Everywhere</span> – short audio ads served between songs with clickable
          display content
        </>,
        <>
          <span className="text-orage">Sponsored Session Video</span> – your video ad unlocks 30 minutes of ad-free
          listening if a user opts to watch the full video
        </>,
        <>
          <span className="text-orage">Video Takeover</span> – your video message served in between songs
        </>,
        <>
          <span className="text-orage">Overlay</span> – simple display ads
        </>,
        <>
          <span className="text-orage">Homepage Takeover</span> – showcase your brand message on the front of Spotify’s
          Browse page for 24 hours
        </>,
        <>
          <span className="text-orage">Sponsored Playlist</span> – customised playlist sponsored by your brand with
          clickable display ad
        </>,
      ],
    },
    {
      id: 5,
      title: <>Utilising a Spotify Advertising Agency</>,
      des: (
        <>
          Spotify is a relatively new advertising medium. The platform itself is growing year on year with little sign
          of slowing. With over 191 million users, each with an average listening time of more than 2 hours per day,
          there are huge opportunities to take advantage of Spotify Advertising & Ontario based business should be
          seriously considering taking advantage.
          <br />
          <br />
          Cybertron Softech is a Spotify Advertising Agency that takes every opportunity possible to beat your
          competition, and this digital audio advertising platform is yet another, which we’ve had great success in
          implementing.
          <br />
          <br />
          Spotify advertising is a great alternative to radio advertising, it’s a highly effective digital audio
          platform that puts your message in front of your audience. Building your brand, and grow your revenue today.
        </>
      ),
      image: assetImages.spotifyAdFet3,
      maxHeight: '25rem',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Turning Music Lovers to 
        Customers"
        description={
          <>
            <span className="text-orage">HARNESS THE POWER OF SPOTIFY ADS TO REACH YOUR AUDIENCE</span>
            <br />
            Develop brand awareness and build trust with Spotify ads
            <br />
          </>
        }
        serviceImg={assetImages.spotifyAdBanner}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default SpotifyAds;
