import React from 'react';
import Slider from 'react-slick';
import { assetImages } from '../../constant/images';
import CenteredTitle from '../UIComponents/CenteredTitle';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        width: '0',
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #fd8008',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        width: '0',
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderRight: '10px solid #fd8008',
      }}
      onClick={onClick}
    />
  );
}

const IndustryExpertise = () => {
  const industries = [
    {
      id: 1,
      image: assetImages.ind1,
      title: 'Travel & Hospitality',
      features: [
        'On-Demand Taxi Apps',
        'Hotel Booking Platforms',
        'Hotel Management Software',
        'Trip Booking Platforms',
        'GDS basad Travel Portals',
        'Activities Booking Platforms',
        'Inventory based Travel Portals',
        'Travel Planning Apps',
      ],
    },
    {
      id: 2,
      image: assetImages.ind2,
      title: 'Real Estate',
      features: [
        'Property Search Portals',
        'Real Estate Agent Platform',
        'Society Management Software',
        'Rent Management Apps',
        'Construction Management Software',
        'Property Management Software',
      ],
    },
    {
      id: 3,
      image: assetImages.ind3,
      title: 'Media & Entertainment',
      features: [
        'Entertainment App',
        'Live Streaming App',
        'Customized Mobile App',
        'Instant Video Sharing App',
        'News App',
        'Music Streaming App',
      ],
    },
    {
      id: 4,
      image: assetImages.ind4,
      title: 'Social Networking',
      features: [
        'Social Medial App',
        'Dating App',
        'Clone App',
        'Social Community App',
        'Photo & Video Sharing App',
        'Chatting App',
      ],
    },
    {
      id: 5,
      image: assetImages.ind5,
      title: 'Medical & Health Care',
      features: [
        'Healthcare Service App',
        'Patient Health Tracking Apps',
        'Hospital Management System',
        'Chiropractic Solutions',
        'Clinical Communication System',
        'Pharmacy Solutions',
        'Laboratory Service App',
        'Health & Fitness Apps',
      ],
    },
    {
      id: 6,
      image: assetImages.ind6,
      title: 'Energy & Utilities',
      features: [
        'Entertainment App',
        'Live Streaming App',
        'Customized Mobile App',
        'Instant Video Sharing App',
        'News App',
        'Music Streaming App',
      ],
    },
    {
      id: 7,
      image: assetImages.ind7,
      title: 'Logistics & Distributions',
      features: [
        'Freight Rate Comparison App',
        'Warehouse Management App',
        'Truck Direction Routing App',
        'Billing Software',
        'Supply Chain Software',
        'Shipping Order Processing Tools',
        'Supplier Management Solution',
      ],
    },
    {
      id: 8,
      image: assetImages.ind8,
      title: 'Education & Learning',
      features: [
        'School Management System ',
        'Learning Management System',
        'Virtual Classroom',
        "Student's Portal",
        "Academic Expert's Portal",
        'Social & Emotional Learning',
      ],
    },
    {
      id: 9,
      image: assetImages.ind9,
      title: 'Sports & Gamers',
      features: [
        'Sports Betting App',
        'Live Sports Tracking App',
        'Sports Event Booking App',
        'Team Management App',
        'Live Streaming App',
        'E-Sports App',
      ],
    },
    {
      id: 10,
      image: assetImages.ind10,
      title: 'E - Commerce',
      features: [
        'B2B Ecommerce Apps & Marketplaces',
        'B2B Ecommerce Apps & Marketplaces ',
        'Multi - Vendor Ecommerce Portal',
        'Inventory Management Apps',
        'Retail Ecommerce Apps',
        'AI- Based Ecommerce Tools',
        'Service Marketplace Apps',
        'Retail ERP Software Management',
      ],
    },
    {
      id: 11,
      image: assetImages.ind11,
      title: 'Fitness & Wellness',
      features: [
        'Fitness Tracking Apps',
        'Nutrition and Diet Apps',
        'AI-based Personal Trainer Apps',
        'Fitness Gamification Apps',
        'Fitness Wearable Watch app',
        'Workout and Exercise App',
      ],
    },
    {
      id: 12,
      image: assetImages.ind12,
      title: 'Banking & Finance',
      features: [
        'Mobile Banking Solution',
        'Finance Management Solution',
        'CRM Software',
        'Fintech App Solution',
        'Mutual Fund Marketplace',
        'Stock Trading Marketplace',
      ],
    },
  ];

  const settings = {
    dots: false,
    // infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    speed: 1000,
    cssEase: 'linear',
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <section className="industry-expertise">
        <CenteredTitle
          title="Our Industry Expertise"
          des="We have extensive knowledge and great expertise in creating highly interactive, user-centric, and
        value-driven software solutions that meet your business goal. No matter what industry you belong to, we
        can help provide highly optimized on-demand software solutions based on your specific business
        requirements."
        />
      </section>
      <section className="industries-carousal-wrapper">
        <div className="container-lg">
          <Slider {...settings}>
            {industries.map((item) => (
              <div key={item.id}>
                <div className="d-flex justify-content-between align-items-end">
                  <h3>{item.title}</h3>
                  <img src={item.image} alt={`industries-${item.id}`} className="img-fluid" />
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-9 col-md-12">
                    <div className="row">
                      {item.features.map((fet) => (
                        <div className="col-lg-6 col-md-6 col-sm-12 feature-wrapper" key={fet}>
                          <div className="fet-dot"></div>
                          <p>{fet}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default IndustryExpertise;
