export const subMenuItems = [
  {
    id: 1,
    title: 'Web Development',
    stacks: [
      {
        id: 11,
        link: '/mean-stack',
        name: 'MEAN Stack',
      },
      {
        id: 12,
        link: '/mern-stack',
        name: 'MERN Stack',
      },
      {
        id: 13,
        link: '/angular-js',
        name: 'Angular JS',
      },
      {
        id: 14,
        link: '/react-js',
        name: 'REACT JS',
      },
      {
        id: 15,
        link: '/node-js',
        name: 'Node JS',
      },
      {
        id: 16,
        link: '/python-web-development',
        name: 'Python Web Development',
      },
      {
        id: 17,
        link: '/drupal-development',
        name: 'Drupal Development',
      },
      {
        id: 18,
        link: '/magento-evelopment',
        name: 'Magento Development',
      },
      {
        id: 19,
        link: '/codeigniter-development',
        name: 'Codeigniter Development',
      },
      {
        id: 110,
        link: '/wordpress-development',
        name: 'Wordpress Development',
      },
    ],
  },
  {
    id: 2,
    title: 'Mobile App Development',
    stacks: [
      {
        id: 21,
        link: '/mobile-app-development',
        name: 'Mobile App Development',
      },
      {
        id: 22,
        link: '/android-app-development',
        name: 'Android App Development',
      },
      {
        id: 23,
        link: '/iphone-app-development',
        name: 'Iphone App Development',
      },
      {
        id: 24,
        link: '/mobile-app-maintainance',
        name: 'Mobile App Maintainance',
      },
      {
        id: 25,
        link: '/mobile-ui-screen',
        name: 'Mobile UI Screen',
      },
      {
        id: 26,
        link: '/react-native-app-development',
        name: 'React Native App Development',
      },
      {
        id: 27,
        link: '/startups-app-development',
        name: 'Startups App Development',
      },
    ],
  },
  {
    id: 3,
    title: 'Digital Marketing',
    stacks: [
      {
        id: 31,
        link: '/seo-services',
        name: 'SEO Services',
      },
      {
        id: 32,
        link: '/adwords',
        name: 'Adwords',
      },
      {
        id: 33,
        link: '/facebook-marketing',
        name: 'Facebook Marketing',
      },
      {
        id: 34,
        link: '/google-shopping-ads',
        name: 'Google Shopping Ads',
      },
      {
        id: 35,
        link: '/youtube-advertising',
        name: 'Youtube Advertising',
      },
      {
        id: 36,
        link: '/email-marketing',
        name: 'Email Marketing',
      },
      {
        id: 37,
        link: '/spotify-ads',
        name: 'Spotify Ads',
      },
      {
        id: 38,
        link: '/banner-advertising',
        name: 'Banner Advertising',
      },
    ],
  },
  {
    id: 4,
    title: 'Web & Creative',
    stacks: [
      {
        id: 41,
        link: '/web-design',
        name: 'Website Design',
      },
      {
        id: 42,
        link: '/e-com-web-design',
        name: 'E Commerce',
      },
      {
        id: 43,
        link: '/branding',
        name: 'Branding',
      },
      {
        id: 44,
        link: '/landing-pages-design',
        name: 'Landing Pages',
      },
      {
        id: 45,
        link: '/copywriting-and-content-writing',
        name: 'Copywriting',
      },
      {
        id: 46,
        link: '/domain-name-registration',
        name: 'Domains',
      },
      {
        id: 47,
        link: '/gsuites-services',
        name: 'G - Suites',
      },
    ],
  },
  {
    id: 5,
    title: 'AWS Services',
    stacks: [
      {
        id: 51,
        link: '/aws-cloud-consulting-services',
        name: 'AWS Cloud Consulting Services',
      },
      {
        id: 52,
        link: '/aws-data-migration',
        name: 'AWS Data Migration',
      },
      {
        id: 53,
        link: '/aws-deployment',
        name: 'AWS Deplopyment',
      },
      {
        id: 54,
        link: '/cloud-application-services',
        name: 'Cloud Application Services',
      },
      {
        id: 55,
        link: '/cloud-migratuion-services',
        name: 'Cloud Migration Services',
      },
    ],
  },
];
