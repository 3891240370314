import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';
const BannerAd = () => {
  const servicesWeProvide = [
    {
      id: 3,
      title: <>Smart Display Advertising Services</>,
      des: (
        <>
          Display advertising is a seriously cool & powerful online marketing strategy that delivers millions of
          impressions and visits to your ads & website. Its most commonly used to drive awareness and extend your
          businesses reach to new audiences that are interested in what your business offers. When it comes to tactical
          display campaigns AKA. custom intent & in- market audiences, you can expect to see increased conversion
          performance thanks to an awesome new Pay Per Conversion feature.
          <br />
          <br />
          We have customised banner advertising tactics that suit your business goals. Many businesses who try to DIY
          their own GDN strategy run a very high risk of depleting their cash flow, which is why we offer a display
          advertising service consisting of marketing experts and a creative team that understands the importance of
          damn good presentation and engagement to ensure you get your message across without breaking the bank and
          giving your accountant a heart attack.
        </>
      ),
      image: assetImages.bannerAd1,
    },
    {
      id: 4,
      title: <>What is Included in Banner Advertising Services?</>,
      des: (
        <>
          At Cybertron Softech, we set up & prepare the entire project for you. All you have to do is tell us your
          objectives and goals, from there we’ll get a killer GDN Campaign up and running, and working just as hard as
          you do to promote your business. It’s that easy. Our Banner Advertising Service includes:
        </>
      ),
      listItems: [
        'GDN Banner Creative Design ( Popular Sizes)',
        'Account Setup',
        'Smart Targeting of Audiences',
        'Campaign reporting based on KPI',
        'Dedicated Account Manager',
      ],
      image: assetImages.bannerAd2,
    },
    {
      id: 5,
      title: <>Brand Safety</>,
      des: (
        <>
          The thing with Banner Display Ads is that your reach is dependent on Googles Ad Inventory Network. Put simply,
          the Ad Inventory Network is a very large group of sites which allow Google to display your ad banners. In fact
          you’ve probably experienced these before on your own devices. For the most part, the majority of these
          industries are highly relevant and qualified sites to extend your reach and show your ad to new audiences that
          are interested in your offer. But it’s tricky to set up, if you’re not careful, your GDN campaign placement
          could be affected due to placement on unqualified sites such as spam or adult sites. This kind of placement
          could drastically affect your brand reputation and credibility. I mean, do you really want to show up on an
          adult site when you’re trying to sell bicycles? Yeah, we didn’t think so either.
          <br />
          <br />
          We’re Google Premier Partners – that means we’re in the top 3% of display advertising agencies for driving
          performance on Google Ads. Which is great for you, because it let’s you feel safe using Cybertron Softech to
          deliver your GDN campaigns, and you can rest easy knowing your brands name and credibility are protected.
        </>
      ),
      image: assetImages.bannerAd3,
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title={<>A Smarter Approach to Banner Ads</>}
        description={
          <>
            <span className="text-orage">CATCH THEM WHERE THEY HANGOUT</span>
            <br />
            Persuasive & Meticulously designed banner & display advertising for Business.
            <br />
          </>
        }
        serviceImg={assetImages.bannerAd}
        maxHeight="20rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default BannerAd;
