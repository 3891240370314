import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../common/ScrollToTop';
import { subMenuItems } from '../../constant/enum';

const Navigation = () => {
  const location = useLocation();
  return (
    <div className="d-none d-lg-block w-100">
      <div className="header-menu-items-wrapper">
        <div className="row gx-5">
          <div className="col">
            <Link to="/" onClick={scrollToTop} className={`${location.pathname === '/' ? 'active' : ''}`}>
              Home
            </Link>
          </div>
          <div className="col">
            <div className="dropdown">
              <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <div className="dropdown-menu">
                <div className="row gx-2">
                  {subMenuItems?.map((itm) => (
                    <div className="col-lg-auto col-md-auto" key={itm.id}>
                      <div className="service-list-wrapper">
                        <div className="service-title-wrapper">
                          <p>{itm.title}</p>
                          <div className="line"></div>
                        </div>
                        <ul>
                          {itm.stacks?.map((ser) => (
                            <li key={ser.id}>
                              <Link to={ser.link} onClick={scrollToTop}>
                                {ser.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                  <div className="col-lg-auto col-md-auto">
                    <div className="service-list-wrapper">
                      <Link to="/ui-ux-design" onClick={scrollToTop}>
                        <div className="service-title-wrapper">
                          <p>UI/UX Design & Development</p>
                          <div className="line"></div>
                        </div>
                      </Link>
                    </div>
                    <div className="service-list-wrapper">
                      <Link to="/testing-and-quality-assurance" onClick={scrollToTop}>
                        <div className="service-title-wrapper">
                          <p>Testing & quality Assurance</p>
                          <div className="line"></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <Link
              to="/industry"
              onClick={scrollToTop}
              className={`${location.pathname === '/industry' ? 'active' : ''}`}
            >
              Industry
            </Link>
          </div>
          <div className="col">
            <Link to="/portfolio" onClick={scrollToTop}>
              Portfolio
            </Link>
          </div>
          <div className="col" style={{ minWidth: '132px' }}>
            <Link to="/contact-us" onClick={scrollToTop}>
              Contact Us
            </Link>
          </div>
        </div>
        <Link to="/estimate-your-project" onClick={scrollToTop}>
          <button className="header-button">Estimate Your Project</button>
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
