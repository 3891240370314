import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServiceFAQS from '../../components/services/ServiceFAQS';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const MobileUi = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: 'Goosebumps Are Mandatory',
      des: 'Cybertron Softech provides services for both Android App UI design and iPhone App UI design. We tactically make a combination of Design, Technology, and Communication to encourage the user to achieve more with the app. Smart Mobile UI Design captivates users’ attention to convert them into app loyalists. Colors, pattern, fonts, characters, and layout of a Mobile UI have to make sense and grab attention.',
      listItems: [
        'Experience of Mobile UI for 350+ apps',
        'Android and iOS insights for UI',
        'Professionally expert and experienced Design Team',
        'Design to compliment the brand and invoke emotions',
        'Recognizable icon and theme design',
        'Compatibility with devices and browsers',
      ],
      image: assetImages.mobileui1,
    },
    {
      id: 2,
      title: 'Benefits Of Mobile UI Designing',
      des: 'UI planning is a power-pack of the knowledge of business needs, presentation of an app and technical aptitude. A reliable UI planning is very helpful to define the structure where more focus is laid on app expression and how it solves the user’s purpose. It’s easier to guide users with planned UIs.',
      image: assetImages.mobileui2,
    },
    {
      id: 3,
      title: 'Experience UI With App Etiquette',
      des: 'Every disorder in the design has to be nullified for increased visibility. An App icon is a part of Mobile App UI Design which becomes the first face of the app and it must bedazzle like it has been downloaded million times. Keeping uniformity in all pages, safekeeping of space between two icons for touch convenience, laying identity patterns, etc. maintain the app etiquette and user’s experience.',
      image: assetImages.mobileui3,
    },
    {
      id: 4,
      title: 'Increase App Usability',
      des: 'If you like something, you use it more frequently. Creative Android App UI Design and iPhone app UI design compel a user to use the app more and increase the interaction with the app. Don’t bore the user, keep them entertained with amusing Mobile UI Designing.',
      image: assetImages.mobileui4,
    },
    {
      id: 5,
      title: 'Return The Favour With Instant Responses',
      des: 'Fetching instant responses like feedbacks for user tasks make the app interaction easy for users. They like to know whether their actions are ok. Responses allow users to identify whether the task is completed. These responses have to be user-amiable and generate in a timely manner',
      image: assetImages.mobileui5,
    },
  ];
  const faqs = [
    {
      id: 1,
      title: '1. Who will take care of my project during development process?',
      des: 'A project leader will be assigned to you, who will keep you updated about the development, save all the communication and passes your requirements and approvals to the team.',
    },
    {
      id: 2,
      title: '2. Can I know the experience of the designer team?',
      des: 'The client can know the experience of the designer.',
    },
    {
      id: 3,
      title: '3. Will you make the changes after the app is designed?',
      des: 'Yes, we make the changes in design as per the clients opinion.',
    },
    {
      id: 4,
      title: '4. Do you have in house design team?',
      des: 'Yes, we have in house design team as well as QA team along with development teams.',
    },
    {
      id: 5,
      title: '5. can I get changes done in the app after it is launched?',
      des: 'Yes, minor fixes, bug fixing, and maintenance can be done after it is launched.',
    },
    {
      id: 6,
      title: '6. Will you help us to upload the app after development?',
      des: 'Yes, we will upload the app in the Google play store after the development.',
    },
    {
      id: 7,
      title: '7. Are there possibilities of my app getting rejected by Apple?',
      des: 'Yes, there are such possibilities but the company takes responsibility to get it approved.',
    },
  ];
  return (
    <Layout>
      <ServiceBanner
        title="Handsome And Gladsome Mobile User Interface Design"
        description={
          <>
            Ever met a person who looks appealing to you but doesn’t know how to interact with others? Maybe yes! Such
            person dims the excitement of communication, you feel boring in their company and you start ignoring them.
            Well, you can’t compare a person with an app but the same thing happens if an app is not exciting. App those
            are haphazard in nature, design-wise unattractive and disallows a smooth interaction with device’s features
            are ignored by the users. Such apps make user’s work tough instead of easing. Respect for dashing Mobile App
            UI Design dawns here!
            <br />
            <br />A Mobile App UI Design not only has to flaunt but also be functional by all means. We create such
            favourable Mobile UI that provides effortless navigation, shows uniformity in all pages, enhances the user
            experience, makes interplay simple and familiarizes the user with the app. Our goal-driven services for user
            interface design for mobile applications make apps well-customized, desirable, unique and in-sync with user
            needs. To connect with the user’s world, we design a mobile UI that also integrates Social Media and extends
            brand presence on different digital platforms, which is more admirable.
          </>
        }
        serviceImg={assetImages.mobileui}
        maxHeight="25rem"
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <ServiceFAQS faqs={faqs} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default MobileUi;
