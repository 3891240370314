import React from 'react';
import Newsletter from '../../components/commonComponents/Newsletter';
import Layout from '../../components/layout/Layout';
import HereToHelp from '../../components/services/HereToHelp';
import ServiceBanner from '../../components/services/ServiceBanner';
import ServicesBlueBg from '../../components/services/ServicesBlueBg';
import { assetImages } from '../../constant/images';

const Branding = () => {
  const servicesWeProvide = [
    {
      id: 1,
      title: <>The Power of Branding for your business</>,
      des: (
        <>
          Think graphic design and branding isn’t an important factor in your company’s growth? Think you can get by
          with sub-par graphics and inconsistent branding? We’re not really the type that likes to tell people they’re
          wrong, but if we were, we’d tell you you’re wrong. The results are in, and the psychologists have something to
          say. They say that using signature brand colors, iconic logos, consistent tonality and imagery will boost your
          brands recognition by 80%. When it’s inconsistent, it doesn’t allow potential customers the ability to
          identify & categories your brand, ensuring you remain lost in the sea of faceless brands forever. You don’t
          want that, we don’t want that for you either, so let’s change it – let’s get to work on making you iconic.
        </>
      ),
      image: assetImages.brand1,
      maxHeight: '25rem',
    },
    {
      id: 2,
      title: <>The importance of Brand Personality</>,
      des: (
        <>
          The truth is, your brand image & digital appearance is crucial to continued growth, regardless of how big you
          are. If Nike started being inconsistent in their brand personality in a way that no longer aligned with who
          you knew them to be as a brand – you’d feel uncomfortable, you’d think they’ve lost their way, you would
          struggle to continue believing in what they do, you might even discontinue buying from them. People need to
          trust you, and they need to know exactly who you are and what you stand for in order to trust you. It’s a
          simple formula – no trust, no money, no honey.
          <br />
          <br />
          We know just how much competition is out there, and we have the skill set to make sure you’re on top of your
          branding game – this is why we want to help you! Let Cybertron Softech take care of all your graphic design
          needs by creating iconic brand elements that turn heads and make people take notice of your business in an
          over crowded market, because as industry leading, cosmetics brand L’Oreal always says “You’re worth it”.
        </>
      ),
      image: assetImages.brand2,
    },
    {
      id: 3,
      title: <>Experienced Graphic Designers</>,
      des: (
        <>
          You need to be constantly aware of how you look, what people think about you, how people respond to your
          content, whether your content generators are representing the brand appropriately – the list goes on… and on.
          <br />
          <br />
          To manage your business presence, you need to develop a brand that is receptive to digital trends as well as
          an appropriate strategy. This way, your customers and online followers are always getting the right content!
          <br />
          <br />
          We are experienced in creating engaging brand identities for new start-ups and existing businesses. We believe
          in clear communication, and we start by listening to your dreams and figuring out how to best convey them to
          the public.
        </>
      ),
      image: assetImages.brand3,
      maxHeight: '30rem',
    },
    {
      id: 4,
      title: <>The importance of Graphic Design</>,
      des: (
        <>
          Great graphic design services aren’t just about delivering an attractive website. Our team of graphic
          designers can assist you in creating a great digital appearance to establish your brand. In the highly
          competitive marketplace, your brand can help you to stand head and shoulders above the crowd.
          <br />
          <br />
          Utilize a highly experienced graphic design agency to bring to life your brand personality, whether it’s a
          basic business logo design or full corporate rebrand, Cybertron Softech as you covered.
        </>
      ),
      image: assetImages.brand4,
      maxHeight: '25rem',
    },
    {
      id: 5,
      title: <>Cybertron Softech - Your Branding Agency</>,
      des: (
        <>
          Cybertron Softech has a team of graphic designers and creative design experts who can provide you with
          branding services and graphic design solutions tailored to the specific needs of your business.
          <br />
          <br />
          If you want progress and massive brand awareness, don’t wait any longer. You deserve greatness, and we believe
          we have what it takes to make you great. So click on the button below and let’s get to work.
        </>
      ),
      image: assetImages.brand5,
      maxHeight: '30rem',
    },
  ];

  return (
    <Layout>
      <ServiceBanner
        title="Graphic Design & Branding"
        description={
          <>
            <span className="text-orage">
              IT TAKES A CUSTOMER 5-7 IMPRESSIONS BEFORE THEY BEGIN TO RECOGNISE YOUR COMPANY'S LOGO & IDENTITY
            </span>
          </>
        }
        serviceImg={assetImages.brandbanner}
        maxHeight="20rem"
        isVectorHidden={true}
      />
      <ServicesBlueBg servicesWeProvide={servicesWeProvide} />
      <HereToHelp />
      <Newsletter />
    </Layout>
  );
};

export default Branding;
