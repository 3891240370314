import React from 'react';

const OneSideTitle = ({ title, des }) => {
  return (
    <>
      <div className="title-wrapper">
        {/* <div className="orange-line"></div> */}
        <h2>{title}</h2>
      </div>
      <p className="des-text">{des}</p>
    </>
  );
};

export default OneSideTitle;
